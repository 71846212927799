import { useState, useEffect } from "react"
import { useDispatch } from "react-redux"
import { FetchMethods } from "../../../../interface/Enums/methods.enum"
import { IUser } from "../../../../interface/IUser.interface"
import { setError } from "../../../../store/reducers/errorReducer"
import { fetchAPI } from "../../../../utils/FetchApi"

const ColleagueSelection = ({selectedColleague}:{selectedColleague:Function}) => {
  const dispatch = useDispatch()
  //Get the list of my users
  const [listOfUsers, setListOfUsers] = useState<IUser[]>([])

useEffect(()=>{
    (async()=>{
      try {
        const getMyUsers:IUser[] =  await fetchAPI("/evaluationcmds/get-my-users", FetchMethods.GET);

        if(getMyUsers.hasOwnProperty("error")) throw new Error("Users could not be loaded")

        setListOfUsers(getMyUsers)

      } catch (error:any) {
          if (error)dispatch(setError(error.message))
      }
      
     
    })()
},[dispatch]);
  
  return (
    <select onChange={({target})=>{
      const pseudoIndex = parseInt(target.value)
      if(target.value !== "---"){
        selectedColleague(listOfUsers[pseudoIndex])
      }
      
    }} className="form-control">
      <option className="form-control" value="---">---</option>
      {
        listOfUsers.map((u:IUser,i)=>(
          <option className="form-control" key={i} value={i}>{u.name} {u.surname} ({u.country})</option>
        ))
      }
    </select>
  )
}

export default ColleagueSelection