import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import AdminContainer from "../../../admin-components/AdminContainer";
import ColleagueSelection from "./ColleageEvaluationComponents/ColleagueSelection";
import { UserInterface, GlobalScope, IDepartment, IJobname, AppraisalDocument, IEvaluationDynamicDocument, IPerformanceMetricInput, IPerformanceMetricOutput } from "../../../interface";
import { Accordion, Badge } from "react-bootstrap";
import { IUser } from "../../../interface/IUser.interface";
import DepartmentSelection from "./ColleageEvaluationComponents/DepartmentSelection";
import JobnameSelection from "./ColleageEvaluationComponents/JobnameSelection";
import AppraisalDocumentSelection from "./ColleageEvaluationComponents/AppraisalDocumentSelection";
import RatingDistribution from "./ColleageEvaluationComponents/RatingDistribution";
import { setError } from "../../../store/reducers/errorReducer";
import { emptyEvaluationDynamicDocument } from "./ColleageEvaluationComponents/EmptyEvaluationDocument"; 
import {ValidateEvaluationDynamicDocument} from "../../../utils/ValidateEvaluationDynamicDocuments"
import CompetenciesChart from "../../../components/Charts/Competencies.chart";
import EnergizerAssesmentChart from "../../../components/Charts/EnergizerAssesment.chart"
import InputOutputChart from "../../../components/Charts/InputOutput.chart";
import { fetchAPI } from "../../../utils/FetchApi";
import { FetchMethods } from "../../../interface/Enums/methods.enum";
import EvaluationDocumentMasterComponents from "./ColleageEvaluationComponents/EvaluationDocumentMasterComponents";
const EvaluateColleage = () => {
  const dispatch = useDispatch();
  const data: UserInterface = useSelector((d: GlobalScope): UserInterface => {
    return d.auth.user;
  });
const [previsouEvalDynamicDocuments, setPrevisouEvalDynamicDocuments] = useState<IEvaluationDynamicDocument[]>([])
const [selectedEvaluationComponent, setSelectedEvaluationComponent] = useState<IEvaluationDynamicDocument>(emptyEvaluationDynamicDocument)
  const [evalDynamicDocument, setEvalDynamicDocument] = useState<IEvaluationDynamicDocument>(emptyEvaluationDynamicDocument); //mono fucking state


  const selectColleague = async (colleague: IUser) => {

    try {
      const evalDocs:IEvaluationDynamicDocument[] = await fetchAPI("/admincmds/get-users-evaluation-document", FetchMethods.POST, colleague);
      if(evalDocs.length>0){

        setPrevisouEvalDynamicDocuments(evalDocs);
      }
    } catch (error) {
      
    }
//@ts-ignore
    setEvalDynamicDocument({...evalDynamicDocument, selectedRep: colleague })
  };
  const selectDepartment = (dep:IDepartment)=>{
    //@ts-ignore
    setEvalDynamicDocument({...evalDynamicDocument, selectedDepartment: dep })
   
  }
  const selectJobName = (job:IJobname) =>{
   //@ts-ignore
   setEvalDynamicDocument({...evalDynamicDocument, selectedJobname: job })
  }
  const selectAppraisalDocument = (app:AppraisalDocument) =>{
     //@ts-ignore
   setEvalDynamicDocument({...evalDynamicDocument, appraisalDocument: app })
  }

  //Preload calculations - ok
  useEffect(()=>{
    if(evalDynamicDocument?.appraisalDocument){
      //gets sum of energizer inputs for further calculation
      const inputImportanceEnergizerSum:number|undefined = evalDynamicDocument?.appraisalDocument?.inputs.reduce((fin:number,obj:IPerformanceMetricInput)=>{
        if(obj.type==="energizer"){return fin+=obj.importance}
          return fin
      },0);
      
      //Gets of standard outputs as well (so I do not have shitload of effects)
      const outputImportanceSum:number|undefined = evalDynamicDocument?.appraisalDocument?.outputs.reduce((fin:number,obj:IPerformanceMetricOutput)=>{
       return fin+=obj.importance
      },0);
      //Here I have to create new properties for the given object
      setEvalDynamicDocument(p=>({
        ...p, 
        energizerImportanceSum:inputImportanceEnergizerSum,
        inputImportanceSum:100-inputImportanceEnergizerSum,
        outputStandardImportanceSum:outputImportanceSum 
      }))

    }
  },[evalDynamicDocument?.appraisalDocument])

  //Aha, tak tady je problém
  useEffect(()=>{
    if(evalDynamicDocument?.inputsEnergizer){
     let sum:number = 0;
      for(let key in evalDynamicDocument.inputsEnergizer){
        //@ts-ignore
        const {importance, fulfillment}:{importance:number, fullfillment:number} = evalDynamicDocument.inputsEnergizer[key]
     //@ts-ignore
        sum += parseInt(importance*fulfillment)
      }
      setEvalDynamicDocument(e=>({
        ...e,
        inputEnergizerSum:sum, 
        energizerInputFinalValue:(sum/evalDynamicDocument.energizerImportanceSum)
      }))
    }
  },[evalDynamicDocument?.inputsEnergizer,evalDynamicDocument.energizerImportanceSum])
  //I validate only Standard inputs{
  useEffect(()=>{
    if(evalDynamicDocument?.inputsStandard){
     let sum:number = 0;
      for(let key in evalDynamicDocument.inputsStandard){
        //@ts-ignore
        const {importance, fulfillment}:{importance:number, fullfillment:number} = evalDynamicDocument.inputsStandard[key]
        //@ts-ignore
        sum += parseInt(importance*fulfillment)
      }
      setEvalDynamicDocument(e=>({
        ...e,
        inputStandardSum:sum
      }))
    }
  },[evalDynamicDocument?.inputsStandard])

  //Overall input value
  useEffect(()=>{

    const finalNumber:number = (evalDynamicDocument?.inputEnergizerSum+evalDynamicDocument?.inputStandardSum)/(evalDynamicDocument?.energizerImportanceSum+evalDynamicDocument?.inputImportanceSum);
    finalNumber.toFixed(2);
    if(finalNumber){
      setEvalDynamicDocument(e=>({
        ...e,
        overallInputFinalvalue:finalNumber
      }))
    }

  },[evalDynamicDocument?.inputStandardSum,evalDynamicDocument?.energizerImportanceSum, evalDynamicDocument?.inputEnergizerSum,evalDynamicDocument?.inputImportanceSum] )



  //Lets create a logic for alphabet evaluation
  useEffect(() => {
    if(evalDynamicDocument?.appraisalDocument.ratingDistribution){
      const data:any = evalDynamicDocument?.appraisalDocument.ratingDistribution;
     
      const arr:any = [];

      for(let keys in data){
        arr.push(data[keys])
      }
     
      const correctRangeObject = arr.filter((d:any)=>{

    
        return evalDynamicDocument?.energizerInputFinalValue<=d.from+1&& evalDynamicDocument.energizerInputFinalValue>=d.to-1

      })
      if(correctRangeObject.length>0){
        setEvalDynamicDocument(e=>({
          ...e,
          energizerInputLetter:correctRangeObject[0].letter
        }))
      }
    }
   
  }, [evalDynamicDocument?.energizerInputFinalValue,evalDynamicDocument?.appraisalDocument.ratingDistribution])
  //Lets create a logic for alphabet evaluation
  useEffect(() => {
    if(evalDynamicDocument?.appraisalDocument.ratingDistribution){
      const data:any = evalDynamicDocument?.appraisalDocument.ratingDistribution;
      const arr:any = [];
      for(let keys in data){
        arr.push(data[keys])
      }
      const correctRangeObject = arr.filter((d:any)=>{
        return evalDynamicDocument?.overallInputFinalvalue<=d.from+1&& evalDynamicDocument?.overallInputFinalvalue>=d.to-1
      })
      if(correctRangeObject.length>0){
        setEvalDynamicDocument(e=>({
          ...e,
          overallInputLetter:correctRangeObject[0].letter
        }))
      }
    }
   
  }, [evalDynamicDocument?.overallInputFinalvalue,evalDynamicDocument?.appraisalDocument.ratingDistribution])

/**
 * OUTPUTS
 */
   //If anything in the standard outputs happens, the virtual DOM recalculates everything
    useEffect(()=>{
      //Counts the correct percentage
      if(evalDynamicDocument?.outputsStandard){
       let sum:number = 0;
        for(let key in evalDynamicDocument.outputsStandard){
          //@ts-ignore
          const {importance, fulfillment}:{importance:number, fullfillment:number} = evalDynamicDocument.outputsStandard[key]
          //@ts-ignore
          sum += parseInt(importance*fulfillment)
        }
          setEvalDynamicDocument(e=>({
            ...e,
            outputStandardSum:sum,
           outputStandardFinalValue:(sum/evalDynamicDocument.outputStandardImportanceSum)
          }))
      }
    },[evalDynamicDocument?.outputsStandard,evalDynamicDocument.outputStandardImportanceSum])

     //Lets create a logic for alphabet evaluation
  useEffect(() => {
    if(evalDynamicDocument?.appraisalDocument.ratingDistribution){
      const data:any = evalDynamicDocument?.appraisalDocument.ratingDistribution;
      const arr:any = [];
      for(let keys in data){
        arr.push(data[keys])
      }
      const correctRangeObject = arr.filter((d:any)=>{
        return evalDynamicDocument?.outputStandardFinalValue<=d.from+1&& evalDynamicDocument?.outputStandardFinalValue>=d.to-1
      })

      if(correctRangeObject.length>0){
        setEvalDynamicDocument(e=>({
          ...e,
          outputStandardLetter:correctRangeObject[0].letter
        }))
      }
    
    }
   
  }, [evalDynamicDocument?.outputStandardFinalValue,evalDynamicDocument?.appraisalDocument.ratingDistribution])


  /**
   * Competencies 
   */

  //Set letter based on competencies
  useEffect(()=>{
 
    //2. Map through the competencies to get the values
    const arrayOfCompetencyLetters:string[] = []
    if(evalDynamicDocument?.competencies){
     for(let keys in evalDynamicDocument?.competencies){
      //@ts-ignore
      arrayOfCompetencyLetters.push(evalDynamicDocument?.competencies[keys].evaluation)
     }
    }

    const turnCompetencyLettersIntoValues:number[] = arrayOfCompetencyLetters.map((letter:string)=>{
      //@ts-ignore
      return (evalDynamicDocument?.appraisalDocument.ratingDistribution[`${letter.toLowerCase()}`].from)
    })

    const averageFromCompetencyLetters:number = turnCompetencyLettersIntoValues.reduce( (p:number, c:number) => p+c,0);
//the average

const theFinalCompetencyScore:number = averageFromCompetencyLetters/turnCompetencyLettersIntoValues.length;


// aaaand this fucking sh1t is the final move how to categorize the final score


  const data:any = evalDynamicDocument?.appraisalDocument.ratingDistribution;
  const arr:any = [];
  for(let keys in data){
    arr.push(data[keys])
  }
  const correctRangeObject = arr.filter((d:any)=>{
    return theFinalCompetencyScore<=d.from+1&& theFinalCompetencyScore>=d.to-1
  })

  if(correctRangeObject.length>0){
    setEvalDynamicDocument(e=>({
      ...e,
      competenciesLetter:correctRangeObject[0].letter,
      competenciesFinaPercentageNumber:theFinalCompetencyScore
    }))
  }
  

  },[evalDynamicDocument?.competencies,evalDynamicDocument?.appraisalDocument.ratingDistribution])

  const saveEvaluationForGivenColleague = async () => {
  
    ValidateEvaluationDynamicDocument(evalDynamicDocument, async (response:{status:boolean, msg:string})=>{
      
      if(!response.status){
        dispatch(setError(response.msg))
      }else{
        //this is just for easier queries
        evalDynamicDocument.country = evalDynamicDocument.selectedRep.country;
        evalDynamicDocument.appraisalPeriod = evalDynamicDocument.appraisalDocument.evaluationPeriod;
        evalDynamicDocument.repNameQuery = `${evalDynamicDocument.selectedRep.name} ${evalDynamicDocument.selectedRep.surname}`

        //Save the doc

        try {
        const message:Response = await fetchAPI(`/admincmds/create-evaluation-document`, FetchMethods.POST,evalDynamicDocument);
        if(message.hasOwnProperty("error")){
          //@ts-ignore
          throw new Error(message.message.toString())
        }
          setEvalDynamicDocument(emptyEvaluationDynamicDocument);
        } catch (error:any) {
          dispatch(setError(error.msg.toString()))
        }


      }

    });

  }
  return (
    <AdminContainer>
      <div className="row">
        <h3 className="h3">Hi {data.name && data.name}</h3>
      </div>
      <EvaluationDocumentMasterComponents bgcolor="white" width={"30vw"} position="fixed" evaldoc={selectedEvaluationComponent}/>
      <Accordion className="shadow rounded m-2 p-2">
        <Accordion.Header><Badge className="bg-success m-2" style={{borderRadius:"50%"}}>1</Badge> Select the colleague {"&"} evaluation period!</Accordion.Header>
        <Accordion.Body>
          <div className="row">
            <ColleagueSelection selectedColleague={selectColleague} />

            {previsouEvalDynamicDocuments.length>0 && 
            <div className="m-2 p-5">
            <h5 className="h5">Previous Evaluations</h5>
            <select className="form-control" onChange={async({target})=>{
           if(target.value!== "---"){
            try {
              const doc:IEvaluationDynamicDocument = await fetchAPI(`/admincmds/get-evaluation-document/${target.value}`, FetchMethods.GET)
              doc.startDate = new Date(doc.startDate);
              doc.currentPositionHeld = new Date(doc.currentPositionHeld)
              doc.appraisalDate = new Date (doc.appraisalDate);
              doc.dateConfirmation = new Date();
             setSelectedEvaluationComponent(doc)
            } catch (error) {
            setSelectedEvaluationComponent(emptyEvaluationDynamicDocument)
            }
           }
             
            
            }}>
                <option value="---">---</option>
              {
                previsouEvalDynamicDocuments.map((e:IEvaluationDynamicDocument)=>(
                  <option className="form-control" key={e._id} value={e._id}>{e.appraisalPeriod} - {new Date(e.appraisalDate).toLocaleDateString()}</option>
                ))
              }
              </select>
            </div>
            }
            {evalDynamicDocument?.selectedRep && (
              <div className="row m-2 p-2">
                <div className="col-lg-6 col-md-6-col-sm-1">
                  <table className="table table-bordered">
                    <tbody>
                      <tr>
                        <th>Name</th>
                        <td>
                          {evalDynamicDocument?.selectedRep.name && evalDynamicDocument?.selectedRep.name} {evalDynamicDocument?.selectedRep.surname && evalDynamicDocument?.selectedRep.surname}
                        </td>
                      </tr>
                      <tr>
                        <th>Department</th>
                        <td><DepartmentSelection evaldoc={evalDynamicDocument} selectedDepartment={selectDepartment}/></td>
                      </tr>
                      <tr>
                        <th>Position</th>
                        <td><JobnameSelection  evaldoc={evalDynamicDocument} selectedJobname={selectJobName}/></td>
                      </tr>
                    </tbody>
                  </table>
                </div>
                <div className="col-lg-6 col-md-6-col-sm-1">
                <table className="table table-bordered">
                    <tbody>
                      <tr>
                        <th>Start date of employment</th>
                        <td>
                          <input value={evalDynamicDocument.startDate?evalDynamicDocument.startDate.toISOString().substring(0, 10):new Date().toISOString().substring(0,10)} type="date" className="form-control" 
                          onChange={(e: any) => {
                            if(e.target.value.length>0){
                              setEvalDynamicDocument({...evalDynamicDocument,startDate:new Date(e.target.value)})
                            }else{
                              setEvalDynamicDocument({...evalDynamicDocument,startDate:new Date()})
                              dispatch(setError("Invalid date chosen"))
                            }
                          }}
                          />
                        </td>
                      </tr>
                      <tr>
                        <th>Current position held since</th>
                        <td>
                        <input value={evalDynamicDocument.currentPositionHeld?evalDynamicDocument.currentPositionHeld.toISOString().substring(0, 10):new Date().toISOString().substring(0,10)} type="date" className="form-control" 
                          onChange={(e: any) => {
                            if(e.target.value.length>0){
                              setEvalDynamicDocument({...evalDynamicDocument,currentPositionHeld:new Date(e.target.value)})
                            }else{
                              setEvalDynamicDocument({...evalDynamicDocument,currentPositionHeld:new Date()})
                              dispatch(setError("Invalid date chosen"))
                            }
                          }}
                          />
                        </td>
                      </tr>
                      <tr>
                        <th>Date of appraisal</th>
                        <td>
                        <input value={evalDynamicDocument.appraisalDate?evalDynamicDocument.appraisalDate.toISOString().substring(0, 10):new Date().toISOString().substring(0,10)} type="date" className="form-control" 
                          onChange={(e: any) => {
                            if(e.target.value.length>0){
                              setEvalDynamicDocument({...evalDynamicDocument,appraisalDate:new Date(e.target.value)})
                            }else{
                              setEvalDynamicDocument({...evalDynamicDocument,appraisalDate:new Date()})
                              dispatch(setError("Invalid date chosen"))
                            }
                          }}
                          />
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            )}
          </div>
{evalDynamicDocument?.selectedRep&&
   <div className="row">
   <h4 className="h4">Period selection </h4>
<AppraisalDocumentSelection  selectedAppraisalDocument={selectAppraisalDocument}/>
 </div>
}
       
        </Accordion.Body>
      </Accordion>
      {(evalDynamicDocument?.appraisalDocument && evalDynamicDocument?.selectedRep.name.length>0) &&
          <Accordion className="shadow rounded p-2 m-2">
          <Accordion.Header>
            <span> <Badge className="bg-success m-2" style={{borderRadius:"50%"}}>2</Badge> Time to take care of inputs, outputs and much more for{" "}<strong style={{color:"blueviolet"}}> {evalDynamicDocument?.selectedRep?.name&&evalDynamicDocument?.selectedRep?.name} {evalDynamicDocument?.selectedRep?.surname&&evalDynamicDocument?.selectedRep?.surname}</strong>
         </span>
          </Accordion.Header>
          <Accordion.Body>
            <div className="row shadow rounded p-4">
              <h4 className="h6 small">Rating distribution info</h4>
            <RatingDistribution data={evalDynamicDocument?.appraisalDocument.ratingDistribution}/>
            </div>
            
             {
              // INPUTS
             }
             <Accordion className="shadow roudned p-2 m-2">
              <Accordion.Header>Inputs</Accordion.Header>
              <Accordion.Body>
                <h6 className="h6p-2 m-2">
                    {evalDynamicDocument?.appraisalDocument.inputName}
                </h6>
                <p className="p-2 m-2">{evalDynamicDocument?.appraisalDocument.inputDescription}</p>
                <table className="table table-bordered p-2 m-2">
                    <tbody>
                      <tr>
                        <th>Performance inputs</th>
                        <th>Exaplanation</th>
                        <th>Importance (%)</th>
                        <th>Fulfillment (%)</th>
                        </tr>

                        {evalDynamicDocument?.appraisalDocument.inputs.map((input,i)=>(
                input.type ==="energizer" &&
                  <tr key={i}>
                       <td>{input.name}</td>
                        <td>{input.explanation} <Badge className="bg-warning small">{input.type}</Badge></td>
                        <td className="text-center">{input.importance} %</td>
                        <td><input min={0} max={100} defaultValue={0} className="form-control" type="number" 
                             onChange={({target})=>{
                              const importance = input.importance;
                              const type = input.type
                              const category = input.category
                              const name = input.name
                             //@ts-ignore
                             setEvalDynamicDocument({...evalDynamicDocument, inputsEnergizer:{
                              ...evalDynamicDocument?.inputsEnergizer,[i]:{
                                fulfillment:parseInt(target.value),
                                importance,
                                type,
                                category,
                                name
                              }
                             }})
                             
                            }}
                        /></td>
                    </tr>
                ))}
                  
              
              {evalDynamicDocument?.appraisalDocument.inputs.map((input,i)=>(
                input.type!=="energizer" &&
                    <tr key={i}>
                       <td>{input.name}</td>
                        <td>{input.explanation} <Badge className="bg-info small">{input.type}</Badge></td>
                        <td className="text-center">{input.importance} %</td>
                        <td><input min={0} max={100} defaultValue={0} className="form-control" type="number" name={`input${i}`} 
                        onChange={({target})=>{
                          const importance = input.importance;
                          const type = input.type
                          const category = input.category
                          const name = input.name
                         //@ts-ignore
                         setEvalDynamicDocument({...evalDynamicDocument, inputsStandard:{
                          ...evalDynamicDocument?.inputsStandard,[i]:{
                            fulfillment:parseInt(target.value),
                            importance,
                            type,
                            category,
                            name
                          }
                         }})
                         
                        }}
                        /></td>
                    </tr>
              ))}
             <tr>
              <td></td>
              <td>Energizer</td>
              <td>{evalDynamicDocument?.energizerInputFinalValue&&evalDynamicDocument?.energizerInputFinalValue.toFixed(2)}%</td>
              <td>{evalDynamicDocument?.energizerInputLetter&&evalDynamicDocument?.energizerInputLetter}</td>
             </tr>
             <tr>
              <td></td>
              <td>Overall rating for inputs (A,B,C,D,E,F)</td>
              <td>{evalDynamicDocument?.overallInputFinalvalue?evalDynamicDocument?.overallInputFinalvalue.toFixed(2): 0} %</td>
              <td>{evalDynamicDocument?.overallInputLetter?evalDynamicDocument?.overallInputLetter:""}</td>
             </tr>
              </tbody>
               </table>
        
              </Accordion.Body>
             </Accordion>
             {
              // Outputs
             }

              <Accordion className="shadow roudned p-2 m-2">
              <Accordion.Header>Outputs</Accordion.Header>
              <Accordion.Body>
                <h6 className="h6p-2 m-2">
                    {evalDynamicDocument?.appraisalDocument.outputName}
                </h6>
                <p className="p-2 m-2">{evalDynamicDocument?.appraisalDocument.outputDescription}</p>
                <table className="table table-bordered p-2 m-2">
                    <tbody>
                      <tr>
                        <th>Performance outputs</th>
                        <th>Exaplanation</th>
                        <th>Importance (%)</th>
                        <th>Fulfillment (%)</th>
                        </tr>

                        {evalDynamicDocument?.appraisalDocument.outputs.map((output,i)=>(
                    <tr key={i}>
                       <td>{output.name}</td>
                        <td>{output.explanation}</td>
                        <td className="text-center">{output.importance} %</td>
                        <td><input min={0} max={100} defaultValue={0} className="form-control" type="number" 
                             onChange={({target})=>{
                              const importance = output.importance;
                              const type = output.type
                              const category = output.category
                              const name = output.name
                             //@ts-ignore
                             setEvalDynamicDocument({...evalDynamicDocument, outputsStandard:{
                              ...evalDynamicDocument?.outputsStandard,[i]:{
                                fulfillment:parseInt(target.value),
                                importance,
                                type,
                                category,
                                name
                              }
                             }})
                             
                            }}
                        /></td>
                    </tr>
              ))}
              
             <tr>
              <td></td>
              <td>Overall rating for inputs (A,B,C,D,E,F)</td>
              <td>{evalDynamicDocument?.outputStandardFinalValue?evalDynamicDocument?.outputStandardFinalValue.toFixed(2): 0} %</td>
              <td>{evalDynamicDocument?.outputStandardLetter?evalDynamicDocument?.outputStandardLetter:""}</td>
             </tr>
              </tbody>
               </table>
        
              </Accordion.Body>
             </Accordion>
             {
              // Competencies
             }
             <Accordion className="shadow roudned p-2 m-2">
              <Accordion.Header>Functional Competencies</Accordion.Header>
              <Accordion.Body>
              <h6 className="h6p-2 m-2">
                    {evalDynamicDocument?.appraisalDocument.competenciesName}
                </h6>
                <p className="p-2 m-2">{evalDynamicDocument?.appraisalDocument.competenciesDescription}</p>
                {
                  evalDynamicDocument?.appraisalDocument.competencies.map((c,i)=>(
                    <div key={i}className="row shadow rounded p-4 m-2">
                      <h4 className="h6">{c.name}</h4>
                      <p>{c.explanation}</p>
                      <div className="row p-3 m-3">
                      <div className="col-lg-2 col-md-2 col-sm-1 col-xs-1">
                        <select onChange={({target})=>{
                              const name = c.name
                              const type = c.type
                              const category = c.category
                             //@ts-ignore
                             setEvalDynamicDocument({...evalDynamicDocument, competencies:{
                              ...evalDynamicDocument?.competencies,[i]:{
                                evaluation:target.value,
                                type,
                                category,
                                name
                              }
                             }})
                            }} className="form-control">
                          <option className="form-control" value="---">---</option>
                          <option className="form-control" value="A">A – Expert level</option>
                          <option className="form-control" value="B">B – Advanced level</option>
                          <option className="form-control" value="C">C – Good Level</option>
                          <option className="form-control" value="D">D – Satisfactory level</option>
                          <option className="form-control" value="E">E – Deficiencies</option>
                          <option className="form-control" value="F">F – Significant deficiencies</option>
                          <option className="form-control" value="X">X – impossible to evaluate</option>
                        </select>
                      </div>
                      <div className="col-lg-10 col-md-10 col-sm-10 col-xs-10">
                        {
                          //@ts-ignore
                          evalDynamicDocument?.competencies[i]?.evaluation && (
                            <>
                             <textarea style={{width:"100%"}} className="form-control" rows={3} onChange={(({target})=>{
                       let someObject = {
                        ...evalDynamicDocument?.competencies,[i]:{
                          evaluationInput:target.value,
                          type:c.type,
                          category:c.category,
                          name:c.name,
                          //@ts-ignore
                          evaluation:evalDynamicDocument?.competencies[i]?.evaluation
                        }
                      }
                      setEvalDynamicDocument({...evalDynamicDocument, competencies:someObject})
                        })} />
                            </>
                          )
                        }
                       
                      </div>
                      </div>
                    </div>
                  ))
                }
      
                <table className="table table-bordered p-2 m-2">
                  <tbody>
                    <tr>
                      <td colSpan={4}>Overall rating for competencies (A,B,C,D,E,F,X)</td>
                      <td>{evalDynamicDocument.competenciesFinaPercentageNumber&&evalDynamicDocument.competenciesFinaPercentageNumber.toFixed(2)} %({
                       (evalDynamicDocument.competenciesLetter === "A" && 6) ||
                        (evalDynamicDocument.competenciesLetter === "B" && 5 )||
                        (evalDynamicDocument.competenciesLetter === "C" && 4) ||
                        (evalDynamicDocument.competenciesLetter === "D" && 3) ||
                        (evalDynamicDocument.competenciesLetter === "E" && 2) ||
                        (evalDynamicDocument.competenciesLetter === "F" && 1) ||
                        (evalDynamicDocument.competenciesLetter === "X" && 0)
                      })</td>
                      <td>{evalDynamicDocument.competenciesLetter&&evalDynamicDocument.competenciesLetter}</td>
                    </tr>
                  </tbody>
                </table>
              </Accordion.Body>
             </Accordion>
             
             {
              // Comments
             }
             <Accordion className="shadow roudned p-2 m-2">
              <Accordion.Header>Comments</Accordion.Header>
              <Accordion.Body>
              <h6 className="h6p-2 m-2">
                    {evalDynamicDocument?.appraisalDocument.commentsName}
                </h6>
                <p className="p-2 m-2">{evalDynamicDocument?.appraisalDocument.commentsDescription}</p>

                {
                  evalDynamicDocument?.appraisalDocument.comments.map((c,i)=>(
                    <div key={i} className="row shadow rounded p-4 m-2">
                      <div className="col-lg-4 col-md-4 col-sm-1 col-xs-1">{c.name}({c.explanation})</div>
                      <div className="col-lg-8 col-md-8 col-sm-1 col-xs-1"><textarea rows={3}  className="form-control" style={{width:"100%"}}  onChange={({target})=>{
                              const name = c.name
                              const type = c.type
                              const category = c.category
                             //@ts-ignore
                             setEvalDynamicDocument({...evalDynamicDocument, comments:{
                              ...evalDynamicDocument?.comments,[i]:{
                                evaluation:target.value,
                                type,
                                category,
                                name
                              }
                             }})
                            }}/></div>
                    </div>
                  ))
                }
                
              </Accordion.Body>
             </Accordion>
             {
              // Charts
             }
             <Accordion className="shadow roudned p-2 m-2">
              <Accordion.Header>Charts</Accordion.Header>
              <Accordion.Body>
                <CompetenciesChart EvalDynamicDocument={evalDynamicDocument}/>
                <EnergizerAssesmentChart EvalDynamicDocument={evalDynamicDocument} />
                <InputOutputChart EvalDynamicDocument={evalDynamicDocument}/>
              </Accordion.Body>
             </Accordion>
             
             <div className="row rounded p-5 m-5 shadow">
                  <h3 className="h5">
                    Lets finish this up! 
                  </h3>
                  <div className="row">
                  <div className="col-lg-5 col-md-5 col-sm-1 col-xs-1 shadow rouned p-2 m-3">
                    I, <strong className="text-warning">{data.name} {data.surname}</strong>, as a manager, hereby confirm by selecting the consent button that the performance appraisal discussion was completed!
                    <br />
                    <br />
                   <div onClick={()=>{
                    setEvalDynamicDocument({...evalDynamicDocument,managerConsentButton:!evalDynamicDocument.managerConsentButton})
                  }} className={evalDynamicDocument.managerConsentButton?"btn btn-success":"btn btn-danger"}>{evalDynamicDocument.managerConsentButton?"Confirmed":"Not confirmed"}</div>
                  </div>
                  <div className="col-lg-5 col-md-5 col-sm-1 col-xs-1 shadow rouned p-2 m-3">
                    I, <strong className="text-info">{evalDynamicDocument.selectedRep.name} {evalDynamicDocument.selectedRep.surname}</strong>, as an employee, hereby confirm by selecting the consent button that the performance appraisal discussion was completed!
                    <br />
                    <br />
                   <div onClick={()=>{
                    setEvalDynamicDocument({...evalDynamicDocument,employeeConsentButton:!evalDynamicDocument.employeeConsentButton})
                    }} className={evalDynamicDocument.employeeConsentButton?"btn btn-success":"btn btn-danger"}>{evalDynamicDocument.employeeConsentButton?"Confirmed":"Not confirmed"}</div>
                  </div>
                  </div>
               
                  {(evalDynamicDocument.employeeConsentButton&&evalDynamicDocument.managerConsentButton)&&
                  <div className="row justify-content-center align-items-center p-5">
                    <h4 className="h5 text-center">Confirm today's date</h4>
                    <input readOnly={true} value={evalDynamicDocument.dateConfirmation?evalDynamicDocument.dateConfirmation.toISOString().substring(0, 10):new Date().toISOString().substring(0,10)} type="date" className="form-control" 
                          onChange={(e: any) => {
                            if(e.target.value.length>0){
                              setEvalDynamicDocument({...evalDynamicDocument,dateConfirmation:new Date(e.target.value)})
                            }else{
                              setEvalDynamicDocument({...evalDynamicDocument,dateConfirmation:new Date()})
                              dispatch(setError("Invalid date chosen"))
                            }
                          }}
                          />
                  {
                    (evalDynamicDocument.dateConfirmation && evalDynamicDocument.managerConsentButton && evalDynamicDocument.employeeConsentButton)&&
                     <div onClick={saveEvaluationForGivenColleague} className="btn btn-primary m-5">Save evaluation!</div>
                  }
          
                  </div>
                  }
                </div>
          </Accordion.Body>
        </Accordion>
      }

    </AdminContainer>
  );
};

export default EvaluateColleage;
