import { useState } from "react";
import { Accordion } from "react-bootstrap";
import { useDispatch } from "react-redux";
import { FetchMethods } from "../../../interface/Enums/methods.enum";
import { IDynamicTableShape } from "../../../interface/DynamicTableShape.interface";
import { setError } from "../../../store/reducers/errorReducer";
import { fetchAPI } from "../../../utils/FetchApi";

const DynamicTableShapes = ({
  data,
  updatedone,
}: {
  data: IDynamicTableShape;
  updatedone: Function;
}) => {
  const dispatch = useDispatch();
  const [dObject, setDObject] = useState<IDynamicTableShape>(data);
  const [isEditAllowed, setIsEditAllowed] = useState<boolean>(true);

  //update the data
  const updateData = async () => {
    try {
      const updateData: IDynamicTableShape[] = await fetchAPI(
        `/evaluationcmds/annual-competency-development/update`,
        FetchMethods.PATCH,
        dObject
      );

      if (updateData.hasOwnProperty("error"))
            //@ts-ignore
        throw new Error(updateData.message);

      updatedone();
      dispatch(setError("Data updated successfully!"));
    } catch (error: any) {
      dispatch(setError(error.toString()));
    }
  };

  //delete the data
  const deleteData = async () => {
    //confirm the delete
    if (!window.confirm("Are you sure you want to delete this data?")) return;
    try {
      const deleteData: IDynamicTableShape[] = await fetchAPI(
        `/evaluationcmds/annual-competency-development/delete`,
        FetchMethods.DELETE,
        dObject
      );
      //@ts-ignore
      if (deleteData.hasOwnProperty("error"))
            //@ts-ignore
        throw new Error(deleteData.message);

      updatedone();
    } catch (error: any) {
      dispatch(setError(error.toString()));
    }
  };

  return (
    <Accordion>
      <Accordion.Header className="shadow rounded border">
        {dObject.name}
      </Accordion.Header>
      <Accordion.Body>
        <button
          className={
            isEditAllowed ? "btn btn-warning m-1" : "btn btn-success m-1"
          }
          onClick={() => {
            setIsEditAllowed(!isEditAllowed);
          }}
        >
          {isEditAllowed ? "Allow editing" : "Stop editing"}
        </button>

        <button
          className="btn btn-primary m-1"
          onClick={() => {
            updateData();
          }}
        >
          Update
        </button>

        <button
          className="btn btn-danger m-1"
          onClick={() => {
            deleteData();
          }}
        >
          Delete
        </button>

        <h2>Name</h2>
        <input
          className="form-control p-1 m-2"
          style={{ background: isEditAllowed ? "grey" : "white" }}
          type="text"
          value={dObject.name}
          readOnly={isEditAllowed}
          onChange={({ target }) => {
            setDObject({ ...dObject, name: target.value });
          }}
        />
        <h3>Description</h3>
        <input
          className="form-control p-1 m-2"
          style={{ background: isEditAllowed ? "grey" : "white" }}
          type="text"
          value={dObject.description}
          readOnly={isEditAllowed}
          onChange={({ target }) => {
            setDObject({ ...dObject, description: target.value });
          }}
        />
        <h3>Notes</h3>
        <input
          className="form-control p-1 m-2"
          style={{ background: isEditAllowed ? "grey" : "white" }}
          type="text"
          value={dObject.notes}
          readOnly={isEditAllowed}
          onChange={({ target }) => {
            setDObject({ ...dObject, notes: target.value });
          }}
        />
        <h3>Country</h3>
        <input
          className="form-control p-1 m-2"
          style={{ background: isEditAllowed ? "grey" : "white" }}
          type="text"
          value={dObject.country}
          readOnly={isEditAllowed}
          onChange={({ target }) => {
            setDObject({ ...dObject, country: target.value });
          }}
        />
        <h3>Columns</h3>
        <div className="row">
          {dObject.columns.map((t: string, i: number) => (
            <div className="row" key={i}>
             <div className="form-group mb-2 col-5">
 <input
                className="form-control p-1 m-2"
                style={{ background: isEditAllowed ? "grey" : "white" }}
                type="text"
                value={t}
                readOnly={isEditAllowed}
                onChange={({ target }) => {
                  const arr = [...dObject.columns];
                  arr[i] = target.value;
                  setDObject({ ...dObject, columns: arr });
                }}
              />
</div>
<div className="form-group mx-sm-3 mb-2 col-3">
<button
                className="btn btn-danger m-1"
                onClick={() => {
                  //confirm the delete
                  if (
                    !window.confirm(
                      "Are you sure you want to delete this column?"
                    )
                  )
                    return;
                  const arr = [...dObject.columns];
                  arr.splice(i, 1);
                  setDObject({ ...dObject, columns: arr });
                }}
              >
                Remove
              </button>
</div>
            </div>


          ))}

          <button
            className="btn btn-primary m-1"
            onClick={() => {
              setDObject({ ...dObject, columns: [...dObject.columns, ""] });
            }}
          >
            Add new column
          </button>
        </div>
      </Accordion.Body>
    </Accordion>
  );
};

export default DynamicTableShapes;
