import  {Component} from "react"
export class ErrorBoundary extends Component {
    constructor(props:any) {
      super(props);
      this.state = { hasError: false };
    }
  
    static getDerivedStateFromError(error:any) {
      // Update state so the next render will show the fallback UI.
      console.log(error)

      return { hasError: true };
    }
  
    componentDidCatch(error:any, errorInfo:any) {
      // You can also log the error to an error reporting service
      console.log(error)
      console.log(errorInfo)
    }
  
    render() {
        //@ts-ignore
      if (this.state.hasError) {
        // You can render any custom fallback UI
        return <h1>Something went wrong. Please, reload app and start over with your work!</h1>;
      }
  //@ts-ignore
      return this.props.children; 
    }
  }