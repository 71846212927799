import React from 'react'
import { Accordion } from 'react-bootstrap'

type FeedbackType = {name:string, country:string, description:string, answer:string}[]

const Feedback = ({feedback}:{feedback:FeedbackType}) => {
  return (
    <>
    {
        feedback.map((f,i)=>
        <Accordion key={i} className="p-2 border rounded">
            <Accordion.Header>
                {f.name}
            </Accordion.Header>
            <Accordion.Body>
                <p><strong>Description</strong>{f.description}</p>
                <p>
                {
                    f.answer
                }
                </p>
                
            </Accordion.Body>
        </Accordion>
        )
    }
    </>
  )
}

export default Feedback