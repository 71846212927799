import { useEffect, useState } from 'react'
import { IInputOutput } from '../../../../interface'
const InputsOutputs = ({inputsoutputs}:{inputsoutputs:{[key:string]:IInputOutput}}) => {

  const [inputsOutputs, setInputsOutputs] = useState<IInputOutput[]>([])

  useEffect(()=>{
    const tempArr:IInputOutput[] = [];
    for(let key in inputsoutputs){
      tempArr.push(inputsoutputs[key])
    }
    setInputsOutputs(tempArr)
  },[inputsoutputs])
  return (
    <div style={{backgroundColor:"white"}}>
    {inputsOutputs.length>0 &&
    inputsOutputs.map((io:IInputOutput, i)=>(
      <div key={i} className='border-bottom p-1 m-1'>
        <div className="row">
          <div className="col">{io.name}</div>
          <div className="col">{io.importance}</div>
          <div className="col">{io.fulfillment}</div>
          <div className="col">{io.type}</div>
        </div>
    </div>
    ))
    }
    </div>
   
  )
}

export default InputsOutputs