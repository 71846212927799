import { useState } from "react";
import { Accordion } from "react-bootstrap";
import { useDispatch } from "react-redux";
import AdminContainer from "../../../admin-components/AdminContainer";
import CountryFilter from "../../../components/CountryFilter";
import { FetchMethods } from "../../../interface/Enums/methods.enum";
import { IDynamicTableShape } from "../../../interface/DynamicTableShape.interface";
import { setError } from "../../../store/reducers/errorReducer";
import { fetchAPI } from "../../../utils/FetchApi";
import CareerInterview from "./CreateAnnualEvaluationComponents/CareerInterview";
import EvaluationDocuments from "./CreateAnnualEvaluationComponents/EvaluationDocuments";
import EvaluationSummary from "./CreateAnnualEvaluationComponents/EvaluationSummary";
import ObservationAndFeedback from "./CreateAnnualEvaluationComponents/FeedbackAndObservation";
import FutureGoals from "./CreateAnnualEvaluationComponents/FutureGoals";
import SummaryTable from "./CreateAnnualEvaluationComponents/SummaryTable";

type Table = {
  cell1: string;
  cell2: string;
  cell3: string;
  cell4: string;
  cell5: string;
  cell6: string;
  cell7: string;
  cell8: string;
  cell9: string;
};

type TableData = {
  name: string;
  country: string;
  description: string;
  columns: Table[];
  _id: string;
  created_at: string;
  updated_at: string;
};

type SaveShape = {
  name: string;
  country: string;
  description: string;
  evaluationSummary: IDynamicTableShape;
  goalsByManager: IDynamicTableShape[];
  interview: IDynamicTableShape[];
  summaryTable: TableData;
  feedbackAndObesrvations: IDynamicTableShape[];
};
const CreateAnnualEvaluation = () => {
  const dispatch = useDispatch();

  const [selectedCountry, setSelectedCountry] = useState<string>("");

  const pickCountry = (country: string) => {
    setSelectedCountry(country);
  };
  const [selectedEvaluationSummary, setSelectedEvaluationSummary] =
    useState<IDynamicTableShape>();
  const pickEvaluationSummary = (evalsummary: IDynamicTableShape) => {
    setSelectedEvaluationSummary(evalsummary);
  };

  const [selectedFutureGoals, setSelectedFutureGoals] = useState<
    IDynamicTableShape[]
  >([]);
  const pickFutureGoals = (futuregoals: IDynamicTableShape) => {
    //ads the item to the array
    setSelectedFutureGoals([...selectedFutureGoals, futuregoals]);
  };

  const [selectedCareerInterview, setSelectedCareerInterview] = useState<
    IDynamicTableShape[]
  >([]);
  const pickCareerInterview = (careerinterview: IDynamicTableShape) => {
    //ads the item to the array
    setSelectedCareerInterview([...selectedCareerInterview, careerinterview]);
  };

  const [selectedSummaryTable, setSelectedSummaryTable] = useState<TableData>();
  const pickSummaryTable = (summarytable: TableData) => {
    //ads the item to the array
    setSelectedSummaryTable(summarytable);
  };

  const [selectedFeedbackAndObservation, setSelectedFeedbackAndObservation] =
    useState<IDynamicTableShape[]>([]);
  const pickFeedbackAndObservation = (
    feedbackandobservation: IDynamicTableShape
  ) => {
    //ads the item to the array
    setSelectedFeedbackAndObservation([
      ...selectedFeedbackAndObservation,
      feedbackandobservation,
    ]);
  };

  //state for name
  const [name, setName] = useState<string>("");
  //state for description
  const [description, setDescription] = useState<string>("");
  //save function that checks for all the data in states
  const [isReloadNeeded, setIsReloadNeeded] = useState(false)
  const save = async () => {
    if (selectedCountry === "") {
      dispatch(setError("Please select a country"));

      return;
    }
    if (selectedEvaluationSummary === undefined) {
      dispatch(setError("Please select an evaluation summary"));
      return;
    }
    if (selectedFutureGoals.length === 0) {
      dispatch(setError("Please select future goals"));
      return;
    }
    if (selectedCareerInterview.length === 0) {
      dispatch(setError("Please select career interview"));
      return;
    }
    if (selectedSummaryTable === undefined) {
      dispatch(setError("Please select summary table"));
      return;
    }
    if (selectedFeedbackAndObservation.length === 0) {
      dispatch(setError("Please select feedback and observation"));
      return;
    }
    //save to database
    const saveShape: SaveShape = {
      name,
      country: selectedCountry,
      description,
      evaluationSummary: selectedEvaluationSummary,
      goalsByManager: selectedFutureGoals,
      interview: selectedCareerInterview,
      summaryTable: selectedSummaryTable,
      feedbackAndObesrvations: selectedFeedbackAndObservation,
    };
    //API call to save
    try {
      const data = await fetchAPI(
        "/evaluationcmds/annual-evaluation/create",
        FetchMethods.POST,
        saveShape
      );
      //@ts-ignore
      if (data.hasOwnProperty("error")) throw new Error(data.message);

      dispatch(setError("Annual evaluation created successfully"));
      //reset all states
      setSelectedCountry("");
      setSelectedEvaluationSummary(undefined);
      setSelectedFutureGoals([]);
      setSelectedCareerInterview([]);
      setSelectedSummaryTable(undefined);
      setSelectedFeedbackAndObservation([]);
      setName("");
      setDescription("");
        setIsReloadNeeded(!isReloadNeeded);
    setCreateNewEvaluationDocument(false)

    } catch (err: any) {
      dispatch(setError(err.toString()));
    }
  };
  const [createNewEvaluationDocument, setCreateNewEvaluationDocument] =
    useState<boolean>(false);

  
  return (
    <AdminContainer>
      <div className="row justify-content-center align-items-center">

        <div className="col-md-9 m-2">
          <h5 className="h5">List of created Evaluation Documents</h5>
          <EvaluationDocuments bool={isReloadNeeded}/>
        </div>

        <div className="col-md-2 m-2">
          <div
            className="btn btn-danger"
            onClick={() => {
              setCreateNewEvaluationDocument(!createNewEvaluationDocument);
            }}
          >
            Create new Evaluation Document
          </div>
        </div>

      </div>
      {createNewEvaluationDocument && (
        <>
          <div className="row">
            <CountryFilter returnedCountry={pickCountry} />
          </div>
          <hr className="hr" />

          <Accordion className="border shadow p-2 m-2 rounded">
            <Accordion.Header>1. Evaluation Summary</Accordion.Header>
            <Accordion.Body>
              <div className="border p-2 m-2">
                <h5 className="h5">Select Evaluation summary</h5>
                <EvaluationSummary evalsummary={pickEvaluationSummary} />
                {selectedEvaluationSummary && (
                  <div className="row">
                    <div
                      className="p-4 m-4 border shadow rounded"
                      style={{ maxWidth: "100%", width: "95%" }}
                    >
                      <p>{selectedEvaluationSummary.description}</p>
                      <table className="table table-bordered">
                        <tbody>
                          <tr>
                            {selectedEvaluationSummary.columns.map(
                              (column, index) => {
                                return <td key={index}>{column}</td>;
                              }
                            )}
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>
                )}
              </div>
              <div className="border p-2 m-2">
                <h5 className="h5">
                  Select Future goals (you can select more than 1)
                </h5>
                <FutureGoals futuregoals={pickFutureGoals} />
                {selectedFutureGoals.length > 0 && (
                  <div className="row shadow rounded p-4 m-4">
                    {selectedFutureGoals.map(
                      (item: IDynamicTableShape, index: number) => {
                        return (
                          <div className="mb-4" key={index}>
                            <strong className="p-2 m-2">{item.name} </strong>
                            <table className="table table-bordered">
                              <tbody>
                                <tr>
                                  {item.columns.map((column, index) => {
                                    return <td key={index}>{column}</td>;
                                  })}
                                </tr>
                              </tbody>
                            </table>
                          </div>
                        );
                      }
                    )}
                  </div>
                )}
              </div>

              <div className="border p-2 m-2">
                <h5 className="h5">Select Summary Table</h5>

                <SummaryTable evalsummarytable={pickSummaryTable} />
                {selectedSummaryTable && (
                  <div className="row">
                    <div
                      className="p-4 m-4 border shadow rounded"
                      style={{ maxWidth: "100%", width: "95%" }}
                    >
                      <p>{selectedSummaryTable.description}</p>
                      <table className="table table-bordered">
                        <thead>
                          <tr>
                            <th scope="col" colSpan={2}>
                              {selectedSummaryTable.columns[0].cell1}
                            </th>
                            <th scope="col" colSpan={2}>
                              {selectedSummaryTable.columns[0].cell2}
                            </th>
                            <th scope="col" colSpan={2}>
                              {selectedSummaryTable.columns[0].cell3}
                            </th>
                          </tr>
                          <tr>
                            <th scope="col">
                              {selectedSummaryTable.columns[0].cell4}
                            </th>
                            <th scope="col">
                              {selectedSummaryTable.columns[0].cell5}
                            </th>
                            <th scope="col">
                              {selectedSummaryTable.columns[0].cell6}
                            </th>
                            <th scope="col">
                              {selectedSummaryTable.columns[0].cell7}
                            </th>
                            <th scope="col">
                              {selectedSummaryTable.columns[0].cell8}
                            </th>
                            <th scope="col">
                              {selectedSummaryTable.columns[0].cell9}
                            </th>
                          </tr>
                        </thead>
                      </table>
                    </div>
                  </div>
                )}
              </div>
            </Accordion.Body>
          </Accordion>
          <Accordion className="border shadow p-2 m-2 rounded">
            <Accordion.Header>2. Career interview</Accordion.Header>
            <Accordion.Body>
              <CareerInterview careerinterviews={pickCareerInterview} />
              {selectedCareerInterview.length > 0 && (
                <div className="row shadow rounded p-4 m-4">
                  {selectedCareerInterview.map(
                    (item: IDynamicTableShape, index: number) => {
                      return (
                        <div key={index}>
                          <div className="border shadow rounded p-2 m-2">
                            {item.name}
                          </div>
                        </div>
                      );
                    }
                  )}
                </div>
              )}
            </Accordion.Body>
          </Accordion>
          <Accordion className="border shadow p-2 m-2 rounded">
            <Accordion.Header>3. Select Observation & Feedback</Accordion.Header>
            <Accordion.Body>
              <ObservationAndFeedback
                observationandfeedback={pickFeedbackAndObservation}
              />
              {selectedFeedbackAndObservation.length > 0 && (
                <div className="row shadow rounded p-4 m-4">
                  {selectedFeedbackAndObservation.map(
                    (item: IDynamicTableShape, index: number) => {
                      return (
                        <div key={index}>
                          <div className="border shadow rounded p-2 m-2">
                            {item.name}
                          </div>
                        </div>
                      );
                    }
                  )}
                </div>
              )}
            </Accordion.Body>
          </Accordion>

          <Accordion className="border shadow p-2 m-2 rounded">
            <Accordion.Header>
              4. Confirm setup and create new Annual Evaluation
            </Accordion.Header>
            <Accordion.Body>
              <div className="row m-2 p-2">
                <form>
                  <div className="form-group m-2">
                    <label htmlFor="name">Unique name</label>
                    <input
                      type="text"
                      className="form-control"
                      id="name"
                      placeholder="Enter name"
                      onChange={(e) => setName(e.target.value)}
                    />
                  </div>
                  <div className="form-group m-2">
                    <label htmlFor="description">
                      Description of the document
                    </label>
                    <textarea
                      className="form-control"
                      id="description"
                      rows={3}
                      onChange={(e) => setDescription(e.target.value)}
                    ></textarea>
                  </div>
                  <button
                    type="button"
                    className="btn btn-primary m-2"
                    onClick={(e) => {
                      e.preventDefault();
                      save();
                    }}
                  >
                    Save
                  </button>
                </form>
              </div>
            </Accordion.Body>
          </Accordion>
        </>
      )}
    </AdminContainer>
  );
};

export default CreateAnnualEvaluation;
