import React from 'react'
import { Accordion } from 'react-bootstrap'

const Interview = ({interview}:{interview: {name:string, country:string, description:string, answer:string}[]}) => {
  return (
   <Accordion className='border rounded m-1'>
    <Accordion.Header>
        Career Interview
    </Accordion.Header>
    <Accordion.Body>
        {
            interview.map((int,i:number)=>
            <Accordion key={i} className="p-2 border rounded">
                <Accordion.Header>
                    {int.name}
                </Accordion.Header>
                <Accordion.Body>
                <p className='small'><strong>Description: </strong>{int.description}</p>
                <p>{int.answer}</p>
                </Accordion.Body>
            </Accordion>
            )
        }
    </Accordion.Body>
   </Accordion>
  )
}

export default Interview