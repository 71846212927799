import React, { useEffect, useState } from "react";

import { useSelector } from "react-redux";
import { authStateInterface } from "../interface/authStateInterface";
import { Roles } from "../interface/Enums/Roles.enum";
const AdminContainer = ({ children }: { children: React.ReactNode }) => {
    const authState: authStateInterface = useSelector((data: any) => { return data.auth });

    const [showComponent, setShowComponent] = useState<boolean>(false);

    useEffect(() => {
     if(authState.user.authLevel===Roles.OmniAdmin){
        setShowComponent(true)
     }else if(authState.user.authLevel===Roles.AreaManager || authState.user.authLevel===Roles.SalesForceManager){
        setShowComponent(true)
     }else{
        setShowComponent(false)
     }
    }, [authState])
    
    return (
        <div className="admin-container">
            {showComponent&& <>
                {children}
            </>}

        </div>
    );
};

export default AdminContainer;
