import { useEffect, useState } from "react"
import { fetchAPI } from "../utils/FetchApi"
import { FetchMethods } from "../interface/Enums/methods.enum"
import { IError } from "../interface"


const GlobalFooter = () => {
const [version, setVersion] = useState<string|undefined>()
useEffect(() => {
  (async()=>{
    try{
      const data:IError & {version:string} = await fetchAPI("/versioncontrol", FetchMethods.GET);
      if(data){
        setVersion(data.version)
      }
    }catch(error:any){
      console.log(error)
    }
  })()
}, [])

  return (
    <div className="container fixed bottom">
      
      <small style={{fontSize:10}} className="small text-grey text-center">
     FrontEnd version 0.0.8.1 | BackEnd version {version?version:"coulnt reach server"}
      </small>
</div>
  )
}

export default GlobalFooter