import { Accordion } from "react-bootstrap";
import { IUser } from "../../../../../interface/IUser.interface";
import EvaluationSummary from "./AEMasterPartials/EvaluationSummary";
import Feedback from "./AEMasterPartials/Feedback";
import Interview from "./AEMasterPartials/Interview";
import SummaryTable from "./AEMasterPartials/SummaryTable";
import TheGoals from "./AEMasterPartials/TheGoals";
import User from "./AEMasterPartials/UserDetail";
import { useRef, useState } from "react";
import { BsEye, BsPrinter, BsTable } from "react-icons/bs";


type Table = {
  cell1: string;
  cell2: string;
  cell3: string;
  cell4: string;
  cell5: string;
  cell6: string;
  cell7: string;
  cell8: string;
  cell9: string;
};

type TableData = {
  name: string;
  country: string;
  description: string;
  columns: Table[];
  _id: string;
  created_at: string;
  updated_at: string;
  answer: Table;
};

type AnnualEvaluationDocumentByUser = {
  _id: string;
  feedbackAndObesrvations: {
    name: string;
    country: string;
    description: string;
    answer: string;
  }[];
  interview: {
    name: string;
    country: string;
    description: string;
    answer: string;
  }[];
  user: IUser;
  summaryTable: TableData;
  goalsByManager: {
    name: string;
    country: string;
    description: string;
    notes: string;
    columns: string[];
    answer: [][];
  }[];
  evaluationSummary: {
    name: string;
    country: string;
    description: string;
    notes: string;
    columns: string[];
    answer: [][];
  };
  country: string;
  description: string;
  repNameQuery: string;
  name: string;
  annualPeriodName: string;
};

const AnnualEvaluationMasterDisplayComponent = ({
  evaldoc,
  position = "absolute",
  width,
  bgcolor,
}: {
  evaldoc: AnnualEvaluationDocumentByUser;
  position?: "absolute" | "relative" | "fixed";
  width?: number | string;
  bgcolor?: string;
}) => {
  const [view, setView] = useState<boolean>(false);
  const printable = useRef<HTMLDivElement>(null);
  const [whatToPrint, setWhatToPrint] = useState<string>("")

  //on click opens a new window with whatToPrint, which is a clean html

  const print = async () => {
    const printWindow = window.open("", "PRINT", "height=400,width=600");
    if (printWindow) {
      printWindow.document.write(
        `<html><head>
        <link rel="stylesheet" href="https://cdn.jsdelivr.net/npm/bootstrap@4.3.1/dist/css/bootstrap.min.css" integrity="sha384-ggOyR0iXCbMQv3Xipma34MD+dH/1fQ784/j6cY/iJTQUOhcWr7x9JvoRxT2MZw1T" crossorigin="anonymous">
        <title>${evaldoc.user.name} - ${evaldoc.annualPeriodName}</title>
        `
      );
      printWindow.document.write("</head><body >");
      printWindow.document.write(whatToPrint);
      printWindow.document.write("</body></html>");
      printWindow.document.close(); // necessary for IE >= 10
      printWindow.focus(); // necessary for IE >= 10*/
      printWindow.print();
      
      window.setTimeout(() => {
        printWindow.close();
      }
      , 4000);

    }
  };

  return (
    <div
      className="container small rounded p-2"
      style={{
        position: position,
        bottom: 0,
        right: 0,
        width: width || "auto",
        zIndex: 1,
        overflow: "scroll",
        maxHeight: "80vh",
        flexWrap: "wrap",
        backgroundColor: bgcolor || "transparent",
      }}
    >
      {!evaldoc ? (
        "No Document loaded"
      ) : (
        <div className="row">
          <div className="col-1 flex-col">
            <div
              className={`btn ${view ? "btn-primary" : "btn-success"}`}
              onClick={() => {
                setView(!view);
              }}
            >
              {!view ? <BsEye /> : <BsTable color="white"/>} 
            </div>
          
            
           
          </div>
          <div className="col-11">
            <Accordion
              className="
      border p-2 small"
            >
              <Accordion.Header style={{ fontSize: 12 }}>
                {evaldoc.annualPeriodName}
              </Accordion.Header>
              {!view && (
                <Accordion.Body className="p-4 shadow rounded">
                    
              
            
                  <p>{evaldoc.description}</p>
                  <User user={evaldoc.user} />
                  <EvaluationSummary document={evaldoc.evaluationSummary} />
                  <Accordion className="border rounded m-1">
                    <Accordion.Header>Goals</Accordion.Header>
                    <Accordion.Body>
                      {evaldoc.goalsByManager.map((g, i: number) => (
                        <TheGoals key={i} thegoal={g} />
                      ))}
                    </Accordion.Body>
                  </Accordion>
                  <SummaryTable table={evaldoc.summaryTable} />
                  <Interview interview={evaldoc.interview} />
                  <Accordion className="border rounded m-1">
                    <Accordion.Header>Feedback & Observations</Accordion.Header>
                    <Accordion.Body>
                      <Feedback feedback={evaldoc.feedbackAndObesrvations} />
                    </Accordion.Body>
                  </Accordion>
                </Accordion.Body>
              )}
              {view && (
                <Accordion.Body>

                  <div className="btn btn-warning">
              <BsPrinter color="white"  onClick={()=>{
                if(printable.current){
                  const printf:string = printable.current.outerHTML;
                   setWhatToPrint(printf)
                   print()
                }
                }}/>
              </div>
              <hr />
                <div className="col-12 m-1 px-2" ref={printable}>
                 
                  {
                    //evaluation summary
                    evaldoc.evaluationSummary && (
                      <div className="row px-2">
                        <h6>{evaldoc.evaluationSummary.name}</h6>
                        <p>
                          <strong>Description: </strong>
                          {evaldoc.evaluationSummary.description}
                        </p>
                        <p>
                          <strong>Notes: </strong>
                          {evaldoc.evaluationSummary.notes}
                        </p>
                        <table className="table table-bordered rounded shadow small p-3">
                          <tbody>
                            <tr>
                              {evaldoc.evaluationSummary.columns &&
                                evaldoc.evaluationSummary.columns.map(
                                  (c: string, i: number) => {
                                    return <th key={i}>{c}</th>;
                                  }
                                )}
                            </tr>
                            {evaldoc.evaluationSummary.answer &&
                              evaldoc.evaluationSummary.answer.map(
                                (row: string[], index: number) => {
                                  return (
                                    <tr
                                      key={index}
                                      style={{ backgroundColor: "#f0efef" }}
                                    >
                                      {row.map((f: string, k: number) => {
                                        return <td key={k}>{f}</td>;
                                      })}
                                    </tr>
                                  );
                                }
                              )}
                          </tbody>
                        </table>
                      </div>
                    )
                  }
                  <hr />

                  {evaldoc.goalsByManager.length > 0 &&
                    evaldoc.goalsByManager.map((g, i: number) => (
                      <div key={i}>
                        <h6>{g.name}</h6>
                        <p>{g.notes}</p>

                        <table className="table table-bordered rounded shadow small p-3">
                          <tbody>
                            <tr>
                              {g.columns &&
                                g.columns.map((c: string, i: number) => {
                                  return <th key={i}>{c}</th>;
                                })}
                            </tr>
                            {g.answer &&
                              g.answer.map((row: string[], index: number) => {
                                return (
                                  <tr
                                    key={index}
                                    style={{ backgroundColor: "#f0efef" }}
                                  >
                                    {row.map((f: string, k: number) => {
                                      return <td key={k}>{f}</td>;
                                    })}
                                  </tr>
                                );
                              })}
                          </tbody>
                        </table>
                      </div>
                    ))}
                  <hr />
                  {
                    //summary table
                    evaldoc.summaryTable && (
                      <div className="row px-2">
                        <h6>{evaldoc.summaryTable.name}</h6>
                        {evaldoc.summaryTable.columns.length > 0 &&
                          evaldoc.summaryTable.answer && (
                            <table className="table table-bordered">
                              <tbody>
                                <tr>
                                  <th colSpan={2}>
                                    {evaldoc.summaryTable.columns[0].cell1}
                                  </th>
                                  <th colSpan={2}>
                                    {evaldoc.summaryTable.columns[0].cell2}
                                  </th>
                                  <th colSpan={2}>
                                    {evaldoc.summaryTable.columns[0].cell3}
                                  </th>
                                </tr>
                                <tr>
                                  <td>
                                    {evaldoc.summaryTable.columns[0].cell4}
                                  </td>
                                  <td>
                                    {evaldoc.summaryTable.columns[0].cell5}
                                  </td>
                                  <td>
                                    {evaldoc.summaryTable.columns[0].cell6}
                                  </td>
                                  <td>
                                    {evaldoc.summaryTable.columns[0].cell7}
                                  </td>
                                  <td>
                                    {evaldoc.summaryTable.columns[0].cell8}
                                  </td>
                                  <td>
                                    {evaldoc.summaryTable.columns[0].cell9}
                                  </td>
                                </tr>
                                <tr className="text-center">
                                  <td>{evaldoc.summaryTable.answer.cell4}</td>
                                  <td>{evaldoc.summaryTable.answer.cell5}</td>
                                  <td>{evaldoc.summaryTable.answer.cell6}</td>
                                  <td>{evaldoc.summaryTable.answer.cell7}</td>
                                  <td>{evaldoc.summaryTable.answer.cell8}</td>
                                  <td>{evaldoc.summaryTable.answer.cell9}</td>
                                </tr>
                              </tbody>
                            </table>
                          )}
                      </div>
                    )
                  }
                  <hr />
                  {
                    //interview
                    evaldoc.interview && (
                      <div className="row px-2">
                        {evaldoc.interview.map((int, i: number) => (
                          <div className="div">
                            <h6>{int.name}</h6>
                            <p className="small">{int.description}</p>
                            <p>{int.answer}</p>
                          </div>
                        ))}
                      </div>
                    )
                  }
                  <hr />
                  {evaldoc.feedbackAndObesrvations && (
                    <div className="row px-2">
                      <h6>Feedback and Observations</h6>
                      {evaldoc.feedbackAndObesrvations.map((f, i) => (
                        <div key={i}>
                          <p>
                            <strong>{f.name}</strong>
                          </p>

                          <p>{f.answer}</p>
                        </div>
                      ))}
                    </div>
                  )}
                </div>
                </Accordion.Body>
              )}
            </Accordion>
          </div>
        </div>
      )}
    </div>
  );
};

export default AnnualEvaluationMasterDisplayComponent;
