import React from 'react'
import { IRatingDistribution } from '../../../../interface'

const RatingDistribution = ({data}:{data:IRatingDistribution}) => {
  return (
    <table className="table table-bordered text-center small">
                  <tbody>
                  <tr>
                    <th>A</th>
                    <th>B</th>
                    <th>C</th>
                    <th>D</th>
                    <th>E</th>
                    <th>F</th>
                  </tr>

                 <tr>
                    <td>{data.a.from} - {data.a.to}</td>
                    <td>{data.b.from} - {data.b.to}</td>
                    <td>{data.c.from} - {data.c.to}</td>
                    <td>{data.d.from} - {data.d.to}</td>
                    <td>{data.e.from} - {data.e.to}</td>
                    <td>{data.f.from} - {data.f.to}</td>
                 </tr>
                  <tr>
                    <td>6</td>
                    <td>5</td>
                    <td>4</td>
                    <td>3</td>
                    <td>2</td>
                    <td>1</td>
                  </tr>
                  </tbody>
                </table>
  )
}

export default RatingDistribution