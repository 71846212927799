import { useEffect, useState } from "react";
import CountryFilter from "../../../components/CountryFilter";
import {
  ICountry,
  IEvaluationPeriod,
  IPerformanceMetricComments,
  UserInterface,
  IPerformanceMetricCompetencies,
  IPerformanceMetricInput,
  IPerformanceMetricOutput,
  AppraisalDocument,
  IRatingDistribution,
} from "../../../interface";
import { fetchAPI } from "../../../utils/FetchApi";
import { FetchMethods } from "../../../interface/Enums/methods.enum";
import { useDispatch, useSelector } from "react-redux";
import { setError } from "../../../store/reducers/errorReducer";
import { Accordion, Badge } from "react-bootstrap";
import {
  BsArrowBarLeft,
  BsArrowBarRight,
  BsArrowUp,
  BsArrowDown,
  BsAward,
  BsChatDots,
  BsPlusCircleFill,
  BsPuzzleFill,
  BsTrash,
} from "react-icons/bs";
import AppriasalDocumentComponent from "./CreateAppraisalComponents/AppriasalDocument.component";
import AdminContainer from "../../../admin-components/AdminContainer";

const EvaluationComposer = () => {
  const RatingDistributionExample: IRatingDistribution = {
    a: { from: 100, to: 96, letter: "A" },
    b: { from: 95, to: 90, letter: "B" },
    c: { from: 89, to: 80, letter: "C" },
    d: { from: 79, to: 60, letter: "D" },
    e: { from: 59, to: 17, letter: "E" },
    f: { from: 16, to: 1, letter: "F" },
  };
  const [ratingDistribution, setRatingDistribution] =
    useState<IRatingDistribution>(RatingDistributionExample);

  const [reloadAllData, setReloadAllData] = useState<boolean>(false);

  const dispatch = useDispatch();
  const data: UserInterface = useSelector((data: any) => {
    return data.auth.user;
  });

  const [currentUser, setCurrentUser] = useState<string>(
    data.name + " " + data.surname
  );

  useEffect(() => {
    setCurrentUser(data.name + " " + data.surname);
  }, [data]);

  /** EFFECT TRIGGERS */
  /**
   * COUNTRY RELATED
   */
  const [selectedCountry, setSelectedCountry] = useState<string>("");
  const pickCountry = (country: string) => {
    setSelectedCountry(country);
  };

  /**
   * =============================================
   */

  /**
   * Get all Input metrics and make them selectable
   */
  const [inputs, setInputs] = useState<IPerformanceMetricInput[]>([]);
  const [selectedInputs, setSelectedInputs] = useState<
    IPerformanceMetricInput[]
  >([]); //!!!!! FINAL VALUES!

  const [inputSectionname, setInputSectionname] = useState<string>("");
  const [inputSectionExplanation, setInputSectionExplanation] =
    useState<string>("");

  const [sumInputs, setSumInputs] = useState<number>(0);
  const inputPicker = (i: number, method: "add" | "remove") => {
    if (method === "add") {
      setSelectedInputs([...selectedInputs, inputs[i]]);
      const filterOriginalInput: IPerformanceMetricInput[] = inputs.filter(
        (input: IPerformanceMetricInput) => {
          return input._id !== inputs[i]._id;
        }
      );
      setInputs(filterOriginalInput);
    } else if (method === "remove") {
      setInputs([...inputs, selectedInputs[i]]);
      const filterOriginalInput: IPerformanceMetricInput[] =
        selectedInputs.filter((input: IPerformanceMetricInput) => {
          return input._id !== selectedInputs[i]._id;
        });
      setSelectedInputs(filterOriginalInput);
    } else {
      //
    }
  };

  useEffect(() => {
    let sum: number = 0;
    for (let i = 0; i < selectedInputs.length; i++) {
      sum += selectedInputs[i].importance;
    }
    setSumInputs(sum);
  }, [selectedInputs]);

  useEffect(() => {
    if (selectedCountry.length > 0) {
      (async () => {
        try {
          const getAllInputs: IPerformanceMetricInput[] = await fetchAPI(
            `/admincmds/get-all-performance-input-metrics/${selectedCountry}`,
            FetchMethods.GET
          );
          if (getAllInputs.hasOwnProperty("error")) {
            // throw new Error(JSON.stringify(getAllInputs));
          } else {
            // inputs can not contain selectedInputs
            const filterOriginalInput: IPerformanceMetricInput[] = getAllInputs.filter(
              (input: IPerformanceMetricInput) => {
                for (let i = 0; i < selectedInputs.length; i++) {
                  if (input._id === selectedInputs[i]._id) {
                    return false;
                  }
                }
                return true;
              }
            );

            setInputs(filterOriginalInput);
          }
        } catch (error: any) {
          dispatch(setError(error.message));
        }
      })();
    }
    //do nothing yet
  }, [selectedCountry, dispatch, selectedInputs]);
  /**
   * ==============================================
   */

  /**
   * Get all Ouput metrics and make them selectable
   */
  const [outputs, setOutputs] = useState<IPerformanceMetricInput[]>([]);
  const [selectedOutputs, setSelectedOutputs] = useState<
    IPerformanceMetricInput[]
  >([]); //!!!!! FINAL VALUES!
  const [outputSectionname, setOutputSectionname] = useState<string>("");
  const [outputSectionExplanation, setOutputSectionExplanation] =
    useState<string>("");
  const [sumOutputs, setSumOutputs] = useState<number>(0);
  const outputPicker = (i: number, method: "add" | "remove") => {
    if (method === "add") {
      setSelectedOutputs([...selectedOutputs, outputs[i]]);
      const filterOriginalOutput: IPerformanceMetricOutput[] = outputs.filter(
        (output: IPerformanceMetricOutput) => {
          return output._id !== outputs[i]._id;
        }
      );
      setOutputs(filterOriginalOutput);
    } else if (method === "remove") {
      setOutputs([...outputs, selectedOutputs[i]]);
      const filterOriginalOutput: IPerformanceMetricOutput[] =
        selectedOutputs.filter((output: IPerformanceMetricOutput) => {
          return output._id !== selectedOutputs[i]._id;
        });
      setSelectedOutputs(filterOriginalOutput);
    } else {
      //
    }
  };

  useEffect(() => {
    let sum: number = 0;
    for (let i = 0; i < selectedOutputs.length; i++) {
      sum += selectedOutputs[i].importance;
    }
    setSumOutputs(sum);
  }, [selectedOutputs]);

  useEffect(() => {
    if (selectedCountry.length > 0) {
      (async () => {
        try {
          const getAllOutputs: IPerformanceMetricOutput[] = await fetchAPI(
            `/admincmds/get-all-performance-output-metrics/${selectedCountry}`,
            FetchMethods.GET
          );
          if (getAllOutputs.hasOwnProperty("error")) {
            //  throw new Error(JSON.stringify(getAllOutputs));
          } else {
              // outputs can not contain selectedOutputs
            const filterOriginalOutput: IPerformanceMetricOutput[] = getAllOutputs.filter(
              (output: IPerformanceMetricOutput) => {
                for (let i = 0; i < selectedOutputs.length; i++) {
                  if (output._id === selectedOutputs[i]._id) {
                    return false;
                  }
                }
                return true;
              }
            );

            setOutputs(filterOriginalOutput);
          }
        } catch (error: any) {
          dispatch(setError(error.message));
        }
      })();
    }

    //do nothing yet
  }, [selectedCountry, dispatch, selectedOutputs]);
  /**
   * ==============================================
   */

  const [countries, setCountries] = useState<ICountry[]>([]);
  const [selectedCountryForAppraisal, setSelectedCountryForAppraisal] =
    useState<string>(selectedCountry);
  const [evaluationPeriods, setEvaluationPeriods] = useState<
    IEvaluationPeriod[]
  >([]);
  const [selectedEvaluationPeriod, setSelectedEvaluationPeriod] =
    useState<string | null>("");

  

  /**
   * Get all Competencies metrics and make them selectable
   */
  const [competencies, setCompetencies] = useState<
    IPerformanceMetricCompetencies[]
  >([]);
  const [selectedCompetencies, setSelectedCompetencies] = useState<
    IPerformanceMetricCompetencies[]
  >([]); //!!!!! FINAL VALUES!
  const [competenciesSectionname, setCompetenciesSectionname] =
    useState<string>("");
  const [competenciesSectionExplanation, setCompetenciesSectionExplanation] =
    useState<string>("");
  const competenciesPicker = (i: number, method: "add" | "remove") => {
    if (method === "add") {
      setSelectedCompetencies([...selectedCompetencies, competencies[i]]);
      const filterOriginalCompetencies: IPerformanceMetricCompetencies[] =
        competencies.filter((competency: IPerformanceMetricCompetencies) => {
          return competency._id !== competencies[i]._id;
        });
      setCompetencies(filterOriginalCompetencies);
    } else if (method === "remove") {
      setCompetencies([...competencies, selectedCompetencies[i]]);
      const filterOriginalCompetencies: IPerformanceMetricCompetencies[] =
        selectedCompetencies.filter(
          (competency: IPerformanceMetricCompetencies) => {
            return competency._id !== selectedCompetencies[i]._id;
          }
        );
      setSelectedCompetencies(filterOriginalCompetencies);
    } else {
      //
    }
  };

  useEffect(() => {
    if (selectedCountry.length > 0) {
      (async () => {
        try {
          const getAllCompetencies: IPerformanceMetricCompetencies[] =
            await fetchAPI(
              `/admincmds/get-all-performance-competencies-metrics/${selectedCountry}`,
              FetchMethods.GET
            );
          if (getAllCompetencies.hasOwnProperty("error")) {
            throw new Error(JSON.stringify(getAllCompetencies));
          }
          
          // competencies can not contain selectedCompetencies
          const filterOriginalCompetencies: IPerformanceMetricCompetencies[] =

            getAllCompetencies.filter(
              (competency: IPerformanceMetricCompetencies) => {
                for (let i = 0; i < selectedCompetencies.length; i++) {
                  if (competency._id === selectedCompetencies[i]._id) {
                    return false;
                  }
                }
                return true;
              }
            );
            setCompetencies(filterOriginalCompetencies);
        } catch (error: any) {
          dispatch(setError(error.message));
        }
      })();
    }

    //do nothing yet
  }, [selectedCountry, dispatch, selectedCompetencies]);
  /**
   * ==============================================
   */

  /**
   * Get all comments metrics and make them selectable
   */
  const [comments, setComments] = useState<IPerformanceMetricComments[]>([]);
  const [selectedComments, setSelectedComments] = useState<
    IPerformanceMetricCompetencies[]
  >([]); //!!!!! FINAL VALUES!
  const [commentsSectionname, setCommentsSectionname] = useState<string>("");
  const [commentsSectionExplanation, setCommentsSectionExplanation] =
    useState<string>("");

  const commentsPicker = (i: number, method: "add" | "remove") => {
    if (method === "add") {
      setSelectedComments([...selectedComments, comments[i]]);
      const filterOriginalComments: IPerformanceMetricComments[] =
        comments.filter((comment: IPerformanceMetricComments) => {
          return comment._id !== comments[i]._id;
        });
      setComments(filterOriginalComments);
    } else if (method === "remove") {
      setComments([...comments, selectedComments[i]]);
      const filterOriginalComments: IPerformanceMetricComments[] =
        selectedComments.filter((comment: IPerformanceMetricComments) => {
          return comment._id !== selectedComments[i]._id;
        });
      setSelectedComments(filterOriginalComments);
    } else {
      //
    }
  };

  useEffect(() => {
    if (selectedCountry.length > 0) {
      (async () => {
        try {
          const getAllComments: IPerformanceMetricComments[] = await fetchAPI(
            `/admincmds/get-all-performance-comments-metrics/${selectedCountry}`,
            FetchMethods.GET
          );
          if (getAllComments.hasOwnProperty("error")) {
            throw new Error(JSON.stringify(getAllComments));
          }
         // comments can not contain selectedComments
          const filterOriginalComments: IPerformanceMetricComments[] =

            getAllComments.filter((comment: IPerformanceMetricComments) => {
              for (let i = 0; i < selectedComments.length; i++) {
                if (comment._id === selectedComments[i]._id) {
                  return false;
                }
              }
              return true;
            }
          );
          setComments(filterOriginalComments);
        } catch (error: any) {
          dispatch(setError(error.message));
        }
      })();
    }
    //do nothing yet
  }, [selectedCountry, dispatch, selectedComments]);



  /**
   * ==============================================
   */

  /**
   * FINAL APPRAISAL CREATION
   */

  const createAppraisal = async () => {
    console.log(typeof selectedEvaluationPeriod === "string")
    const AppraisalFinalDocument: AppraisalDocument = {
      country: selectedCountryForAppraisal
        ? selectedCountryForAppraisal
        : selectedCountry,
      inputs: selectedInputs,
      inputName: inputSectionname,
      inputDescription: inputSectionExplanation,
      outputs: selectedOutputs,
      outputName: outputSectionname,
      outputDescription: outputSectionExplanation,
      comments: selectedComments,
      commentsName: commentsSectionname,
      commentsDescription: commentsSectionExplanation,
      competencies: selectedCompetencies,
      competenciesName: competenciesSectionname,
      competenciesDescription: competenciesSectionExplanation,
      evaluationPeriod: (typeof selectedEvaluationPeriod === "string") ? selectedEvaluationPeriod : "",
      creator: currentUser,
      ratingDistribution: ratingDistribution,
    };

    /**
     * ========== VALIDATION COMES HERE ==========
     */
    try {
      const {
        country,
        inputName,
        inputDescription,
        outputName,
        outputDescription,
        commentsDescription,
        commentsName,
        competenciesDescription,
        competenciesName,
        evaluationPeriod,
      } = AppraisalFinalDocument;

      if (country.length < 1) throw new Error("Country must be set!");

      if (sumInputs !== 100) throw new Error("Inputs must be equal to 100%");

      if (selectedInputs.length < 1) {
        throw new Error("No inputs were selected");
      }

      if (!inputName) throw new Error("Input name must not be empty");

      if (!inputDescription)
        throw new Error("Input description must not be empty!");

      if (sumOutputs !== 100) {
        throw new Error("Outputs must be equal to 100%");
      }

      if (selectedOutputs.length < 1)
        throw new Error("No outputs were selected");

      if (!outputName) throw new Error("output name must not be empty");

      if (!outputDescription)
        throw new Error("output description must not be empty!");

      if (selectedComments.length < 1)
        throw new Error("No comments were selected");

      if (!commentsName) throw new Error("coment name must not be empty");

      if (!commentsDescription)
        throw new Error("coment description must not be empty!");

      if (selectedCompetencies.length < 1)
        throw new Error("No competencies were selected");

      if (!competenciesName) throw new Error("coment name must not be empty");

      if (!competenciesDescription)
        throw new Error("coment description must not be empty!");

      if (!evaluationPeriod)
        throw new Error("You must select valid evaluation period!");

      if (ratingDistribution.a.from <= ratingDistribution.a.to)
        throw new Error("A FROM rating can not be less than equal A TO rating");

      if (ratingDistribution.b.from <= ratingDistribution.b.to)
        throw new Error("B FROM rating can not be less than equal B TO rating");

      if (ratingDistribution.c.from <= ratingDistribution.c.to)
        throw new Error("C FROM rating can not be less than equal C TO rating");

      if (ratingDistribution.d.from <= ratingDistribution.d.to)
        throw new Error("D FROM rating can not be less than equal D TO rating");

      if (ratingDistribution.e.from <= ratingDistribution.e.to)
        throw new Error("E FROM rating can not be less than equal E TO rating");

      if (ratingDistribution.f.from <= ratingDistribution.f.to)
        throw new Error("F FROM rating can not be less than equal F TO rating");

      // Here will be the document creation
      const data: AppraisalDocument = await fetchAPI(
        "/admincmds/create-appraisal-document",
        FetchMethods.POST,
        AppraisalFinalDocument
      );

      if (data.hasOwnProperty("error")) {
        throw new Error(JSON.stringify(data));
      }
      setReloadAllData(!reloadAllData);
      resetStatesToDefault();
      dispatch(setError("Appraisal document created successfully"));

      //in two sconds, reload the page

      setTimeout(() => {
        window.location.reload();
      }, 2000);
    } catch (error: any) {
      dispatch(setError(error.message));
    }
  };

  const [allAppraisalDocuments, setAllAppraisalDocuments] =
    useState<AppraisalDocument[]>();
  //get all created  appraisal documents
  useEffect(() => {
    if (selectedCountry.length > 0) {
      (async () => {
        try {
          const getDocs: AppraisalDocument[] = await fetchAPI(
            `/admincmds/get-all-appraisal-documents/${selectedCountry}`,
            FetchMethods.GET
          );
          if (getDocs.hasOwnProperty("error")) {
            throw new Error(JSON.stringify(getDocs));
          }
          setAllAppraisalDocuments(getDocs);
        } catch (error: any) {
          dispatch(setError(error.message));
        }
      })();
    }
  }, [selectedCountry, reloadAllData, dispatch]);

  useEffect(() => {
    (async () => {
      try {
        const countries: ICountry[] = await fetchAPI(
          "/admincmds/get-all-countries",
          FetchMethods.GET
        );
        countries.sort((a, b) => (a.name > b.name ? 1 : -1));
        setCountries(countries);
        if (selectedCountry.length > 0) {
          const evalPeriods: IEvaluationPeriod[] = await fetchAPI(
            `/admincmds/get-all-evaluation-periods/${selectedCountry}`,
            FetchMethods.GET
          );

          if (evalPeriods.hasOwnProperty("error")) {
            setEvaluationPeriods([]);
          } else {
            //Filter out the ones that are already used in the allAppraisalDocuments
            const usedEvalPeriods: IEvaluationPeriod[] = [];
            allAppraisalDocuments?.forEach((doc:AppraisalDocument) => {
              const usedEvalPeriod: IEvaluationPeriod | undefined = evalPeriods.find(
                (evalPeriod: IEvaluationPeriod) =>
                  evalPeriod.name === doc.evaluationPeriod
              );
              if (usedEvalPeriod) {
                usedEvalPeriods.push(usedEvalPeriod);
              }
            });
            evalPeriods.forEach((evalPeriod: IEvaluationPeriod) => {
              const usedEvalPeriod: IEvaluationPeriod | undefined = usedEvalPeriods.find(
                (usedEvalPeriod: IEvaluationPeriod) =>
                  usedEvalPeriod.name === evalPeriod.name
              );
              if (usedEvalPeriod) {
                evalPeriods.splice(evalPeriods.indexOf(evalPeriod), 1);
              }
            });


            setEvaluationPeriods(evalPeriods);
          }
        }
      } catch (error: any) {
        dispatch(setError(error.message.toString()));
      }
    })();
  }, [selectedCountry, dispatch, allAppraisalDocuments, reloadAllData]);

  const resetStatesToDefault = () => {
    setSelectedComments([]);
    setSelectedCompetencies([]);
    setSelectedCountryForAppraisal("---");
    setSelectedEvaluationPeriod("---");
    setSelectedInputs([]);
    setSelectedOutputs([]);

    setCommentsSectionExplanation("");
    setCommentsSectionname("");

    setCompetenciesSectionExplanation("");
    setCompetenciesSectionname("");

    setInputSectionExplanation("");
    setInputSectionname("");

    setOutputSectionExplanation("");
    setOutputSectionname("");

    setSelectedEvaluationPeriod("");
  };

  const prefillData = async (id: string) => {
    try {
      const prefilledAppraisal: AppraisalDocument = await fetchAPI(
        `/admincmds/get-appraisal-document/${id}`,
        FetchMethods.GET
      );

      if (prefilledAppraisal.hasOwnProperty("error"))
        throw new Error(
          "This appraisal is missing in our database... Weird..."
        );

      /**
       * A ZDE BUDE PREFILL LOGIKA - POSERU SE!
       */

      //competencies
      setCompetenciesSectionExplanation(
        prefilledAppraisal.competenciesDescription
      );
      setCompetenciesSectionname(prefilledAppraisal.competenciesName);
      // setCompetencies([])
      setSelectedCompetencies(prefilledAppraisal.competencies);
      //comments
      setCommentsSectionExplanation(prefilledAppraisal.commentsDescription);
      setCommentsSectionname(prefilledAppraisal.commentsName);
      setSelectedComments(prefilledAppraisal.comments);
      //Evaluation Summaries
      //outputs
      setOutputSectionExplanation(prefilledAppraisal.outputDescription);
      setOutputSectionname(prefilledAppraisal.outputName);
      setSelectedOutputs(prefilledAppraisal.outputs);
      //inputs
      setInputSectionExplanation(prefilledAppraisal.inputDescription);
      setInputSectionname(prefilledAppraisal.inputName);
      setSelectedInputs(prefilledAppraisal.inputs);
    } catch (error: any) {
      dispatch(setError(error.message));
    }
  };

  const deleteAppraisalDocument = async (id: string) => {
    //confirm the deletion
    if (
      !window.confirm(
        "Are you sure you want to delete this appraisal document?"
      )
    )
      return;
    try {
      const deleteDoc: AppraisalDocument = await fetchAPI(
        `/admincmds/delete-appraisal-document/${id}`,
        FetchMethods.DELETE
      );

      if (deleteDoc.hasOwnProperty("error"))
        throw new Error("The document could not be deleted");

      setReloadAllData(!reloadAllData);
    } catch (error: any) {
      dispatch(setError(error.message));
    }
  };
  const [showCreateComponent, setShowCreateComponent] =
    useState<boolean>(false);

    // Moves selected inputs, outputs, competencies or comments to the left or right in the array based on the string command up/down. up = right, down = left
    const moveSelectedArray = (i: number, command: "up" | "down", what: "inputs" | "outputs" | "competencies" | "comments") => {
   //if selected input/output/competency/comment is the last in the array - do not move it outside the array's range

      if (what === "inputs") {
        if (command === "up" && i > 0) {
          const temp = selectedInputs[i];
          const temp2 = selectedInputs[i - 1];
          selectedInputs[i] = temp2;
          selectedInputs[i - 1] = temp;
          setSelectedInputs([...selectedInputs]);
        } else if (command === "down" && i < selectedInputs.length - 1) {
          const temp = selectedInputs[i];
          const temp2 = selectedInputs[i + 1];
          selectedInputs[i] = temp2;
          selectedInputs[i + 1] = temp;
          setSelectedInputs([...selectedInputs]);
        } 
     
      }else if (what === "outputs") {

        if (command === "up" && i > 0) {
          const temp = selectedOutputs[i];
          const temp2 = selectedOutputs[i - 1];
          selectedOutputs[i] = temp2;
          selectedOutputs[i - 1] = temp;
          setSelectedOutputs([...selectedOutputs]);
        } else if (command === "down" && i < selectedOutputs.length - 1) {
          const temp = selectedOutputs[i];
          const temp2 = selectedOutputs[i + 1];
          selectedOutputs[i] = temp2;
          selectedOutputs[i + 1] = temp;
          setSelectedOutputs([...selectedOutputs]);
        }
      }
      else if (what === "competencies") {
        if (command === "up" && i > 0) {
          const temp = selectedCompetencies[i];
          const temp2 = selectedCompetencies[i - 1];
          selectedCompetencies[i] = temp2;
          selectedCompetencies[i - 1] = temp;
          setSelectedCompetencies([...selectedCompetencies]);
        } else if (command === "down" && i < selectedCompetencies.length - 1) {
          const temp = selectedCompetencies[i];
          const temp2 = selectedCompetencies[i + 1];
          selectedCompetencies[i] = temp2;
          selectedCompetencies[i + 1] = temp;
          setSelectedCompetencies([...selectedCompetencies]);
        }
      } else if (what === "comments"){
        if (command === "up" && i > 0) {
          const temp = selectedComments[i];
          const temp2 = selectedComments[i - 1];
          selectedComments[i] = temp2;
          selectedComments[i - 1] = temp;
          setSelectedComments([...selectedComments]);
        } else if (command === "down" && i < selectedComments.length - 1) {
          const temp = selectedComments[i];
          const temp2 = selectedComments[i + 1];
          selectedComments[i] = temp2;
          selectedComments[i + 1] = temp;
          setSelectedComments([...selectedComments]);
        }
      }else{
        return;
      }
       
        
      };
  return (
    <AdminContainer>

      <div className="row m-4">
            <CountryFilter returnedCountry={pickCountry} />
          </div>

      <div className="row">
        <div className="col-10"><Accordion>
          <Accordion.Header className="shadow p-2 m-2">
            <h6 className="h6">List of all appraisals for {selectedCountry}</h6>
          </Accordion.Header>
          <Accordion.Body>
            <AppriasalDocumentComponent
              deldocs={deleteAppraisalDocument}
              docs={allAppraisalDocuments}
            />
          </Accordion.Body>
        </Accordion></div>
        <div className="col-2">
        <div className="btn btn-danger" onClick={()=>{
          setShowCreateComponent(!showCreateComponent)
        }}>
          Create new Appraisal
        </div>
        </div>
        
        
      </div>

      {showCreateComponent && (
        <>
        

          <Accordion>
            <Accordion.Header className="shadow rounded p-2 m-2">
              <h6 className="h6">Create new Appraisal Document</h6>
            </Accordion.Header>
            <Accordion.Body>
              <div className="row m-2 p-2">
                <div className="col-lg-10">
                  <select
                    onChange={({ target }) => {
                      if (target.value.length > 3) {
                        prefillData(target.value);
                      }
                      if(target.value === "-1"){
                        resetStatesToDefault();
                      }
                    }}
                    className="form-control text-left"
                  >
                    <option className="form-control" value={-1}>
                      ---
                    </option>
                    {allAppraisalDocuments?.map((a, i) => (
                      <option className="form-control" key={i} value={a._id}>
                        {a.evaluationPeriod} - {a.country}
                      </option>
                    ))}
                  </select>
                </div>
                <div className="col-lg-2">
                  <div className="btn btn-primary">Import previous</div>
                </div>
              </div>
              <hr className="hr" />
              {
                // Choose Inputs
              }
              <Accordion>
                <Accordion.Header
                  className={`shadow rounded p-2 m-2 ${
                    sumInputs < 100 ? "bg-warning" : "bg-success"
                  }`}
                >
                  <div className="row">
                    <div className="col-lg-10">
                      <h6 className="h6">
                        <BsArrowBarRight /> 1. Select your inputs
                      </h6>
                    </div>
                    <div className="col-lg-2">
                      <Badge bg={`${sumInputs < 100 ? "danger" : "success"}`}>
                        {sumInputs}%
                      </Badge>
                    </div>
                  </div>
                </Accordion.Header>
                <Accordion.Body>
                  <div className="row p-2 m-2">
                    <div className="h3 m-2 p-2">
                      Give a global name to the input section
                    </div>
                    <input
                      type="text"
                      className="form-control"
                      value={inputSectionname}
                      onChange={({ target }) => {
                        setInputSectionname(target.value);
                      }}
                    />
                    <div className="h3 m-2 p-2">
                      Give a description to the input section
                    </div>
                    <input
                      type="text"
                      className="form-control"
                      value={inputSectionExplanation}
                      onChange={({ target }) => {
                        setInputSectionExplanation(target.value);
                      }}
                    />
                  </div>
                  <div className="row p-2 m-2">
                    <div className="col-lg-6">
                      <div className="row">
                        <h3 className="h3">Choose Inputs</h3>
                        {inputs.map(
                          (input: IPerformanceMetricInput, index: number) => (
                            <div
                              key={index}
                              className="col-lg-12 shadow rounded m-2 p-2"
                            >
                              <div className="row justify-content-center align-items-center">
                                <div className="col-lg-2">
                                  <div
                                    className="btn btn-primary"
                                    onClick={() => {
                                      inputPicker(index, "add");
                                    }}
                                  >
                                    <BsPlusCircleFill size={16} /> Add
                                  </div>
                                </div>
                                <div className="col-lg-10">
                                  <Accordion>
                                    <Accordion.Item eventKey={index.toString()}>
                                      <Accordion.Header>
                                        <div className="row">
                                          <div className="col-lg-12">
                                            <h5 className="h5">
                                              {input.name}{" "}
                                            </h5>
                                            <p
                                              className={`small ${
                                                input.type === "standard"
                                                  ? "text-info"
                                                  : "text-warning"
                                              }`}
                                            >
                                              <em>{input.type}</em>
                                            </p>
                                          </div>
                                        </div>
                                      </Accordion.Header>

                                      <Accordion.Body key={index}>
                                        <strong>Explanation:</strong>
                                        <p>{input.explanation}</p>
                                        <br />
                                        <Badge bg="secondary">
                                          Importance: {input.importance}%
                                        </Badge>
                                        <br />
                                        <Badge bg="secondary">
                                          By: {input.creator}
                                        </Badge>
                                        <br />
                                        <Badge bg="secondary">
                                          Country scope: {input.country}
                                        </Badge>
                                        <br />
                                        <Badge bg="secondary">
                                          Category: {input.category}
                                        </Badge>
                                        <Badge bg="secondary">
                                          Type: {input.type}
                                        </Badge>
                                      </Accordion.Body>
                                    </Accordion.Item>
                                  </Accordion>
                                </div>
                              </div>
                            </div>
                          )
                        )}
                      </div>
                    </div>
                    <div className="col-lg-6">
                      <h3 className="H3">Selected Inputs</h3>
                      {selectedInputs.map(
                        (input: IPerformanceMetricInput, index: number) => (
                          <div
                            key={index}
                            className="col-lg-12 shadow rounded m-2 p-2"
                          >
                            <div className="row justify-content-center align-items-center">
                              <div className="col-lg-4">
                                <div className="flex flex-row justify-center align-middle" style={{display:"flex"}}>
                                <div className="btn btn-warning" onClick={()=>{
                                  moveSelectedArray(index, "up", "inputs")
                                }}>
                                  <BsArrowUp />
                                </div>
                                <div className="btn btn-warning mx-1" onClick={()=>{
                                   moveSelectedArray(index, "down", "inputs")
                                }}>
                                <BsArrowDown /> 
                                </div>
                                <div
                                  className="btn btn-danger"
                                  onClick={() => {
                                    inputPicker(index, "remove");
                                  }}
                                >
                                   <BsTrash size={16}/>
                                </div>
                        
                               
                                </div>
                                
                              </div>
                              <div className="col-lg-8">
                                <Accordion>
                                  <Accordion.Item eventKey={index.toString()}>
                                    <Accordion.Header>
                                      <div className="row">
                                        <div className="col-lg-10">
                                          <h5 className="h5">{input.name}</h5>
                                        </div>
                                        <div className="col-lg-2">
                                          <Badge>{input.importance}%</Badge>
                                        </div>
                                      </div>
                                    </Accordion.Header>

                                    <Accordion.Body key={index}>
                                      <strong>Explanation:</strong>
                                      <p>{input.explanation}</p>
                                      <br />
                                      <Badge bg="secondary">
                                        Importance: {input.importance}%
                                      </Badge>
                                      <br />
                                      <Badge bg="secondary">
                                        By: {input.creator}
                                      </Badge>
                                      <br />
                                      <Badge bg="secondary">
                                        Country scope: {input.country}
                                      </Badge>
                                      <br />
                                      <Badge bg="secondary">
                                        Category: {input.category}
                                      </Badge>
                                    </Accordion.Body>
                                  </Accordion.Item>
                                </Accordion>
                              </div>
                            </div>
                          </div>
                        )
                      )}
                    </div>
                  </div>
                </Accordion.Body>
              </Accordion>
              <hr className="hr" />
              {
                //2. Choose Ouputs
              }
              <Accordion>
                <Accordion.Header
                  className={`shadow rounded p-2 m-2 ${
                    sumOutputs < 100 ? "bg-warning" : "bg-success"
                  }`}
                >
                  <div className="row">
                    <div className="col-lg-10">
                      <h6 className="h6">
                        <BsArrowBarLeft /> 2. Select your Outputs
                      </h6>
                    </div>
                    <div className="col-lg-2">
                      <Badge bg={`${sumOutputs < 100 ? "danger" : "success"}`}>
                        {sumOutputs}%
                      </Badge>
                    </div>
                  </div>
                </Accordion.Header>
                <Accordion.Body>
                  <div className="row p-2 m-2">
                    <div className="h3 m-2 p-2">
                      Give a global name to the output section
                    </div>
                    <input
                      type="text"
                      className="form-control"
                      value={outputSectionname}
                      onChange={({ target }) => {
                        setOutputSectionname(target.value);
                      }}
                    />
                    <div className="h3 m-2 p-2">
                      Give a description to the input section
                    </div>
                    <input
                      type="text"
                      className="form-control"
                      value={outputSectionExplanation}
                      onChange={({ target }) => {
                        setOutputSectionExplanation(target.value);
                      }}
                    />
                  </div>
                  <div className="row p-2 m-2">
                    <div className="col-lg-6">
                      <div className="row">
                        <h3 className="h3">Choose Outputs</h3>
                        {outputs.map(
                          (output: IPerformanceMetricOutput, index: number) => (
                            <div
                              key={index}
                              className="col-lg-12 shadow rounded m-2 p-2"
                            >
                              <div className="row justify-content-center align-items-center">
                                <div className="col-lg-2">
                                  <div
                                    className="btn btn-primary"
                                    onClick={() => {
                                      outputPicker(index, "add");
                                    }}
                                  >
                                    <BsPlusCircleFill size={16} /> Add
                                  </div>
                                </div>
                                <div className="col-lg-10">
                                  <Accordion>
                                    <Accordion.Item eventKey={index.toString()}>
                                      <Accordion.Header>
                                        <div className="row">
                                          <div className="col-lg-10">
                                            <h5 className="h5">
                                              {output.name}
                                            </h5>
                                          </div>
                                          <div className="col-lg-2">
                                            <Badge>{output.importance}%</Badge>
                                          </div>
                                        </div>
                                      </Accordion.Header>

                                      <Accordion.Body key={index}>
                                        <strong>Explanation:</strong>
                                        <p>{output.explanation}</p>
                                        <br />
                                        <Badge bg="secondary">
                                          Importance: {output.importance}%
                                        </Badge>
                                        <br />
                                        <Badge bg="secondary">
                                          By: {output.creator}
                                        </Badge>
                                        <br />
                                        <Badge bg="secondary">
                                          Country scope: {output.country}
                                        </Badge>
                                        <br />
                                        <Badge bg="secondary">
                                          Category: {output.category}
                                        </Badge>
                                      </Accordion.Body>
                                    </Accordion.Item>
                                  </Accordion>
                                </div>
                              </div>
                            </div>
                          )
                        )}
                      </div>
                    </div>
                    <div className="col-lg-6">
                      <h3 className="H3">Selected Outputs</h3>
                      {selectedOutputs.map(
                        (output: IPerformanceMetricOutput, index: number) => (
                          <div
                            key={index}
                            className="col-lg-12 shadow rounded m-2 p-2"
                          >
                            <div className="row justify-content-center align-items-center">
                            <div className="col-lg-4">
                                <div className="flex flex-row justify-center align-middle" style={{display:"flex"}}>
                                <div className="btn btn-warning" onClick={()=>{
                                  moveSelectedArray(index, "up", "outputs")
                                }}>
                                  <BsArrowUp />
                                </div>
                                <div className="btn btn-warning mx-1" onClick={()=>{
                                   moveSelectedArray(index, "down", "outputs")
                                }}>
                                <BsArrowDown /> 
                                </div>
                                <div
                                  className="btn btn-danger"
                                  onClick={() => {
                                    outputPicker(index, "remove");
                                  }}
                                >
                                   <BsTrash size={16}/>
                                </div>
                                </div>
                                
                              </div>
                              <div className="col-lg-8">
                                <Accordion>
                                  <Accordion.Item eventKey={index.toString()}>
                                    <Accordion.Header>
                                      <div className="row">
                                        <div className="col-lg-10">
                                          <h5 className="h5">{output.name}</h5>
                                        </div>
                                        <div className="col-lg-2">
                                          <Badge>{output.importance}%</Badge>
                                        </div>
                                      </div>
                                    </Accordion.Header>

                                    <Accordion.Body key={index}>
                                      <strong>Explanation:</strong>
                                      <p>{output.explanation}</p>
                                      <br />
                                      <Badge bg="secondary">
                                        Importance: {output.importance}%
                                      </Badge>
                                      <br />
                                      <Badge bg="secondary">
                                        By: {output.creator}
                                      </Badge>
                                      <br />
                                      <Badge bg="secondary">
                                        Country scope: {output.country}
                                      </Badge>
                                      <br />
                                      <Badge bg="secondary">
                                        Category: {output.category}
                                      </Badge>
                                    </Accordion.Body>
                                  </Accordion.Item>
                                </Accordion>
                              </div>
                            </div>
                          </div>
                        )
                      )}
                    </div>
                  </div>
                </Accordion.Body>
              </Accordion>
              <hr className="hr" />
              {
                //3. Choose Competencies
              }
              <Accordion>
                <Accordion.Header
                  className={`shadow rounded p-2 m-2 ${
                    selectedCompetencies.length > 0
                      ? "bg-success"
                      : "bg-warning"
                  }`}
                >
                  <div className="row">
                    <div className="col-lg-12">
                      <h6 className="h6">
                        <BsPuzzleFill /> 3. Select your Competencies
                      </h6>
                    </div>
                  </div>
                </Accordion.Header>
                <Accordion.Body>
                  <div className="row p-2 m-2">
                    <div className="h3 m-2 p-2">
                      Give a global name to the competency section
                    </div>
                    <input
                      type="text"
                      className="form-control"
                      value={competenciesSectionname}
                      onChange={({ target }) => {
                        setCompetenciesSectionname(target.value);
                      }}
                    />
                    <div className="h3 m-2 p-2">
                      Give a description to the competency section
                    </div>
                    <input
                      type="text"
                      className="form-control"
                      value={competenciesSectionExplanation}
                      onChange={({ target }) => {
                        setCompetenciesSectionExplanation(target.value);
                      }}
                    />
                  </div>
                  <div className="row p-2 m-2">
                    <div className="col-lg-6">
                      <div className="row">
                        <h3 className="h3">Choose Competencies</h3>
                        {competencies.map(
                          (
                            competency: IPerformanceMetricCompetencies,
                            index: number
                          ) => (
                            <div
                              key={index}
                              className="col-lg-12 shadow rounded m-2 p-2"
                            >
                              <div className="row justify-content-center align-items-center">
                                <div className="col-lg-2">
                                  <div
                                    className="btn btn-primary"
                                    onClick={() => {
                                      competenciesPicker(index, "add");
                                    }}
                                  >
                                    <BsPlusCircleFill size={16} /> Add
                                  </div>
                                </div>
                                <div className="col-lg-10">
                                  <Accordion>
                                    <Accordion.Item eventKey={index.toString()}>
                                      <Accordion.Header>
                                        <div className="row">
                                          <div className="col-lg-10">
                                            <h5 className="h5">
                                              {competency.name}
                                            </h5>
                                          </div>
                                        </div>
                                      </Accordion.Header>

                                      <Accordion.Body key={index}>
                                        <strong>Explanation:</strong>
                                        <p>{competency.explanation}</p>
                                        <br />
                                        <Badge bg="secondary">
                                          By: {competency.creator}
                                        </Badge>
                                        <br />
                                        <Badge bg="secondary">
                                          Country scope: {competency.country}
                                        </Badge>
                                        <br />
                                        <Badge bg="secondary">
                                          Category: {competency.category}
                                        </Badge>
                                      </Accordion.Body>
                                    </Accordion.Item>
                                  </Accordion>
                                </div>
                              </div>
                            </div>
                          )
                        )}
                      </div>
                    </div>
                    <div className="col-lg-6">
                      <h3 className="H3">Selected Competencies</h3>
                      {selectedCompetencies.map(
                        (
                          competency: IPerformanceMetricCompetencies,
                          index: number
                        ) => (
                          <div
                            key={index}
                            className="col-lg-12 shadow rounded m-2 p-2"
                          >
                            <div className="row justify-content-center align-items-center">
                            <div className="col-lg-4">
                                <div className="flex flex-row justify-center align-middle" style={{display:"flex"}}>
                                <div className="btn btn-warning" onClick={()=>{
                                  moveSelectedArray(index, "up", "competencies")
                                }}>
                                  <BsArrowUp />
                                </div>
                                <div className="btn btn-warning mx-1" onClick={()=>{
                                   moveSelectedArray(index, "down", "competencies")
                                }}>
                                <BsArrowDown /> 
                                </div>
                                <div
                                  className="btn btn-danger"
                                  onClick={() => {
                                    competenciesPicker(index, "remove");
                                  }}
                                >
                                   <BsTrash size={16}/>
                                </div>
                                </div>
                                
                              </div>
                              <div className="col-lg-8">
                                <Accordion>
                                  <Accordion.Item eventKey={index.toString()}>
                                    <Accordion.Header>
                                      <div className="row">
                                        <div className="col-lg-10">
                                          <h5 className="h5">
                                            {competency.name}
                                          </h5>
                                        </div>
                                      </div>
                                    </Accordion.Header>

                                    <Accordion.Body key={index}>
                                      <strong>Explanation:</strong>
                                      <p>{competency.explanation}</p>

                                      <br />
                                      <Badge bg="secondary">
                                        By: {competency.creator}
                                      </Badge>
                                      <br />
                                      <Badge bg="secondary">
                                        Country scope: {competency.country}
                                      </Badge>
                                      <br />
                                      <Badge bg="secondary">
                                        Category: {competency.category}
                                      </Badge>
                                    </Accordion.Body>
                                  </Accordion.Item>
                                </Accordion>
                              </div>
                            </div>
                          </div>
                        )
                      )}
                    </div>
                  </div>
                </Accordion.Body>
              </Accordion>
              <hr className="hr" />
              {
                //4. Choose Comments
              }
              <Accordion>
                <Accordion.Header
                  className={`shadow rounded p-2 m-2 ${
                    selectedComments.length > 0 ? "bg-success" : "bg-warning"
                  }`}
                >
                  <div className="row">
                    <div className="col-lg-12">
                      <h6 className="h6">
                        <BsChatDots /> 4. Select your Comments
                      </h6>
                    </div>
                  </div>
                </Accordion.Header>
                <Accordion.Body>
                  <div className="row p-2 m-2">
                    <div className="h3 m-2 p-2">
                      Give a global name to the comment section
                    </div>
                    <input
                      type="text"
                      className="form-control"
                      value={commentsSectionname}
                      onChange={({ target }) => {
                        setCommentsSectionname(target.value);
                      }}
                    />
                    <div className="h3 m-2 p-2">
                      Give a description to the comment section
                    </div>
                    <input
                      type="text"
                      className="form-control"
                      value={commentsSectionExplanation}
                      onChange={({ target }) => {
                        setCommentsSectionExplanation(target.value);
                      }}
                    />
                  </div>
                  <div className="row p-2 m-2">
                    <div className="col-lg-6">
                      <div className="row">
                        <h3 className="h3">Choose Comments</h3>
                        {comments.map(
                          (
                            comment: IPerformanceMetricComments,
                            index: number
                          ) => (
                            <div
                              key={index}
                              className="col-lg-12 shadow rounded m-2 p-2"
                            >
                              <div className="row justify-content-center align-items-center">
                                <div className="col-lg-2">
                                  <div
                                    className="btn btn-primary"
                                    onClick={() => {
                                      commentsPicker(index, "add");
                                    }}
                                  >
                                    <BsPlusCircleFill size={16} /> Add
                                  </div>
                                </div>
                                <div className="col-lg-10">
                                  <Accordion>
                                    <Accordion.Item eventKey={index.toString()}>
                                      <Accordion.Header>
                                        <div className="row">
                                          <div className="col-lg-10">
                                            <h5 className="h5">
                                              {comment.name}
                                            </h5>
                                          </div>
                                        </div>
                                      </Accordion.Header>

                                      <Accordion.Body key={index}>
                                        <strong>Explanation:</strong>
                                        <p>{comment.explanation}</p>
                                        <br />
                                        <Badge bg="secondary">
                                          By: {comment.creator}
                                        </Badge>
                                        <br />
                                        <Badge bg="secondary">
                                          Country scope: {comment.country}
                                        </Badge>
                                        <br />
                                        <Badge bg="secondary">
                                          Category: {comment.category}
                                        </Badge>
                                      </Accordion.Body>
                                    </Accordion.Item>
                                  </Accordion>
                                </div>
                              </div>
                            </div>
                          )
                        )}
                      </div>
                    </div>
                    <div className="col-lg-6">
                      <h3 className="H3">Selected Comments</h3>
                      {selectedComments.map(
                        (
                          comment: IPerformanceMetricComments,
                          index: number
                        ) => (
                          <div
                            key={index}
                            className="col-lg-12 shadow rounded m-2 p-2"
                          >
                            <div className="row justify-content-center align-items-center">
                            <div className="col-lg-4">
                                <div className="flex flex-row justify-center align-middle" style={{display:"flex"}}>
                                <div className="btn btn-warning" onClick={()=>{
                                  moveSelectedArray(index, "up", "comments")
                                }}>
                                  <BsArrowUp />
                                </div>
                                <div className="btn btn-warning mx-1" onClick={()=>{
                                   moveSelectedArray(index, "down", "comments")
                                }}>
                                <BsArrowDown /> 
                                </div>
                                <div
                                  className="btn btn-danger"
                                  onClick={() => {
                                    commentsPicker(index, "remove");
                                  }}
                                >
                                   <BsTrash size={16}/>
                                </div>
                                </div>
                                
                              </div>
                              <div className="col-lg-8">
                                <Accordion>
                                  <Accordion.Item eventKey={index.toString()}>
                                    <Accordion.Header>
                                      <div className="row">
                                        <div className="col-lg-10">
                                          <h5 className="h5">{comment.name}</h5>
                                        </div>
                                      </div>
                                    </Accordion.Header>

                                    <Accordion.Body key={index}>
                                      <strong>Explanation:</strong>
                                      <p>{comment.explanation}</p>

                                      <br />
                                      <Badge bg="secondary">
                                        By: {comment.creator}
                                      </Badge>
                                      <br />
                                      <Badge bg="secondary">
                                        Country scope: {comment.country}
                                      </Badge>
                                      <br />
                                      <Badge bg="secondary">
                                        Category: {comment.category}
                                      </Badge>
                                    </Accordion.Body>
                                  </Accordion.Item>
                                </Accordion>
                              </div>
                            </div>
                          </div>
                        )
                      )}
                    </div>
                  </div>
                </Accordion.Body>
              </Accordion>

              
              <hr className="hr" />
              {
                //5. Finalize the setup
              }
              <Accordion>
                <Accordion.Header
                  className={`shadow rounded p-2 m-2 ${
                    selectedEvaluationPeriod && selectedEvaluationPeriod.length > 0
                      ? "bg-success"
                      : "bg-warning"
                  }`}
                >
                  <div className="row">
                    <div className="col-lg-12">
                      <h6 className="h6">
                        <BsAward /> 5. Finalize the setup and create Appraisal!
                      </h6>
                    </div>
                  </div>
                </Accordion.Header>
                <Accordion.Body>
                  <Accordion.Item eventKey="0">
                    <div className="row align-items-center justify-content-center m-2 p-2">
                      <div className="col-lg-2">Expectation overview</div>
                      <div className="col-lg-10">
                        <table className="table table-bordered">
                          <tbody>
                            <tr>
                              <th>A</th>
                              <th>B</th>
                              <th>C</th>
                              <th>D</th>
                              <th>E</th>
                              <th>F</th>
                            </tr>
                            <tr>
                              <th colSpan={2}>Exceeds expectations</th>
                              <th colSpan={2}>Meets Expectations</th>
                              <th colSpan={2}>Improvement needed</th>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                    </div>
                    <div className="row align-items-center justify-content-center m-2 p-2">
                      <div className="col-lg-2">Rating distribution</div>
                      <div className="col-lg-10">
                        <table className="table table-bordered">
                          <tbody>
                            <tr>
                              <th>A</th>
                              <th>B</th>
                              <th>C</th>
                              <th>D</th>
                              <th>E</th>
                              <th>F</th>
                            </tr>
                            <tr>
                              <td>
                                <input
                                  type="number"
                                  style={{
                                    width: 60,
                                    padding: 5,
                                    margin: 2,
                                    borderRadius: 5,
                                    border: "1px solid grey",
                                  }}
                                  value={ratingDistribution.a.from}
                                  onChange={({ target }) => {
                                    const num: number = parseInt(target.value);
                                    setRatingDistribution({
                                      ...ratingDistribution,
                                      a: {
                                        from: num,
                                        to: ratingDistribution.a.to,
                                      },
                                    });
                                  }}
                                />
                                -
                                <input
                                  type="number"
                                  style={{
                                    width: 60,
                                    padding: 5,
                                    margin: 2,
                                    borderRadius: 5,
                                    border: "1px solid grey",
                                  }}
                                  value={ratingDistribution.a.to}
                                  onChange={({ target }) => {
                                    const num: number = parseInt(target.value);
                                    setRatingDistribution({
                                      ...ratingDistribution,
                                      a: {
                                        from: ratingDistribution.a.from,
                                        to: num,
                                      },
                                    });
                                  }}
                                />
                              </td>
                              <td>
                                <input
                                  type="number"
                                  style={{
                                    width: 60,
                                    padding: 5,
                                    margin: 2,
                                    borderRadius: 5,
                                    border: "1px solid grey",
                                  }}
                                  value={ratingDistribution.b.from}
                                  onChange={({ target }) => {
                                    const num: number = parseInt(target.value);
                                    setRatingDistribution({
                                      ...ratingDistribution,
                                      b: {
                                        from: num,
                                        to: ratingDistribution.b.to,
                                      },
                                    });
                                  }}
                                />
                                -
                                <input
                                  type="number"
                                  style={{
                                    width: 60,
                                    padding: 5,
                                    margin: 2,
                                    borderRadius: 5,
                                    border: "1px solid grey",
                                  }}
                                  value={ratingDistribution.b.to}
                                  onChange={({ target }) => {
                                    const num: number = parseInt(target.value);
                                    setRatingDistribution({
                                      ...ratingDistribution,
                                      b: {
                                        from: ratingDistribution.b.from,
                                        to: num,
                                      },
                                    });
                                  }}
                                />
                              </td>
                              <td>
                                <input
                                  type="number"
                                  style={{
                                    width: 60,
                                    padding: 5,
                                    margin: 2,
                                    borderRadius: 5,
                                    border: "1px solid grey",
                                  }}
                                  value={ratingDistribution.c.from}
                                  onChange={({ target }) => {
                                    const num: number = parseInt(target.value);
                                    setRatingDistribution({
                                      ...ratingDistribution,
                                      c: {
                                        from: num,
                                        to: ratingDistribution.c.to,
                                      },
                                    });
                                  }}
                                />
                                -
                                <input
                                  type="number"
                                  style={{
                                    width: 60,
                                    padding: 5,
                                    margin: 2,
                                    borderRadius: 5,
                                    border: "1px solid grey",
                                  }}
                                  value={ratingDistribution.c.to}
                                  onChange={({ target }) => {
                                    const num: number = parseInt(target.value);
                                    setRatingDistribution({
                                      ...ratingDistribution,
                                      c: {
                                        from: ratingDistribution.c.from,
                                        to: num,
                                      },
                                    });
                                  }}
                                />
                              </td>
                              <td>
                                <input
                                  type="number"
                                  style={{
                                    width: 60,
                                    padding: 5,
                                    margin: 2,
                                    borderRadius: 5,
                                    border: "1px solid grey",
                                  }}
                                  value={ratingDistribution.d.from}
                                  onChange={({ target }) => {
                                    const num: number = parseInt(target.value);
                                    setRatingDistribution({
                                      ...ratingDistribution,
                                      c: {
                                        from: num,
                                        to: ratingDistribution.d.to,
                                      },
                                    });
                                  }}
                                />
                                -
                                <input
                                  type="number"
                                  style={{
                                    width: 60,
                                    padding: 5,
                                    margin: 2,
                                    borderRadius: 5,
                                    border: "1px solid grey",
                                  }}
                                  value={ratingDistribution.d.to}
                                  onChange={({ target }) => {
                                    const num: number = parseInt(target.value);
                                    setRatingDistribution({
                                      ...ratingDistribution,
                                      d: {
                                        from: ratingDistribution.d.from,
                                        to: num,
                                      },
                                    });
                                  }}
                                />
                              </td>
                              <td>
                                <input
                                  type="number"
                                  style={{
                                    width: 60,
                                    padding: 5,
                                    margin: 2,
                                    borderRadius: 5,
                                    border: "1px solid grey",
                                  }}
                                  value={ratingDistribution.e.from}
                                  onChange={({ target }) => {
                                    const num: number = parseInt(target.value);
                                    setRatingDistribution({
                                      ...ratingDistribution,
                                      e: {
                                        from: num,
                                        to: ratingDistribution.e.to,
                                      },
                                    });
                                  }}
                                />
                                -
                                <input
                                  type="number"
                                  style={{
                                    width: 60,
                                    padding: 5,
                                    margin: 2,
                                    borderRadius: 5,
                                    border: "1px solid grey",
                                  }}
                                  value={ratingDistribution.e.to}
                                  onChange={({ target }) => {
                                    const num: number = parseInt(target.value);
                                    setRatingDistribution({
                                      ...ratingDistribution,
                                      e: {
                                        from: ratingDistribution.e.from,
                                        to: num,
                                      },
                                    });
                                  }}
                                />
                              </td>
                              <td>
                                <input
                                  type="number"
                                  style={{
                                    width: 60,
                                    padding: 5,
                                    margin: 2,
                                    borderRadius: 5,
                                    border: "1px solid grey",
                                  }}
                                  value={ratingDistribution.f.from}
                                  onChange={({ target }) => {
                                    const num: number = parseInt(target.value);
                                    setRatingDistribution({
                                      ...ratingDistribution,
                                      f: {
                                        from: num,
                                        to: ratingDistribution.f.to,
                                      },
                                    });
                                  }}
                                />
                                -
                                <input
                                  type="number"
                                  style={{
                                    width: 60,
                                    padding: 5,
                                    margin: 2,
                                    borderRadius: 5,
                                    border: "1px solid grey",
                                  }}
                                  value={ratingDistribution.f.to}
                                  onChange={({ target }) => {
                                    const num: number = parseInt(target.value);
                                    setRatingDistribution({
                                      ...ratingDistribution,
                                      f: {
                                        from: ratingDistribution.f.from,
                                        to: num,
                                      },
                                    });
                                  }}
                                />
                              </td>
                            </tr>
                            <tr>
                              <td>6</td>
                              <td>5</td>
                              <td>4</td>
                              <td>3</td>
                              <td>2</td>
                              <td>1</td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                    </div>
                    <div className="row align-items-center justify-content-center m-2 p-2">
                      <div className="col-lg-2">Country selection</div>
                      <div className="col-lg-10">
                        <select
                          onChange={({ target }) => {
                            setSelectedCountryForAppraisal(target.value);
                          }}
                          className="form-control"
                        >
                          <option
                            defaultValue={selectedCountry}
                            value={selectedCountry}
                          >
                            {selectedCountry}
                          </option>
                          {countries.map((c, i) => (
                            <option key={i} value={c.name}>
                              {c.name}
                            </option>
                          ))}
                        </select>
                      </div>
                    </div>
                    <div className="row align-items-center justify-content-center m-2 p-2">
                      <div className="col-lg-2">
                        Evaluation Period selection
                      </div>
                      <div className="col-lg-10">
                        <select
                          onChange={({ target }) => {
                            if(target.value === "-1"){
                              setSelectedEvaluationPeriod(null);
                            }
                            setSelectedEvaluationPeriod(target.value);
                          }}
                          className="form-control"
                        >
                          <option value={-1}>
                            ---
                          </option>
                          {evaluationPeriods.map((c, i) => (
                            <option key={i} value={c.name}>
                              {c.name}
                            </option>
                          ))}
                        </select>
                      </div>
                    </div>

                    <div className="row inline">
                      <div
                        onClick={createAppraisal}
                        className="btn btn-primary"
                      >
                        Create new Appraisal!
                      </div>
                    </div>
                  </Accordion.Item>
                </Accordion.Body>
              </Accordion>
            </Accordion.Body>
          </Accordion>
        </>
      )}
    </AdminContainer>
  );
};

export default EvaluationComposer;
