import { useState } from "react";
import { Accordion } from "react-bootstrap";
import { BsFillCaretLeftFill, BsFillCaretRightFill, BsXCircle } from "react-icons/bs";

interface VideoInterface {
  title: string;
  url: string;
}
const Tutorial = ({
  style,
  className,
  toggleButton,
}: {
  style: React.CSSProperties;
  className: string;
  toggleButton: () => void;

}) => {
  const [progress, setProgress] = useState<number>(0);

  const [videoArray] = useState<VideoInterface[]>([
    {
      title: "Global setup & User management",
      url: "-T6K_8lxULs",
    },
    {
      title: "Appraisal management",
      url: "DdjsPOlXXYo",
    },
    {
      title: "Appraisal document Creation",
      url: "lEqzWv9DmhI",
    },
    {
      title: "Appraisal document Evaluation",
      url: "YkrC8-LcFfc",
    },
    {
      title: "Annual Evaluation - Document structure",
      url: "U3Jk5gXiSRw",
    },
    {
      title: "Annual Evaluation - Partial Management",
      url: "ievGwA86UsQ",
    },
    {
      title: "Annual Evaluation - Document Creation && Evaluation",
      url: "SLZzTcSFfgo",
    },
  ]);
  return (
    <Accordion className={className} style={style}>
      <Accordion.Header>
        <h6 className="h6 text-center">Tutorial</h6>
      </Accordion.Header>
      <Accordion.Body style={{position:"relative"}}>
        <div className="text-center">
        <span style={{textAlign:"right", fontSize:30, color:"red"}} onClick={()=>{toggleButton()}}><BsXCircle /></span>
        </div>
      
      <div className="text-center text-bold">{progress+1}/{videoArray.length}</div>
     <div style={{display:"flex", alignItems:"center", justifyContent:"space-between"}}>
        <div>
            {
                progress>0&&<BsFillCaretLeftFill style={{fontSize:60}} onClick={()=>

                   { 
                    if(progress>0){
                        setProgress(progress-1)
                    }
                }
                }/>
            }
        </div>
        <div>
            {
                progress<videoArray.length-1&&<BsFillCaretRightFill style={{fontSize:60}} onClick={()=>

                     {
                        if(progress<videoArray.length-1){
                            setProgress(progress+1)
                        }

                    }
                }/>

            }
        </div>
        </div>
        <div style={{display:"flex",flexDirection:"column",  flex:1, justifyContent:"center", alignItems:"center"}}>
            <div className="title h5" style={{maxWidth:"90%"}}>
                {videoArray[progress].title}
            </div>
            <iframe style={{maxWidth:"90%"}} width="560" height="315" src={`https://www.youtube.com/embed/${videoArray[progress].url}`} title="YouTube video player" allowFullScreen={true} allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"></iframe>
     </div>
       
      </Accordion.Body>
    </Accordion>
  );
};

export default Tutorial;
