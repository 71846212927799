
import { useCallback, useEffect, useState } from 'react'
import { IEvaluationDynamicDocument} from '../../interface'
import {  IInputOutput } from '../../interface'
import { Radar } from 'react-chartjs-2';

import {
    Chart as ChartJS,
    RadialLinearScale,
    PointElement,
    LineElement,
    Filler,
    Tooltip,
    Legend,
  } from 'chart.js';

  ChartJS.register(
    RadialLinearScale,
    PointElement,
    LineElement,
    Filler,
    Tooltip,
    Legend
  );

  interface DataSetInterface {
    data: number[],
    borderColor: string, //'rgba(255, 99, 132, 1)'
    backgroundColor: string,//'rgba(255, 99, 132, 0.2)',
    borderWidth: number,
    label:string,
}
const EnergizerAssesmentChart = ({EvalDynamicDocument}:{EvalDynamicDocument:IEvaluationDynamicDocument[]}) => {

const [getLabels, setGetLabels] = useState<string[]>([])
const [CustomDataSet, setCustomDataSet] = useState<DataSetInterface[]>([])

    const manageCompetenciesData = useCallback(()=>{
EvalDynamicDocument.forEach((e:IEvaluationDynamicDocument)=>{
    const rating:any = e.appraisalDocument.ratingDistribution;
    const inputs:{[key:string]:IInputOutput} = e.inputsEnergizer;
    let tempArray:number[] = [];
    let tempLables:string[] = []
    if(Object.keys(inputs).length > 0){
        for(let key in inputs){
            tempLables.push(inputs[key].name)
           for(let aKey in rating){
            const fullfilment =  inputs[key].fulfillment;
            let {from, to, letter}: {from:number, to:number, letter:string} = rating[aKey]
            if(fullfilment<=from && fullfilment>= to){
                if(letter === "A"){
                    tempArray.push(6)
                }else if(letter === "B"){
                    tempArray.push(5)
                }
                else if(letter === "C"){
                    tempArray.push(4)
                }
                else if(letter === "D"){
                    tempArray.push(3)
                }
                else if(letter === "E"){
                    tempArray.push(2)
                }
                else if(letter === "F"){
                    tempArray.push(1)
                }
            }
           }
        }
    setGetLabels(tempLables)
    setCustomDataSet((prev:DataSetInterface[])=>[...prev, {
        label:e.appraisalPeriod,
        data:tempArray,
        borderColor:"white",
        borderWidth:1,
        backgroundColor:`rgba(${Math.floor(Math.random()*254)}, ${Math.floor(Math.random()*254)}, ${Math.floor(Math.random()*254)}, 0.2)`
    }])
    }
});
        
       
    },[EvalDynamicDocument]);
    useEffect(()=>{
        manageCompetenciesData()

        return () => {
            setCustomDataSet([])
            setGetLabels([])
        }
        
    },[manageCompetenciesData])

  return (
   <div style={{width:"100%", maxWidth:"100%", margin:"0 auto"}}>
   {
    getLabels.length>0 &&<Radar
    data={
        {
            labels:getLabels,
            datasets:CustomDataSet
        }
    }
   options={{
    scales: {
        r: {
            angleLines: {
                display: false
            },
            suggestedMin: 1,
            suggestedMax: 6
        }
    }
   }}
/>
   }
   
   </div>
        
  )
}

export default EnergizerAssesmentChart