import { useState, useEffect } from "react";
import {
  IPerformanceMetricInput,
  UserInterface,
  ICountry,
} from "../../../interface";
import CountryFilter from "../../../components/CountryFilter";
import { Category } from "../../../interface/Enums/category.enum";
import { PerfType } from "../../../interface/Enums/peftype.enum";
import { useDispatch, useSelector } from "react-redux";
import { getAllCountries } from "../../../APIs";
import { fetchAPI } from "../../../utils/FetchApi";
import { FetchMethods } from "../../../interface/Enums/methods.enum";
import { setError } from "../../../store/reducers/errorReducer";
import Dropdown from "react-bootstrap/Dropdown";
import { BsThreeDotsVertical } from "react-icons/bs";
import Badge from "react-bootstrap/Badge";
import Accordion from "react-bootstrap/Accordion";
import { BsPerson } from "react-icons/bs";
import AdminContainer from "../../../admin-components/AdminContainer";
const ManagePerformanceOutputMetrics = () => {
  const dispatch:any = useDispatch();
  const data: UserInterface = useSelector((data: any) => {
    return data.auth.user;
  });

  const [listOfCountries, setlistOfCountries] = useState<ICountry[]>([]);
  const [selectedCountry, setSelectedCountry] = useState<string>("");
  const [performanceMetrics, setPerformanceMetrics] = useState<
    IPerformanceMetricInput[]
  >([]);
  const [showAddSection, setshowAddSection] = useState<boolean>(false);
  const [reloadAllData, setReloadAllData] = useState<boolean>(false);
  const [performanceMetric, setPerformanceMetric] =
    useState<IPerformanceMetricInput>({
      name: "",
      explanation: "",
      category: Category.OUTPUT,
      type: PerfType.STANDARD,
      country: "",
      importance: 0,
      createdAt: new Date(),
      updatedAt: new Date(),
      creator: data.name + " " + data.surname,
    });

    const [updatedPerformanceMetric, setUpdatedPerformanceMetric] = useState<IPerformanceMetricInput>({ name:"", 
explanation:"",
category: Category.OUTPUT,
type: PerfType.STANDARD,
country:"",
importance:0,
createdAt:new Date(),
updatedAt: new Date(),
creator: data.name + " " + data.surname,
_id:""
})
const [showUpdate, setShowUpdate] = useState<boolean>(false)
const updatePerformanceMetric = async () =>{

  try {

      const updatedPerfs:IPerformanceMetricInput[] = await fetchAPI("/admincmds/update-performance-output-metric", FetchMethods.PATCH, updatedPerformanceMetric);
      setPerformanceMetrics(updatedPerfs)
      setReloadAllData(!reloadAllData);
      setShowUpdate(false);
      
      setUpdatedPerformanceMetric({name:"", 
      explanation:"",
      category: Category.OUTPUT,
      type: PerfType.STANDARD,
      country:"",
      importance:0,
      createdAt:new Date(),
      updatedAt: new Date(),
      creator:"",
      _id:""})
   } catch (error:any) {
    dispatch(setError(error.toString()))
  }
}
  const createPerfromanceMetric = async () => {
//validate performance metric name, country, creator, explanation can not be empty and importance must be between 1 - 100
    if(performanceMetric.name.length===0 || performanceMetric.country.length===0 || performanceMetric.creator.length===0 || performanceMetric.explanation.length===0 || performanceMetric.importance<1 || performanceMetric.importance>100){
      dispatch(setError("Please fill in all the fields correctly - name, country, creator, explanation must not be empty and importance must have a value from 1 to 100"))
          return;
    }
    try {
      const listOfAllInputs: IPerformanceMetricInput[] = await fetchAPI(
        "/admincmds/create-performance-output-metric",
        FetchMethods.POST,
        performanceMetric
      );
      setshowAddSection(!showAddSection);
      setReloadAllData(!reloadAllData);
      setPerformanceMetric({
        name: "",
        explanation: "",
        category: Category.OUTPUT,
        type: PerfType.STANDARD,
        country: "",
        importance: 0,
        createdAt: new Date(),
        updatedAt: new Date(),
        creator: data.name + " " + data.surname,
      });
      setPerformanceMetrics(listOfAllInputs);
    } catch (error: any) {
      dispatch(setError(error.message.toString()));
    }
  };

  const deletePerformanceMetric = async (index: number) => {
    try {
      const listOfAllInputs: IPerformanceMetricInput[] = await fetchAPI(
        `/admincmds/delete-performance-output-metric/${performanceMetrics[index]._id}`,
        FetchMethods.GET
      );
      console.log(listOfAllInputs)
      if (listOfAllInputs.hasOwnProperty("error")) {
        //@ts-ignore
        throw new Error(listOfAllInputs.message?.toString());
      }
      setReloadAllData(!reloadAllData);

      setPerformanceMetrics(listOfAllInputs);
    } catch (error: any) {
      dispatch(setError(error.toString()));
    }
  };
  const pickCountry = (country: string): void => {
    setSelectedCountry(country);
  };

  /**
   * Use Effects
   */
  useEffect(() => {
    (async () => {
      const ctries: ICountry[] = await getAllCountries();
      ctries.sort((a, b) => (a.name > b.name ? 1 : -1));
      setlistOfCountries(ctries);
    })();
  }, [reloadAllData]);

  //Load all inputs based on the country
  useEffect(() => {
    if(selectedCountry.length>0){
      (async () => {
        try {
          const getAllInputs: IPerformanceMetricInput[] = await fetchAPI(
            `/admincmds/get-all-performance-output-metrics/${selectedCountry}`,
            FetchMethods.GET
          );
          if (getAllInputs.hasOwnProperty("error")) {
            throw new Error(JSON.stringify(getAllInputs));
          }
          setPerformanceMetrics(getAllInputs);
        } catch (error: any) {
          dispatch(JSON.stringify(error.message));
        }
      })();
    }
    
    //do nothing yet
  }, [selectedCountry, dispatch]);
  //hlídej to zasraný jméno
  useEffect(() => {
    setPerformanceMetric(p=>({
      ...p,
      creator: data.name + " " + data.surname,
    }));
  }, [data]);
  const scrollToBottom = () => {
    window.scrollTo({ top: window.innerHeight });
  };
  return (
    <AdminContainer>
          <div className="row justify-content-between align-items-center">
        <div className="col-lg-8 m-2">
        <CountryFilter returnedCountry={pickCountry} />
        </div>
        <div className="col-lg-2 m-2 border p-1">
        <button
                className="btn btn-danger"
                type="button"
                onClick={() => {
                  setshowAddSection(!showAddSection);
                  scrollToBottom()
                }}
              >
                Create new Performance Metric
              </button>
        </div>
      </div>
<hr className="hr" />
      <div className="row justify-content-md-center">
        {performanceMetrics.map((c: IPerformanceMetricInput, i: number) => (
          <div className="card col-lg-3 col-md-4 cal-sm-2 m-2" key={i}>
            <div className="row flex-row-reverse">
              <div className="m-2">
                <Dropdown>
                  <Dropdown.Toggle size="sm" variant="info">
                    <BsThreeDotsVertical />
                  </Dropdown.Toggle>
                  <Dropdown.Menu>
                    <Dropdown.Item
                      href="#/action-1"
                      onClick={() => {
                        scrollToBottom();
                        setshowAddSection(false);
                        setShowUpdate(!showUpdate)
                        setUpdatedPerformanceMetric(performanceMetrics[i]);
                      }}
                    >
                      {showUpdate?"Close update":"Update"}
                    </Dropdown.Item>
                    <Dropdown.Item
                      href="#/action-2"
                      onClick={() => {
                        deletePerformanceMetric(i);
                      }}
                    >
                      Delete
                    </Dropdown.Item>
                  </Dropdown.Menu>
                </Dropdown>
                <Badge
                  bg={c.category === "input" ? "secondary" : "success"}
                  className="mr-2"
                >
                  {c.category}
                </Badge>
                <Badge
                  bg={c.type === "standard" ? "info" : "danger"}
                  className="mr-2"
                >
                  {c.type}
                </Badge>
                <Badge bg="warning" className="mr-2">
                  {c.importance}%
                </Badge>
              </div>
            </div>
            <div className="card-body">
              <Accordion>
                <Accordion.Item eventKey={i.toString()}>
                  <Accordion.Header>
                    <BsPerson />
                    {"   "}
                    <small className="m-2" style={{ fontWeight: "bold" }}>
                      {c.name}
                    </small>
                  </Accordion.Header>
                  <Accordion.Body key={i}>
                    <strong>Explanation:</strong>
                    <p>{c.explanation}</p>
                    <br />
                    <Badge bg="secondary">By: {c.creator}</Badge>
                    <br />
                    <Badge bg="secondary">Country scope: {c.country}</Badge>
                    <br />
                    <Badge bg="secondary">Importance: {c.importance}%</Badge>
                    <br />
                    <Badge bg="secondary">Category: {c.category}</Badge>
                    <br />
                    <Badge bg="secondary">Type: {c.type}</Badge>
                    <br />
                  </Accordion.Body>
                </Accordion.Item>
              </Accordion>
            </div>
          </div>
        ))}
      </div>
      {
        // Create a performance metric
      }
      <div className="row">
        <form
          onSubmit={(e) => {
            e.preventDefault();
            createPerfromanceMetric();
          }}
        >
          <div className="row" style={{ marginTop: 100 }}>
            <div className="form-group">
             

              {showAddSection && (
                <div className="card" style={{ padding: 30 }}>
                  <div className="row p-4 m-4 shadow rounded">
                    <div className="col-4">
                      <h3>Metric Name</h3>
                    </div>
                    <div className="col-6">
                      <input
                        className="form-control"
                        type="text"
                        value={performanceMetric.name}
                        onChange={({ target }) => {
                          setPerformanceMetric({
                            ...performanceMetric,
                            name: target.value,
                          });
                        }}
                      />
                    </div>
                  </div>

                  <div className="row p-4 m-4 shadow rounded">
                    <div className="col-4">
                      <h3>Metric Explanation</h3>
                    </div>
                    <div className="col-6">
                      {" "}
                      <input
                        className="form-control"
                        type="text"
                        value={performanceMetric.explanation}
                        onChange={({ target }) => {
                          setPerformanceMetric({
                            ...performanceMetric,
                            explanation: target.value,
                          });
                        }}
                      />
                    </div>
                  </div>

                  <div className="row p-4 m-4 shadow">
                    <div className="col-4">
                      <h3>Metric Type</h3>
                    </div>
                    <div className="col-6">
                      <select
                        className="form-control"
                        onChange={({ target }) => {
                          
                          setPerformanceMetric({
                            ...performanceMetric,
                            //@ts-ignore
                            type: target.value,
                          });
                        }}
                      >
                   
                        <option
                          className="form-control"
                          value={PerfType.STANDARD}
                        >
                          {PerfType.STANDARD}
                        </option>
                      
                      </select>
                    </div>
                  </div>

                  <div className="row p-4 m-4 shadow rounded">
                    <div className="col-4"> <h3>Importance (%)</h3> <p className="small">Insert number only from 0-100!</p></div>
                    <div className="col-6"><input
                    value={performanceMetric.importance}
                    max={100}
                    min={0}
                    className="form-control"
                    type="number"
                    onChange={({ target }) => {
                      setPerformanceMetric({
                        ...performanceMetric,
                        importance: parseInt(target.value),
                      });
                    }}
                  /></div>
                    </div>
                 
                 
                    <div className="row p-4 m-4 shadow rounded">
                    <div className="col-4"><h3>Country</h3></div>
                    <div className="col-6"><select
                    className="form-control"
                    onChange={({ target }) => {
                      setPerformanceMetric({
                        ...performanceMetric,
                        country: target.value,
                      });
                    }}
                  >
                    <option className="form-control" value="---">
                      ---
                    </option>
                    {listOfCountries.map((c: ICountry, i: number) => (
                      <option className="form-control" key={i} value={c.name}>
                        {c.name}
                      </option>
                    ))}
                  </select></div>
                    </div>
                  
                  
                    <div className="col p-4 m-4 shadow rounded"> 
                    <h3 className="text-center">Creator</h3>
                    <input
                    className="form-control"
                    type="text"
                    readOnly
                    value={performanceMetric.creator}
                    style={{ backgroundColor: "#d6d6d6", textAlign:"center" }}
                  /></div>
                 
                 <div className="row"> <button type="submit" className="btn btn-danger">
                    Create
                  </button></div>
                 
                </div>
              )}
            </div>
          </div>
        </form>
      </div>
      {
        // Update performance metric
      }


<div className="row">
        <form
          onSubmit={(e) => {
            e.preventDefault();
            updatePerformanceMetric();
          }}
        >
          <div className="row" style={{ marginTop: 100 }}>
            <div className="form-group">
            

              {showUpdate && (
                <div className="card" style={{ padding: 30 }}>
                  <h1 className="bold small text-center shadow p-4 m-4">You are updating <span className="text-info">{updatedPerformanceMetric.name}</span> | <span className="text-danger">{updatedPerformanceMetric._id}</span></h1>
                  <div className="row p-4 m-4 shadow rounded">

                    <div className="col-4">
                      <h3>Update Metric Name</h3>
                    </div>
                    <div className="col-6">
                      <input
                        className="form-control"
                        type="text"
                        value={updatedPerformanceMetric.name}
                        onChange={({ target }) => {
                          setUpdatedPerformanceMetric({
                            ...updatedPerformanceMetric,
                            name: target.value,
                          });
                        }}
                      />
                    </div>
                  </div>

                  <div className="row p-4 m-4 shadow rounded">
                    <div className="col-4">
                      <h3>Update Metric Explanation</h3>
                    </div>
                    <div className="col-6">
                      {" "}
                      <input
                        className="form-control"
                        type="text"
                        value={updatedPerformanceMetric.explanation}
                        onChange={({ target }) => {
                          setUpdatedPerformanceMetric({
                            ...updatedPerformanceMetric,
                            explanation: target.value,
                          });
                        }}
                      />
                    </div>
                  </div>

                  <div className="row p-4 m-4 shadow">
                    <div className="col-4">
                      <h3>Update Metric Type</h3>
                    </div>
                    <div className="col-6">
                      <select
                        className="form-control"
                        onChange={({ target }) => {
                          
                          setUpdatedPerformanceMetric({
                            ...updatedPerformanceMetric,
                            //@ts-ignore
                            type: target.value,
                          });
                        }}
                      >
                    
                        <option
                          className="form-control"
                          value={PerfType.STANDARD}
                        >
                          {PerfType.STANDARD}
                        </option>
                       
                      </select>
                    </div>
                  </div>

                  <div className="row p-4 m-4 shadow rounded">
                    <div className="col-4"> <h3>Update Importance (%)</h3> <p className="small">Insert number only from 0-100!</p></div>
                    <div className="col-6"><input
                    value={updatedPerformanceMetric.importance}
                    max={100}
                    min={0}
                    className="form-control"
                    type="number"
                    onChange={({ target }) => {
                      setUpdatedPerformanceMetric({
                        ...updatedPerformanceMetric,
                        importance: parseInt(target.value),
                      });
                    }}
                  /></div>
                    </div>
                 
                 
                    <div className="row p-4 m-4 shadow rounded">
                    <div className="col-4"><h3>Country</h3></div>
                    <div className="col-6"><select
                    className="form-control"
                    onChange={({ target }) => {
                      setUpdatedPerformanceMetric({
                        ...updatedPerformanceMetric,
                        country: target.value,
                      });
                    }}
                  >
                    <option defaultValue={updatedPerformanceMetric.country} className="form-control" value={updatedPerformanceMetric.country}>
                    {updatedPerformanceMetric.country}
                    </option>
                    {listOfCountries.map((c: ICountry, i: number) => (
                      <option className="form-control" key={i} value={c.name}>
                        {c.name}
                      </option>
                    ))}
                  </select></div>
                    </div>
                  
                  
                    <div className="col p-4 m-4 shadow rounded"> 
                    <h3 className="text-center">Creator</h3>
                    <input
                    className="form-control"
                    type="text"
                    readOnly
                    value={updatedPerformanceMetric.creator}
                    style={{ backgroundColor: "#d6d6d6", textAlign:"center" }}
                  /></div>
                 
                 <div className="row"> <button type="submit" className="btn btn-danger">
                    Update {updatedPerformanceMetric.name}
                  </button></div>
                 
                </div>
              )}
            </div>
          </div>
        </form>
      </div>
    </AdminContainer>
  );
};

export default ManagePerformanceOutputMetrics;
