import React, { useEffect, useState } from 'react';
import { BsFillFilePlusFill, BsFillTrashFill, BsSafe } from 'react-icons/bs';
import { useDispatch } from 'react-redux';
import AdminContainer from '../../../admin-components/AdminContainer'
import CountryFilter from '../../../components/CountryFilter';
import { FetchMethods } from '../../../interface/Enums/methods.enum';
import { IDynamicTableShape } from '../../../interface/DynamicTableShape.interface';
import { setError } from "../../../store/reducers/errorReducer";
import { fetchAPI } from '../../../utils/FetchApi';
import DynamicTableShapes from '../AnnualComponents/DynamicTableShapes';

const emptyObject:IDynamicTableShape = {
    country:"",
    columns:[],
    name:"",
    notes:"",
    description:"",
    __v:0,
}
const AnnualEvaluationSummary = () => {

const dispatch = useDispatch()
const [dynamicTableShape, setDynamicTableShape] = useState<IDynamicTableShape>(emptyObject)
const [showCreateDynamicTableShape, setShowCreateDynamicTableShape] = useState<boolean>(false)
const [listOfDynamicTableShapes, setListOfDynamicTableShapes] = useState<IDynamicTableShape[]>([]);
const [wasDataUpdated, setWasDataUpdated] = useState<boolean>(false);

//controls a country   
const [selectedCountry, setSelectedCountry] = useState<string>("---");
const pickCountry = (country: string) => {
    setSelectedCountry(country);
  };

  const saveIt = async () =>{
    try {
        let {columns, name} = dynamicTableShape;
        dynamicTableShape.country = selectedCountry;

        if(columns.length<1)throw new Error("No columns were selected!");

        if(name.length<1)throw new Error("Name is mandatory!");

        const saveData:IDynamicTableShape[] = await fetchAPI(`/evaluationcmds/annual-competency-development/create`,FetchMethods.POST,dynamicTableShape);
//@ts-ignore
        if(saveData.hasOwnProperty("error"))throw new Error(saveData.message);

        setListOfDynamicTableShapes(saveData);
setDynamicTableShape(emptyObject);
    } catch (error:any) {
        dispatch(setError(error.toString()));  
    }
  }

const reactToUpdatedData = () =>{
    setWasDataUpdated(!wasDataUpdated);
}

        useEffect(()=>{
          (async()=>{
            try {
              const getData:IDynamicTableShape[] = await fetchAPI(`/evaluationcmds/annual-competency-development/get/all`,FetchMethods.GET);
              //@ts-ignore
              if(getData.hasOwnProperty("error"))throw new Error(getData.message);
              setListOfDynamicTableShapes(getData);
          } catch (error:any) {
              dispatch(setError(error.toString()));
          }
          })()
         return () =>{
          setListOfDynamicTableShapes([]);
         }
        },[dispatch, wasDataUpdated])
  return (
    <AdminContainer>
      <div className="row">
         <h1 className="h1">Manage - Evaluation summary</h1>
      </div>

      <div className="row justify-content-between align-items-center">
        <div className="col-lg-8 m-2">
        <CountryFilter returnedCountry={pickCountry} />
        </div>
        <div className="col-lg-2 m-2 p-1">
            <p>Performance appraisal - description of the evaluation in the document, development of competences</p>
        <button
              className="btn btn-danger"
              type="button"
              onClick={() => {
               setShowCreateDynamicTableShape(!showCreateDynamicTableShape)
              }}
            >
              Create the summary
            </button>
        </div>
      </div>
      {listOfDynamicTableShapes&&listOfDynamicTableShapes.map((data:IDynamicTableShape,i:number)=><DynamicTableShapes key={i} updatedone={reactToUpdatedData} data={data} />)}
      { 
        showCreateDynamicTableShape&&
        <form onSubmit={(e:React.FormEvent<HTMLFormElement>)=>{
            e.preventDefault();
            saveIt()
        }}>
            <div className="row" style={{ marginTop: 100 }}>
                <div className="form-group">
                <div className="card" style={{ padding: 30 }}>
                        <h3>Name <span className='text-danger'>*</span></h3>
                        <input type="text"
                        className='form-control'
                        value={dynamicTableShape.name} 
                        onChange={({ target }) => {
                          setDynamicTableShape({ ...dynamicTableShape, name: target.value });
                        }}
                        />
                        <h3>Description</h3>
                        <input type="text"
                        className='form-control'
                        value={dynamicTableShape.description} 
                        onChange={({ target }) => {
                          setDynamicTableShape({ ...dynamicTableShape, description: target.value });
                        }}
                        />
                        <h3>Notes</h3>
                        <input type="text"
                        className='form-control'
                        value={dynamicTableShape.notes} 
                        onChange={({ target }) => {
                          setDynamicTableShape({ ...dynamicTableShape, notes: target.value });
                        }}
                        />
                        <h3>Define columns <span className='text-danger'>*</span></h3>
                       <div className="btn btn-warning" onClick={()=>{
                        const arr = [...dynamicTableShape.columns];
                        arr.push("New Column");
                     setDynamicTableShape({
                        ...dynamicTableShape, columns:arr
                     })
                       }}><BsFillFilePlusFill color='white' size={30} /> Add new column</div>

                       <table className='table table-bordered mt-2'>
                        <tbody>
                 
                                {dynamicTableShape.columns.map((t:string,i:number)=>(
                                    <tr key={i}>
                                        <td>
                                        <div className="row">
                                            <div className="col-11">
                                            <input className='form-control' value={dynamicTableShape.columns[i]} onChange={({target})=>{
                                        const arr = [...dynamicTableShape.columns];
                                        arr[i] = target.value;
                                        setDynamicTableShape({...dynamicTableShape, columns:arr})
                                    }} />
                                            </div>
                                            <div className="col-1">
                                            <div className="btn btn-danger"
                                            onClick={()=>{
                                                const arr = dynamicTableShape.columns.filter((t,ind)=>ind!==i);
                                                setDynamicTableShape({...dynamicTableShape, columns:arr})
                                            }}
                                            ><BsFillTrashFill /></div>
                                            </div>
                                        
                                        
                                    </div>
                                    </td>
                                    </tr>
                                ))}
         
                        </tbody>
                       </table>
                       <div className="row">
                       <div onClick={()=>{
                        saveIt()
                       }} className="btn btn-danger"><BsSafe /> Save it</div>
                       </div>
                    </div>
                </div>
            </div>
        </form>
      }
    </AdminContainer>
  )
}

export default AnnualEvaluationSummary