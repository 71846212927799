import { useState, useEffect} from "react";
import AdminContainer from "../../../admin-components/AdminContainer";
import { IJobname } from "../../../interface/IJobname.interface";
import { useDispatch, useSelector } from "react-redux";
import { setError } from "../../../store/reducers/errorReducer";
import { UserInterface } from "../../../interface/UserInterface";
import { fetchAPI } from "../../../utils/FetchApi";
import { FetchMethods } from "../../../interface/Enums/methods.enum";

import Dropdown from "react-bootstrap/Dropdown";
import { BsThreeDotsVertical } from "react-icons/bs";
import Badge from "react-bootstrap/Badge";
import Accordion from "react-bootstrap/Accordion";
import { BsPerson } from "react-icons/bs";

import CountryFilter from "../../../components/CountryFilter"

interface CountryInterface {
  name: string;
  createdAt: Date;
  updatedAt: Date;
  _id: string;
}
const ManageJobname = () => {
  const dispatch = useDispatch();
  
  const data: UserInterface = useSelector((data: any) => {
    return data.auth.user;
  });
const makeEmpty = {
  name: "",
  country: "",
  description: "",
  creator: data.name + " " + data.surname,
  createdAt: new Date(),
}
  const [jobnames, setJobnames] = useState<IJobname[]>([]);
  const [showAddJobname, setshowAddJobname] = useState<boolean>(false);
  const [selectedCountry, setSelectedCountry] = useState<string>("---");
  const [Jobname, setJobname] = useState<IJobname>(makeEmpty);
  const [countries, setCountries] = useState<CountryInterface[]>([]);
  const [makeChange, setMakeChange] = useState<boolean>(false);
  const [showUpdate, setShowUpdate] = useState<boolean>(false);
  const [updatedJobname, setUpdatedJobname] = useState<IJobname>({
    name: "",
    country: "",
    description: "",
    creator: data.name + " " + data.surname,
    createdAt: new Date(),
  });

  const createJobname = async () => {
    const { name, country, creator, description } = Jobname;
    try {
      const data: IJobname = await fetchAPI(
        "/admincmds/create-jobname",
        FetchMethods.POST,
        {
          name,
          country,
          creator,
          description,
        }
      );
      if (data.hasOwnProperty("error")) {
        throw new Error(data.message?.toString());
      }
      setJobname(makeEmpty)
      setMakeChange(!makeChange);
    } catch (error: any) {
      dispatch(setError(error.toString()));
    }
  };

  useEffect(() => {
    (async () => {
      try {
        const data: IJobname[] | any = await fetchAPI(
          `/admincmds/get-all-jobnames/${selectedCountry}`,
          FetchMethods.GET
        );
        if (data.hasOwnProperty("error")) {
          throw new Error(data.message?.toString());
        }
        setshowAddJobname(false);
        setJobnames(data);
      } catch (error: any) {
        dispatch(setError(error.toString()));
      }
    })();
  }, [makeChange, dispatch, selectedCountry]);

  const deleteJobname = async (index: number) => {

    //confirm delete
    if(!window.confirm("Are you sure you want to delete this jobname?")) return;
    try {
      const EvalToBeDeleted: IJobname = await fetchAPI(
        `/admincmds/delete-jobname/${jobnames[index]._id}`,
        FetchMethods.GET
      );
      if (EvalToBeDeleted.hasOwnProperty("error")) {
        throw new Error(EvalToBeDeleted.message?.toString());
      }
      setMakeChange(!makeChange);
      setJobname(makeEmpty)
    } catch (error: any) {
      dispatch(setError(error.toString()));
    }
  };

  const updateJobname = async () => {
    try {
      const getUpdatedJobnames: IJobname[] = await fetchAPI(
        "/admincmds/update-jobname",
        FetchMethods.PATCH,
        updatedJobname
      );
      setJobnames(getUpdatedJobnames);
      setShowUpdate(false);
      setUpdatedJobname(makeEmpty);
    } catch (error) {
      dispatch(setError(JSON.stringify(error)));
    }
  };

  useEffect(() => {
((async()=>{
  const countries: CountryInterface[] = await fetchAPI("/admincmds/get-all-countries", FetchMethods.GET)
  countries.sort((a, b) => (a.name > b.name) ? 1 : -1)
  setCountries(countries);
})())
  }, []);

  useEffect(()=>{
    setJobname(j=>({
      ...j,
      creator:data.name + " "+ data.surname
    }))
  }, [data])

  const scrollToBottom = () => {
  // do nothing
  };

  const pickCountry = (country: string) => {
    setSelectedCountry(country);
  };

  return (
    <AdminContainer>
      <div className="row">
        <h1 className="h1">Manage Jobnames</h1>
      </div>

      <div className="row justify-content-between align-items-center">
        <div className="col-lg-8 m-2">
        <CountryFilter returnedCountry={pickCountry} />
        </div>
        <div className="col-lg-2 m-2 p-1">
        <button
              className="btn btn-danger"
              type="button"
              onClick={() => {
                setshowAddJobname(!showAddJobname);
              }}
            >
              Create new Jobname
            </button>
        </div>
      </div>
<hr className="hr" />
      <div className="row">
        {jobnames.map((c: IJobname, i: number) => (
          <div key={c._id} className="col-sm-6">
           <div className="row shadow rounded p-2 m-2">
            <div className="col-sm-2"> <Dropdown>
                  <Dropdown.Toggle size="sm" variant="info">
                    <BsThreeDotsVertical />
                  </Dropdown.Toggle>
                  <Dropdown.Menu>
                    <Dropdown.Item
                      href="#/action-1"
                      onClick={() => {
                        scrollToBottom();
                        setshowAddJobname(false);
                        setShowUpdate(true);
                        setUpdatedJobname(jobnames[i]);
                      }}
                    >
                      Update
                    </Dropdown.Item>
                    <Dropdown.Item
                      href="#/action-2"
                      onClick={() => {
                        deleteJobname(i);
                      }}
                    >
                      Delete
                    </Dropdown.Item>
                  </Dropdown.Menu>
                </Dropdown></div>
            <div className="col-sm-10">
            <Accordion>
                <Accordion.Item eventKey={i.toString()}>
                  <Accordion.Header>
                    <BsPerson />
                    {"   "}
                    <small className="m-2" style={{ fontWeight: "bold" }}>
                      {c.name}
                    </small>
                  </Accordion.Header>
                  <Accordion.Body key={i}>
                    <strong>Description:</strong>
                    <p>{c.description}</p>
                    <br />
                    <Badge bg="secondary">By: {c.creator}</Badge>
                    <br />
                    <Badge bg="secondary">Country scope: {c.country}</Badge>
                    <br />
                  </Accordion.Body>
                </Accordion.Item>
              </Accordion>
            </div>
           </div>
           </div>
        ))}
      </div>
      <form
      
        onSubmit={(e) => {
          e.preventDefault();
          createJobname();
        }}
      >
        <div className="row" style={{ marginTop: 100 }}>
          <div className="form-group">
           
            {showAddJobname && (
              <div className="card" style={{ padding: 30 }}>
                <h3>Jobname Name</h3>
                <input
                  className="form-control"
                  type="text"
                  value={Jobname.name}
                  onChange={({ target }) => {
                    setJobname({ ...Jobname, name: target.value });
                  }}
                />
                <h3>Jobname description</h3>
                <input
                  className="form-control"
                  type="text"
                  value={Jobname.description}
                  onChange={({ target }) => {
                    setJobname({ ...Jobname, description: target.value });
                  }}
                />
                <h3>Country</h3>
                <select
                  className="form-control"
                  onChange={({ target }) => {
                    setJobname({ ...Jobname, country: target.value });
                  }}
                >
                  <option className="form-control" value="---">
                    ---
                  </option>
                  {countries.map((c: CountryInterface, i: number) => (
                    <option key={i} value={c.name}>
                      {c.name}
                    </option>
                  ))}
                </select>

                <br />
                <input
                  className="form-control"
                  type="text"
                  readOnly
                  value={Jobname.creator}
                  style={{ backgroundColor: "#d6d6d6" }}
                />
                <br />
                <button type="submit" className="btn btn-danger">
                  Create new Job name
                </button>
              </div>
            )}
          </div>
        </div>
      </form>

      <form
        onSubmit={(e) => {
          e.preventDefault();
          updateJobname();
        }}
      >
        <div className="row" style={{ marginTop: 100 }}>
          <div className="form-group">
            {showUpdate && (
              <div className="card" style={{ padding: 30 }}>
                <p
                  onClick={() => {
                    setShowUpdate(false);
                  }}
                >
                  Close update component
                </p>
                <h2>Update {updatedJobname._id}</h2>
                <h3>Section Name</h3>
                <input
                  type="text"
                  autoFocus
                  value={updatedJobname.name}
                  onChange={({ target }) => {
                    setUpdatedJobname({
                      ...updatedJobname,
                      name: target.value,
                    });
                  }}
                />
                <h3>Jobname Description</h3>
                <input
                  type="text"
                  value={updatedJobname.description}
                  onChange={({ target }) => {
                    setUpdatedJobname({
                      ...updatedJobname,
                      description: target.value,
                    });
                  }}
                />

                <h3>Country</h3>
                <select
                  onChange={({ target }) => {
                    setUpdatedJobname({
                      ...updatedJobname,
                      country: target.value,
                    });
                  }}
                >
                  <option value={updatedJobname.country}>
                    {updatedJobname.country}
                  </option>
                  {countries.map((c: CountryInterface, i: number) => (
                    <option key={i} value={c.name}>
                      {c.name}
                    </option>
                  ))}
                </select>
                <br />
                <input
                  type="text"
                  readOnly
                  value={updatedJobname.creator}
                  style={{ backgroundColor: "#d6d6d6" }}
                />
                <br />
                <button type="submit" className="btn btn-danger">
                  Update {updatedJobname.name}
                </button>
              </div>
            )}
          </div>
        </div>

      </form>
    </AdminContainer>
  );
};

export default ManageJobname;
