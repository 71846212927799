import { useState, useEffect } from "react"
import { useDispatch } from "react-redux"
import { FetchMethods } from "../../../../interface/Enums/methods.enum"
import { AppraisalDocument, IEvaluationDynamicDocument } from "../../../../interface"

import { setError } from "../../../../store/reducers/errorReducer"
import { fetchAPI } from "../../../../utils/FetchApi"
import { emptyEvaluationDynamicDocument } from "./EmptyEvaluationDocument"

const AppraisalDocumentSelection = ({selectedAppraisalDocument, previousPeriods}:{selectedAppraisalDocument:Function, previousPeriods?:IEvaluationDynamicDocument[]}) => {
  const dispatch = useDispatch()
  //Get the list of my users
  const [listOfDeparments, setListOfDeparments] = useState<AppraisalDocument[]>([])

useEffect(()=>{
    (async()=>{
      if(previousPeriods && previousPeriods.length > 0){
        const getDeparments:AppraisalDocument[] =  await fetchAPI("/evaluationcmds/get-my-appraisal-documents", FetchMethods.GET);
        if(getDeparments.hasOwnProperty("error")) throw new Error("Departments could not be loaded");

        const filteredDepartments = getDeparments.filter((u:AppraisalDocument)=>previousPeriods.findIndex((p:IEvaluationDynamicDocument)=>p.appraisalDocument._id === u._id) === -1);
        setListOfDeparments(filteredDepartments)

      }else{
        try {
          const getDeparments:AppraisalDocument[] =  await fetchAPI("/evaluationcmds/get-my-appraisal-documents", FetchMethods.GET);
          if(getDeparments.hasOwnProperty("error")) throw new Error("Departments could not be loaded")
  
          setListOfDeparments(getDeparments)
  
        } catch (error:any) {
            if (error)dispatch(setError(error.message))
        }
      }
      
      
     
    })()
},[dispatch, previousPeriods]);
  
  return (
    <select onChange={({target})=>{
      const pseudoIndex = parseInt(target.value)

      if(pseudoIndex === -1) {
        selectedAppraisalDocument(emptyEvaluationDynamicDocument.appraisalDocument)
      }else{
        selectedAppraisalDocument(listOfDeparments[pseudoIndex])
      }
     
    }} className="form-control">
      <option className="form-control" value={-1}>---</option>
      {
       listOfDeparments.map((u:AppraisalDocument,i)=>(
          <option className="form-control" key={i} value={i}>{u.evaluationPeriod}</option>
        ))
      }
    </select>
  )
}

export default AppraisalDocumentSelection