
import { useEffect, useState } from 'react'
import { ICompetencies } from '../../../../interface'
const Competencies = ({competencies}:{competencies:{[key:string]:ICompetencies}}) => {
    const [comps, setComps] = useState<ICompetencies[]>([])
  useEffect(()=>{
    const tempArr:ICompetencies[] = [];
    for(let key in competencies){
      tempArr.push(competencies[key])
    }
    setComps(tempArr)
  },[competencies])
  return (
    <div style={{backgroundColor:"white"}}>
    {comps.length>0 &&
    comps.map((io:ICompetencies)=>(
      <div key={io.name} className='border-bottom p-1 m-1'>
        <div className="row">
          <div className="col">{io.name}</div>
            <div className="col">{io.evaluation} <p>{io.evaluationInput}</p></div>
        </div>
    </div>
    ))
    }
    </div>
  )
}

export default Competencies