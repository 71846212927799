import {useState, useEffect} from 'react'
import { fetchAPI } from '../utils/FetchApi';
import {FetchMethods} from "../interface/Enums/methods.enum"
import { useDispatch } from 'react-redux';
import { setError } from "../store/reducers/errorReducer";

interface CountryInterface {
    name: string;
    createdAt: Date;
    updatedAt: Date;
    _id: string;
  }
const CountryFilter = ({returnedCountry}:{returnedCountry:Function}) => {

const dispatch = useDispatch()

const [countries, setCountries] = useState<CountryInterface[]>([]);
const [selectedCountry, setSelectedCountry] = useState<string|null>(localStorage.getItem("selected-country")||null);

//Loads countries
useEffect(() => {
    (async () => {
      try {
        const countries: CountryInterface[] = await fetchAPI(
          "/admincmds/get-all-countries",
          FetchMethods.GET
        );
        countries.sort((a, b) => (a.name > b.name ? 1 : -1));
        setCountries(countries);

        const getCountryFromLocal:string|null = localStorage.getItem("selected-country");
        if(getCountryFromLocal){
          setSelectedCountry(getCountryFromLocal)
          returnedCountry(getCountryFromLocal)
        }
      } catch (error: any) {
        dispatch(setError(error.message.toString()));
      }
    })();
  }, [dispatch, selectedCountry, returnedCountry]);

  


  return (
    <>
     <h3 className="h3">Select country</h3>
        <select
          className="form-control"
          value={selectedCountry||-1}
          onChange={({ target }) => {
            localStorage.setItem("selected-country", target.value)
            setSelectedCountry(target.value);
          }}
        >
          <option className="form-control" value={-1}>
            ---
          </option>
          {countries.map((c: CountryInterface, i: number) => (
            <option key={i} value={c.name}>
              {c.name}
            </option>
          ))}
        </select>
    </>
  )
}

export default CountryFilter