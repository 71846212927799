import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import AdminContainer from "../../../../admin-components/AdminContainer";
import { useDispatch } from "react-redux";
import { setError } from "../../../../store/reducers/errorReducer";
import { IUser } from "../../../../interface/IUser.interface";
import { FetchMethods } from "../../../../interface/Enums/methods.enum";
import { fetchAPI } from "../../../../utils/FetchApi";
import {
  IAnnualEvaluationDocument,
  IError,
  IEvaluationDynamicDocument,
} from "../../../../interface";
import { Accordion, Badge } from "react-bootstrap";
import EvaluationSummaryComponent from "./ComponentsForEvaluation/EvaluationSummaryComponent";
import GoalsComponent from "./ComponentsForEvaluation/GoalsComponent";
import { IDynamicTableShape } from "../../../../interface/DynamicTableShape.interface";
import GenericTableComponent from "./ComponentsForEvaluation/GenericTableComponent";
import InterviewComponent from "./ComponentsForEvaluation/InterviewComponent";
import FeedbackAndObservationComponent from "./ComponentsForEvaluation/FeedbackAndObesrvationComponent";
import EvaluationDocumentMasterComponents from "../../AppraisalManagement/ColleageEvaluationComponents/EvaluationDocumentMasterComponents";
import AnnualEvaluationMasterDisplayComponent from "./ComponentsForEvaluation/AnnualEvaluationMasterDisplayComponent";

const CreateAnnualEvaluationForColleague = () => {
  const { id } = useParams();
  const dispatch = useDispatch();

  const [evaluatedUser, setEvaluatedUser] = useState<IUser>();
  const [listOfAllAnnualDocumentsOfUser, setListOfAllAnnualDocumentsOfUser] =
    useState<any>([]);
  const [selectedAnnualDocumentOfUser, setSelectedAnnualDocumentOfUser] = useState<any>()
  const [appraisalDocuments, setAppraisalDocuments] = useState<
    IEvaluationDynamicDocument[]
  >([]);
  const [selectedAppraisalDocument, setSelectedAppraisalDocument] =
    useState<IEvaluationDynamicDocument>();

  const [listOfAnnualDocuments, setListOfAnnualDocuments] = useState<
    IAnnualEvaluationDocument[]
  >([]);
  const [selectedAnnualDocument, setSelectedAnnualDocument] =
    useState<IAnnualEvaluationDocument>();

  const [finalAnnualEvaluationDocument, setfinalAnnualEvaluationDocument] =
    useState<any>();

  const applyEvaluationSummaryAnswer = (value: any) => {
    const modifiedObject = { ...finalAnnualEvaluationDocument };
    modifiedObject.evaluationSummary.answer = value;
    setfinalAnnualEvaluationDocument(modifiedObject);
  };

  const applyGoalsAnswers = (index: number, data: any) => {
    const modifiedObject = { ...finalAnnualEvaluationDocument };

      modifiedObject.goalsByManager[index].answer = data;

    setfinalAnnualEvaluationDocument(modifiedObject);
  };

  const applySummaryTable = (value: any) => {
    const modifiedObject = { ...finalAnnualEvaluationDocument };
    modifiedObject.summaryTable.answer = value;
    setfinalAnnualEvaluationDocument(modifiedObject);
  };

  const applyInterviewAnswers = (index: number, data: any) => {
    const modifiedObject = { ...finalAnnualEvaluationDocument };
    modifiedObject.interview[index].answer = data;
    setfinalAnnualEvaluationDocument(modifiedObject);
  };

  const applyFeedbackAndObservation = (index: number, data: any) => {
    const modifiedObject = { ...finalAnnualEvaluationDocument };
    modifiedObject.feedbackAndObesrvations[index].answer = data;
    setfinalAnnualEvaluationDocument(modifiedObject);
  };
  useEffect(() => {
    (async () => {
      try {
        const data: IAnnualEvaluationDocument[] & IError = await fetchAPI(
          "/evaluationcmds/annual-evaluation/get/all",
          FetchMethods.GET
        );
        if (data.error) {
          throw new Error(data.message?.toString());
        }
        setListOfAnnualDocuments(data);
      } catch (error: any) {
        dispatch(setError(error));
      }
    })();

    return () => {
      setListOfAnnualDocuments([]);
    };
  }, [dispatch]);

  useEffect(() => {
    (async () => {
      try {
        const data: IUser & IError = await fetchAPI(
          `/users/${id}`,
          FetchMethods.GET
        );
        const dataAppraisal: IEvaluationDynamicDocument[] & IError =
          await fetchAPI(
            `/admincmds/get-evaluation-document-by-specific-user/${id}`,
            FetchMethods.GET
          );

        const dataAnnualEvaluationDocuments: any & IError = await fetchAPI(
          `/evaluationcmds/annual-evaluations/user/${id}`,
          FetchMethods.GET
        );

    
        if (data.statusCode) {
          throw new Error(data.message?.toString());
        }

        if (dataAppraisal.statusCode) {
          throw new Error(data.message?.toString());
        }

        if (dataAnnualEvaluationDocuments.statusCode) {
          throw new Error(data.message?.toString());
        }

        setEvaluatedUser(data);
        setAppraisalDocuments(dataAppraisal);
        setListOfAllAnnualDocumentsOfUser(dataAnnualEvaluationDocuments);
      } catch (error: any) {
        dispatch(setError(error.toString()));
      }
    })();
    return () => {
      setEvaluatedUser(undefined);
    };
  }, [id, dispatch]);

  const saveTheDocumentToTheDatabase = async () => {
    const modifiedobject = { ...finalAnnualEvaluationDocument };
    modifiedobject.repNameQuery = evaluatedUser
      ? `${evaluatedUser.name} ${evaluatedUser.surname}`
      : undefined;
    modifiedobject.annualPeriodName =
      selectedAnnualDocument?.name +
      " " +
      `${evaluatedUser?.name} ${evaluatedUser?.surname}`;
    modifiedobject.user = evaluatedUser;
    try {
      const data: any & IError = await fetchAPI(
        "/evaluationcmds/annual-evaluations/user/create",
        FetchMethods.POST,
        modifiedobject
      );

      if (data.hasOwnProperty("error")) {
        throw new Error(data.toString());
      }
      if (data.statusCode >= 400) {
        throw new Error(JSON.stringify(data).toString());
      }

      setListOfAllAnnualDocumentsOfUser(data);

      dispatch(setError("The document was successfuly saved!"));
      setSelectedAnnualDocument(undefined);
    } catch (error: any) {
      dispatch(setError(error.toString()));
    }
  };

  return (
    <AdminContainer>
      <h1 className="h1">
        Evaluation of{" "}
        {evaluatedUser && (
          <strong style={{ color: "green" }}>
            {evaluatedUser.name} {evaluatedUser.surname}
          </strong>
        )}
      </h1>

      {evaluatedUser && (
        <Accordion className="border rounded">
          <Accordion.Header>User Detail</Accordion.Header>
          <Accordion.Body>
            {evaluatedUser.isUserApproved ? (
              <Badge className="bg-success">Approved</Badge>
            ) : (
              <Badge className="bg-warning">Disapproved</Badge>
            )}
            <br />
            {evaluatedUser.email}
            <br />
            {evaluatedUser.country}
            <br />
            {evaluatedUser.jobName?.name}
            <h5 className="h5 p-2 m-2">History of Appraisal documents</h5>
            <select
              onChange={({ target }) => {
                setSelectedAppraisalDocument(
                  appraisalDocuments[parseInt(target.value)]
                );
              }}
              className="form-control"
            >
              <option value={-1}>---</option>
              {appraisalDocuments &&
                appraisalDocuments.length > 0 &&
                appraisalDocuments.map(
                  (a: IEvaluationDynamicDocument, i: number) => (
                    <option key={a._id} value={i}>
                      {a.appraisalPeriod}
                    </option>
                  )
                )}
            </select>

            <h5 className="h5 p-2 m-2">
              History of Annual Evaluation Documents
            </h5>
            <select
              onChange={({ target }) => {
               setSelectedAnnualDocumentOfUser(
                  listOfAllAnnualDocumentsOfUser[parseInt(target.value)]
                );
              }}
              className="form-control"
            >
              <option value={-1}>---</option>
              {appraisalDocuments &&
                listOfAllAnnualDocumentsOfUser.length > 0 &&
                listOfAllAnnualDocumentsOfUser.map(
                  (a: any, i: number) => (
                    <option key={a._id} value={i}>
                      {a.annualPeriodName}
                    </option>
                  )
                )}
            </select>
          </Accordion.Body>
        </Accordion>
      )}

{
  (selectedAppraisalDocument || selectedAnnualDocumentOfUser) &&
  <div className="rounded" style={{position:"fixed", bottom:0, right:0, zIndex:300, backgroundColor:"#ffffffd1", padding:4}}>
{selectedAppraisalDocument && (
  <>
  <h5 className="h5">Appraisal Document</h5>
      <EvaluationDocumentMasterComponents
          evaldoc={selectedAppraisalDocument}
          position="relative"
          bgcolor="white"
          width={"30vw"}
        />
  </>
    
      )}

        {
          selectedAnnualDocumentOfUser && (
            <>
            <h5 className="h5">Annual Document</h5>
            <AnnualEvaluationMasterDisplayComponent 
            evaldoc={selectedAnnualDocumentOfUser}
            position="relative"
            bgcolor="white"
            width="30vw"
            />
            </>
            
          )
        }
  </div>
}
      

      <hr className="hr p-2 m-2" />
      <h5>Select the annual evaluation document</h5>
      {listOfAnnualDocuments.length > 0 && (
        <select
          className="form-control"
          onChange={({ target }) => {
            setSelectedAnnualDocument(
              listOfAnnualDocuments[parseInt(target.value)]
            );
            //@ts-ignore
            setfinalAnnualEvaluationDocument(
              listOfAnnualDocuments[parseInt(target.value)]
            );
          }}
         
        >
          <option className="form-control" value={-1}>
            ---
          </option>
          {listOfAnnualDocuments.map(
            (l: IAnnualEvaluationDocument, i: number) => (
              <option className="from-control" key={i} value={i}>
                {l.name}
              </option>
            )
          )}
        </select>
      )}

      {selectedAnnualDocument && (
        <div className="border rounded mt-4 p-2">
          <h5 className="h5 mt-2">{selectedAnnualDocument.name}</h5>
          <p>{selectedAnnualDocument.description}</p>

          <Accordion
            className="border rounded p-2 m-2"
          >
            <Accordion.Header>
              1. {selectedAnnualDocument.evaluationSummary.name}
            </Accordion.Header>
            <Accordion.Body>
              <p> {selectedAnnualDocument.evaluationSummary.description}</p>
              <EvaluationSummaryComponent
                selectedAnnualDocument={selectedAnnualDocument}
                setter={applyEvaluationSummaryAnswer}
              />
            </Accordion.Body>
          </Accordion>
          <Accordion
            className="border rounded p-2 m-2"
          >
            <Accordion.Header>2. Goals</Accordion.Header>
            <Accordion.Body>
              {selectedAnnualDocument.goalsByManager.length > 0 &&
                selectedAnnualDocument.goalsByManager.map(
                  (g: IDynamicTableShape, i: number) => {
                    return (
                      <Accordion
                        key={i}
                        className="border rounded mb-2"
                      
                      >
                        <Accordion.Header>
                          {i + 1}. {g.name}
                        </Accordion.Header>
                        <Accordion.Body>
                          <GoalsComponent
                            goal={g}
                            setter={applyGoalsAnswers}
                            specificindex={i}
                          />
                        </Accordion.Body>
                      </Accordion>
                    );
                  }
                )}
            </Accordion.Body>
          </Accordion>
          {selectedAnnualDocument?.summaryTable && (
            <GenericTableComponent
              table={selectedAnnualDocument?.summaryTable}
              setter={applySummaryTable}
            />
          )}

          {selectedAnnualDocument?.interview.length > 0 && (
            <Accordion className="border rounded p-2 m-2">
              <Accordion.Header>4. Interview questions</Accordion.Header>
              <Accordion.Body>
                {selectedAnnualDocument.interview.map(
                  (int: IDynamicTableShape, i: number) => (
                    <InterviewComponent
                      key={i}
                      interview={int}
                      setter={applyInterviewAnswers}
                      specificindex={i}
                    />
                  )
                )}
              </Accordion.Body>
            </Accordion>
          )}
          {selectedAnnualDocument?.feedbackAndObesrvations.length > 0 && (
            <Accordion className="border rounded p-2 m-2">
              <Accordion.Header>4. Feedback and Observations</Accordion.Header>
              <Accordion.Body>
                {selectedAnnualDocument.feedbackAndObesrvations.map(
                  (int: IDynamicTableShape, i: number) => (
                    <FeedbackAndObservationComponent
                      key={i}
                      feedback={int}
                      setter={applyFeedbackAndObservation}
                      specificindex={i}
                    />
                  )
                )}
              </Accordion.Body>
            </Accordion>
          )}

          <div
            onClick={saveTheDocumentToTheDatabase}
            className="btn btn-primary m-2"
          >
           Save the evaluation for {evaluatedUser?.name}{" "}
            {evaluatedUser?.surname}
          </div>
        </div>
      )}
    </AdminContainer>
  );
};

export default CreateAnnualEvaluationForColleague;
