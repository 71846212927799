import {useState} from 'react'
import { Accordion } from 'react-bootstrap';
import { BsSafe2Fill } from 'react-icons/bs';

type Table = {
    cell1: string;
    cell2: string;
    cell3: string;
    cell4: string;
    cell5: string;
    cell6: string;
    cell7: string;
    cell8: string;
    cell9: string;
  };
  
  type TableData = {
    name: string;
    country: string;
    description: string;
    columns: Table[];
    _id: string;
    created_at: string;
    updated_at: string;
  
  }
const GenericTableComponent = ({table, setter}:{table:TableData, setter:Function}) => {
    const [makeItGreen, setMakeItGreen] = useState<boolean>(false)
    const [cell, setCell] = useState({
        cell4:"",
        cell5:"",
        cell6:"",
        cell7:"",
        cell8:"",
        cell9:"",
    })

    const selectCheckbox = (s:string) =>{
        let obj = {
        cell4:"",
        cell5:"",
        cell6:"",
        cell7:"",
        cell8:"",
        cell9:"",
        }
//@ts-ignore
        obj[s] = "X";
        setCell(obj);
    }
  return (
    <Accordion className="border rounded p-2 m-2" >
        <Accordion.Header>
           3. {table.name}
        </Accordion.Header>
        <Accordion.Body>
            <p>
                {table.description}
            </p>
           <table className="table table-bordered" style={makeItGreen?{backgroundColor:"#89d189"}:{backgroundColor:"transparent"}}>
            <tbody>
                <tr>
                    <th colSpan={2}>{table.columns[0].cell1}</th>
                    <th colSpan={2}>{table.columns[0].cell2}</th>
                    <th colSpan={2}>{table.columns[0].cell3}</th>
                </tr>
                <tr>
                    <td>{table.columns[0].cell4}</td>
                    <td>{table.columns[0].cell5}</td>
                    <td>{table.columns[0].cell6}</td>
                    <td>{table.columns[0].cell7}</td>
                    <td>{table.columns[0].cell8}</td>
                    <td>{table.columns[0].cell9}</td>
                </tr>
                <tr>
                    <td><input className='form-check-input text-center' type="radio" name="selection" 
                    onChange={({target})=>{
                            selectCheckbox("cell4")
                    }}
                    value={cell.cell4} /></td>
                    <td><input className='form-check-input text-center' type="radio" name="selection" 
                    onChange={({target})=>{
                            selectCheckbox("cell5")
                    }}
                    value={cell.cell5} /></td>
                    <td><input className='form-check-input text-center' type="radio" name="selection" 
                    onChange={({target})=>{
                            selectCheckbox("cell6")
                    }}
                    value={cell.cell6} /></td>
                    <td><input className='form-check-input text-center' type="radio" name="selection" 
                    onChange={({target})=>{
                            selectCheckbox("cell7")
                    }}
                    value={cell.cell7} /></td>
                    <td><input className='form-check-input text-center' type="radio" name="selection" 
                    onChange={({target})=>{
                            selectCheckbox("cell8")
                    }}
                    value={cell.cell8} /></td>
                    <td><input className='form-check-input text-center' type="radio" name="selection" 
                    onChange={({target})=>{
                            selectCheckbox("cell9")
                    }}
                    value={cell.cell9} /></td>
                </tr>

            </tbody>
           </table>
           <div
                className="btn btn-success m-2"
                onClick={() => {
                    setMakeItGreen(true);  
                    setter(cell)
                }}
              >
     
                <BsSafe2Fill /> Save
              </div>
        </Accordion.Body>
    </Accordion>
  )
}

export default GenericTableComponent