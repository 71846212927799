import React, { useEffect, useState } from "react";
import { IDepartment } from "../interface";
import { fetchAPI } from "../utils/FetchApi";
import { FetchMethods } from "../interface/Enums/methods.enum";
import {
  UserInterface,
  IEvaluationDynamicDocument,
  IError,
} from "../interface";
import { useDispatch } from "react-redux";
import { setError } from "../store/reducers/errorReducer";


interface Props {
  department?: IDepartment;
  master:string;
}

interface ITeamStatistics extends UserInterface {
  evaluationDocuments: IEvaluationDynamicDocument[];
}

const TeamAssessmentDetailComponent: React.FC<Props> = ({
  master,
  department,
}) => {
  const [teamStatistics, setTeamStatistics] = useState<ITeamStatistics[]>([]);

  const [selectedPeriod, setSelectedPeriod] = useState<string | undefined>();

  const [uniqueAppraisalPeriods, setUniqueAppraisalPeriods] = useState<
    string[]
  >([]);

  const [teamStatisticsBySelectedPeriod, setteamStatisticsBySelectedPeriod] =
    useState<IEvaluationDynamicDocument[]>([]);

  const dispatch = useDispatch();
  useEffect(() => {
    (async () => {
      try {
        let data: ITeamStatistics[] & IError;

        if(department?._id !== "all"){
          data = await fetchAPI(
            "/admincmds/team/statistics",
            FetchMethods.POST,
            { master, department }
          );
  
          if (data.statusCode) {
            setTeamStatistics([]);
            //@ts-ignore
            throw new Error(data.message.toString());
          }
          setTeamStatistics(data);
        }else{
          data = await fetchAPI(`/admincmds/team/statistics/${master}`, FetchMethods.GET)
         
  
          if (data.statusCode) {
            setTeamStatistics([]);
            //@ts-ignore
            throw new Error(data.message.toString());
          }
          setTeamStatistics(data);
        }

        

        //extracts the unique appraisal periods from data

        const uniqueAppraisalPeriods = data
          .map((teamMember: ITeamStatistics) => {
            return teamMember.evaluationDocuments.map(
              (evaluationDocument: IEvaluationDynamicDocument) => {
                return evaluationDocument.appraisalPeriod;
              }
            );
          })
          .flat()
          .filter(
            (appraisalPeriod: string, index: number, self: string[]) =>
              self.indexOf(appraisalPeriod) === index
          );
        setUniqueAppraisalPeriods(
          sortColumnsByYearAndHalf(uniqueAppraisalPeriods)
        );
        setSelectedPeriod(undefined);
      } catch (error: any) {
        dispatch(setError(error.message));
      }
    })();
  }, [master, department, dispatch]);

  useEffect(() => {
    //From teamStatistics extract the evaluation documents that match the selected period
    const selectedPeriodEvaluationDocuments = teamStatistics
      .map((teamMember: ITeamStatistics) => {
        return teamMember.evaluationDocuments.filter(
          (evaluationDocument: IEvaluationDynamicDocument) => {
            return evaluationDocument.appraisalPeriod === selectedPeriod;
          }
        );
      })
      .flat();
    setteamStatisticsBySelectedPeriod(selectedPeriodEvaluationDocuments);
  }, [uniqueAppraisalPeriods, teamStatistics, selectedPeriod]);
  return (
    <div style={{ fontSize: 12 }} className="p-2">
      {department && (
        <div className="row p-5 border rounded shadow">
          <h3 className="h3">Period Selection</h3>
          <select
            className="form-control"
            onChange={(e) => {
              setSelectedPeriod(e.target.value);
            }}
          >
            <option defaultValue={-1} value={-1} className="form-control">
              ---
            </option>
            {uniqueAppraisalPeriods.map(
              (appraisalPeriod: string, index: number) => {
                return (
                  <option
                    key={index}
                    value={appraisalPeriod}
                    className="form-control"
                  >
                    {appraisalPeriod}
                  </option>
                );
              }
            )}
          </select>
        </div>
      )}

      <hr className="hr" />
      {selectedPeriod && (
        <>
          <div className="row p-5 border rounded shadow">
            <table className="table table-bordered">
              <thead>
                <tr>
                  <th scope="col">Nr.</th>
                  <th scope="col">Name of employee</th>
                  <th scope="col">Current position</th>
                  <th scope="col">Held since</th>
                  <th scope="col">Employed in RG since</th>
                  <th scope="col">Inputs</th>
                  <th scope="col">Energizers</th>
                  <th scope="col">Outputs</th>
                  <th scope="col">Competencies</th>
                </tr>
              </thead>
              <tbody>
                {teamStatisticsBySelectedPeriod.map(
                  (
                    evaluationDocument: IEvaluationDynamicDocument,
                    index: number
                  ) => {
                    return (
                      <tr>
                        <th scope="row">{index + 1}</th>
                        <td>{evaluationDocument.repNameQuery}</td>
                       <td>{evaluationDocument.selectedRep.jobName && evaluationDocument.selectedRep.jobName.name}</td>
                        <td>{evaluationDocument.selectedRep.currentPositionHeldSince && new Date(evaluationDocument.selectedRep.currentPositionHeldSince).toLocaleDateString()}</td>
                        <td>{evaluationDocument.selectedRep.startDateOfEmployment && new Date(evaluationDocument.selectedRep.startDateOfEmployment).toLocaleDateString()}</td>
                        <td>{evaluationDocument.overallInputLetter}</td>
                        <td>{evaluationDocument.energizerInputLetter}</td>
                        <td>{evaluationDocument.outputStandardLetter}</td>
                        <td>{evaluationDocument.competenciesLetter}</td>
                      </tr>
                    );
                  }
                )}
              </tbody>
            </table>
          </div>
        </>
      )}
    </div>
  );
};

export default TeamAssessmentDetailComponent;

function sortColumnsByYearAndHalf(columns: string[]): string[] {
  // Create a custom sorting function
  const customSort = (a: string, b: string): number => {
    // Extract the year and half from the column names
    const yearA = parseInt(a.split("/")[1]);
    const yearB = parseInt(b.split("/")[1]);
    const halfA = a.includes("H1") ? 1 : 2;
    const halfB = b.includes("H1") ? 1 : 2;

    // Sort by year first
    if (yearA !== yearB) {
      return yearA - yearB;
    }
    // Sort by half (H1 before H2)
    else if (halfA !== halfB) {
      return halfA - halfB;
    }
    // If year and half are the same, compare the full names
    else {
      return a.localeCompare(b);
    }
  };

  //Create a function that sums two numbers

  // Sort the columns using the custom sorting function
  return columns.sort(customSort);
}
