import React,{useCallback, useEffect, useState} from 'react'
import { IEvaluationDynamicDocument } from '../../interface'
import { Bubble } from 'react-chartjs-2';
import {
    Chart as ChartJS,
  LinearScale,
  PointElement,
  Tooltip,
  Legend,
  } from 'chart.js';


  ChartJS.register(
    LinearScale, PointElement, Tooltip, Legend
  );

  interface ChartData {
    x:number, y:number, r:number, 
  }

  interface CustomDataSet {
    label: string
    data: ChartData[],
    backgroundColor: string,
  }
const InputOutputChart = ({EvalDynamicDocument}:{EvalDynamicDocument:IEvaluationDynamicDocument[]}) => {

const [customDataSet, setCustomDataSet] = useState<CustomDataSet[]>([])

 const getInputOutputNumbers = useCallback(()=>{
    EvalDynamicDocument.forEach((e,i)=>{
        const schema:ChartData = {
            y:0,
            x:0,
            r:10,
       
        }
       
        const inputLetter = e.overallInputLetter;
        const outputLetter = e.outputStandardLetter;
        if(inputLetter === "A"){
            schema.x = 6;
        }else if(inputLetter === "B"){
            schema.x = 5;
        }
        else if(inputLetter === "C"){
            schema.x = 4;
        }
        else if(inputLetter === "D"){
            schema.x = 3;
        }
        else if(inputLetter === "E"){
            schema.x = 2;
        }
        else if(inputLetter === "F"){
            schema.x = 1;
        }
        if(outputLetter === "A"){
            schema.y = 6;
        }else if(outputLetter === "B"){
            schema.y = 5;
        }
        else if(outputLetter === "C"){
            schema.y = 4;
        }
        else if(outputLetter === "D"){
            schema.y = 3;
        }
        else if(outputLetter === "E"){
            schema.y = 2;
        }
        else if(outputLetter === "F"){
            schema.y = 1;
        }

       setCustomDataSet((prev:CustomDataSet[])=>[...prev, {
        data:[schema],
        label:e.appraisalPeriod,
        backgroundColor:`rgb(255, 99, ${Math.floor(Math.random()*254)})`
       }])
    })
 },[EvalDynamicDocument])


 useEffect(()=>{
    getInputOutputNumbers()

    return ()=>{
        setCustomDataSet([])
    }
 },[getInputOutputNumbers])
  return (
    <div style={{maxWidth:"100%", margin:"0 auto"}}>

    <Bubble
   options={{
    
    scales: {
        y: {
            suggestedMin: 1,
            suggestedMax: 6,
            title:{
                text:"Output",
                display:true,
            }
        },
        x:{
            suggestedMin: 1,
            suggestedMax: 6,
            title:{
                text:"Inputs",
                display:true,
                
            }
        }
    },
   }}
    data={{
        datasets:customDataSet
        
        // [{
        //     label: "Inputs & Outputs",
        //     data: dataForChart,
        //     backgroundColor: 'rgba(255, 99, 132, 0.5)',
        // }
        // ] 
            
        
    }}/>
    </div>
  )
}

export default InputOutputChart