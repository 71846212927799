import React, { useEffect, useState} from 'react';
import AdminContainer from '../../admin-components/AdminContainer';
import CountryFilter from '../../components/CountryFilter';
import { IDepartment, IError, UserInterface} from '../../interface';
import TeamStatisticsDetailComponents from '../../components/TeamStatisticsDetailComponents';
import { IRatingDistribution } from '../../interface';
import DataDisplayTable from '../../components/TeamAverageComponents';
import { useSelector } from 'react-redux';
import { fetchAPI } from '../../utils/FetchApi';
import { FetchMethods } from '../../interface/Enums/methods.enum';
interface Props {
  name: string;
}

interface AggregatedNumbersInterface {
    [key: string]: {
        inputs: number,
        finalInputLetter: string,
        outputs: number,
        finalOutputLetter: string,
        energizers: number,
        finalEnergizerLetter: string,
        competencies: number,
        finalCompetencyLetter: string
    }
}
interface AggregatedDataInterface {
    [key: string]: {
        inputs: string[],
        outputs: string[],
        energizers: string[],
        competencies: string[]
        ratingDistribution: IRatingDistribution
    }
}
interface GlobalScope {
    
    auth: {
        user: {
            _id: string,
            updatedAt: Date,
            createdAt: Date,
            lastLoggedIn: Date,
            isUserApproved: true,
            authLevel: string,
            country: string,
            surname: string,
            name: string,
            password: null,
            email:string,
            __v: number,
            master:any,
            refresh_token: string
        }
    }
}
const TeamStatistics: React.FC<Props> = ({ name }) => {
    //country picker
    const [country, setCountry] = useState<string>("")
    const [department, setDepartment] = useState<IDepartment>()
    const [listOfDepartments, setListOfDepartments] = useState<IDepartment[]>([])
    const [aggregatedData, setAggregatedData] = useState<AggregatedDataInterface>()
    const [averageData, setAverageData] = useState<any>()
    
    const CurrentUser:UserInterface = useSelector((d:GlobalScope):UserInterface=>{
        return d.auth.user;
    })
    
   //useEffect to console log aggregatedData changes

   useEffect(()=>{
    if(aggregatedData){
       const data =  turnLettersIntoNumbers(aggregatedData);
       setAverageData(data)
    }
   
}
,[aggregatedData])
    

useEffect(() => {
setCountry(CurrentUser.country)
}, [CurrentUser])

const [areaManagers, setAreaManagers] = useState<UserInterface[]>([]);
 useEffect(()=>{
   
    (async()=>{
        if(CurrentUser.authLevel === "omni-admin" && country.length > 0){
            try {
                const data:UserInterface[] & IError = await fetchAPI(`/evaluationcmds/area-managers/get/all/${country}`, FetchMethods.GET)
                if(data.statusCode){
                    setAreaManagers([]);
                    //@ts-ignore
                    throw new Error(data.message.toString())
                }
                setAreaManagers(data)
            } catch (error) {
                console.log(error)
            }
        }
        
    })()
 }, [country, CurrentUser.authLevel])

 const [selectedAreaManager, setSelectedAreaManager] = useState<string>("");
 
 const getDataByAreaManager = async (index:number)=>{
    setListOfDepartments([]);
    try {

        let user = areaManagers[index];
        const managersDepartments: IDepartment[] & IError = await fetchAPI(`/evaluationcmds/get/unique/departments/of/master`, FetchMethods.POST,{user});
        if(managersDepartments.statusCode ){
                setListOfDepartments([]);
                throw new Error(managersDepartments.message?.toString());
        }
        setListOfDepartments(managersDepartments);
        setSelectedAreaManager(user._id);
    } catch (error) {
       console.log(error) 
    }
 }

 useEffect(()=>{
    if(CurrentUser.authLevel === "area-manager"){
        (async()=>{
            try {
                const managersDepartments: IDepartment[] & IError = await fetchAPI(`/evaluationcmds/get/unique/departments/of/master`, FetchMethods.POST,{user:CurrentUser});
                if(managersDepartments.statusCode ){
                        setListOfDepartments([]);
                        throw new Error(managersDepartments.message?.toString());
                }
                setListOfDepartments(managersDepartments);
                setSelectedAreaManager(CurrentUser._id);
            } catch (error) {
               console.log(error) 
            }
        })()
       
    }
 }, [CurrentUser])
  return (
    <AdminContainer>
      <h1>{name}</h1>
    
      {
        (CurrentUser && CurrentUser.authLevel === "omni-admin") &&
        <div className="row">
    <div className="col border p-2 mx-2 shadow rounded">
    <CountryFilter returnedCountry={(country:string)=>{setCountry(country)}}/>
    </div>
    {
    //     (country.length > 0 && country) && (<div className="col border p-2 mx-2 shadow rounded">
    //         <h3 className='h3'>Select Department</h3>
    //     <DepartmentSelectionByCountry country={country} selectedDepartment={(dep:IDepartment)=>{setDepartment(dep)}}/>
    // </div>)
    (country.length > 0 && country) && (
        <div className="col border p-2 mx-2 shadow rounded">
            <h3 className="h3">Area managers</h3>
            <select onChange={({target})=>{
                getDataByAreaManager(parseInt(target.value));
            }}
            className='form-control'
            >
                <option className='form-control' value={-1}>---</option>
                {
                    areaManagers.map((areaManager:UserInterface, index:number)=>{
                        return <option className="form-control" key={index} value={index}>{areaManager.name} {areaManager.surname} - {areaManager.department?.name}
                        </option>
                    })
                }
            </select>
        </div>
    )
    } 
    <div className='col'>
        {
            listOfDepartments.length > 0 && <div className="col border p-2 mx-2 shadow rounded">
                <h3 className="h3">Select Department</h3>
                <select onChange={({target})=>{
                    if(parseInt(target.value) === -2){
                        setDepartment({
                            name: "All of the above",
                            _id: "all",
                            country: "all",
                            createdAt: new Date(),
                            updatedAt: new Date(),
                            description: "All of the above",
                            creator: "all",
                        })
                    }else{
                        setDepartment(listOfDepartments[parseInt(target.value)])
                    }
                    
                }}
                className='form-control'
                >
                    <option defaultValue={-1} className='form-control' value={-1}>---</option>
                    {
                        listOfDepartments.map((department:IDepartment, index:number)=>{
                            return <option className="form-control" key={index} value={index}>{department.name}
                            </option>
                        })
                    }
 {
                    listOfDepartments.length > 1 && 
                    <option value={-2}><span className='color-primary'>All of the above</span></option>
                }
                </select>
               
            </div>
        }
    </div>
</div>}
{/* 
SELECT DEP FOR AM
*/
CurrentUser.authLevel === "area-manager" &&
<div className='col my-5'>
{
    listOfDepartments.length > 0 && <div className="col border p-2 mx-2 shadow rounded">
        <h3 className="h3">Select Department</h3>
        <select onChange={({target})=>{
            if(parseInt(target.value) === -2){
                setDepartment({
                    name: "All of the above",
                    _id: "all",
                    country: "all",
                    createdAt: new Date(),
                    updatedAt: new Date(),
                    description: "All of the above",
                    creator: "all",
                })
            }else{
                setDepartment(listOfDepartments[parseInt(target.value)])
            }
            
        }}
        className='form-control'
        >
            <option defaultValue={-1} className='form-control' value={-1}>---</option>
            {
                listOfDepartments.map((department:IDepartment, index:number)=>{
                    return <option className="form-control" key={index} value={index}>{department.name}
                    </option>
                })
            }
{
            listOfDepartments.length > 1 && 
            <option value={-2}><span className='color-primary'>All of the above</span></option>
        }
        </select>
       
    </div>
}
</div>

}


{
    department && <div className="row">
    <div className="col">
        {
            ( selectedAreaManager.length > 0 && department) && 
       
        <TeamStatisticsDetailComponents department={department} dataRetriever={setAggregatedData} master={CurrentUser.authLevel === "omni-admin"?selectedAreaManager:CurrentUser._id}/>

        }
        
    </div>
</div>
}

{
    (averageData) && <div className="row">
    <div className="col">
        <h3 className="h3">Average Data</h3>
        <DataDisplayTable data={averageData}/>
       </div>  
</div>
}


      
    </AdminContainer>
  );
};

export default TeamStatistics;




function turnLettersIntoNumbers(aggregatedData: AggregatedDataInterface) {
    const letterToNumberMap = {
        A: 6,
        B: 5,
        C: 4,
        D: 3,
        E: 2,
        F: 1
    };

    const newAggregatedData: AggregatedNumbersInterface = {};

    for (let key in aggregatedData) {
        newAggregatedData[key] = {} as any;

        const calculateAverage = (letters: string[]) => {
            //@ts-ignore
            const numbers = letters.map(letter => letterToNumberMap[letter]);
            const sum = numbers.reduce((acc, curr) => acc + curr, 0);
            return sum / numbers.length;
        };

        const inputs = aggregatedData[key].inputs;
        newAggregatedData[key].inputs = calculateAverage(inputs);
        newAggregatedData[key].finalInputLetter = calculateLetter(newAggregatedData[key].inputs);

        const outputs = aggregatedData[key].outputs;
        newAggregatedData[key].outputs = calculateAverage(outputs);
        newAggregatedData[key].finalOutputLetter = calculateLetter(newAggregatedData[key].outputs);

        const energizers = aggregatedData[key].energizers;
        newAggregatedData[key].energizers = calculateAverage(energizers);
        newAggregatedData[key].finalEnergizerLetter = calculateLetter(newAggregatedData[key].energizers);

        const competencies = aggregatedData[key].competencies;
        newAggregatedData[key].competencies = calculateAverage(competencies);
        newAggregatedData[key].finalCompetencyLetter = calculateLetter(newAggregatedData[key].competencies);
    }

    return newAggregatedData;
}
 //@ts-ignore
function calculateLetter(value: number): string {
    if (value >= 5.55) {
        return "A";
    } else if (value >= 4.55 && value <= 5.54) {
        return "B";
    } else if (value >= 3.55 && value <= 4.54) {
        return "C";
    } else if (value >= 2.55 && value <= 3.54) {
        return "D";
    } else if (value >= 1.55 && value <= 2.54) {
        return "E";
    } else if (value >= 0 && value <= 1.54) {
        return "F";
    }
}
