import {useEffect, useState} from 'react'
import { useDispatch } from 'react-redux';
import { FetchMethods } from '../../../../interface/Enums/methods.enum';
import { IDynamicTableShape } from '../../../../interface/DynamicTableShape.interface';
import { setError } from '../../../../store/reducers/errorReducer';
import { fetchAPI } from '../../../../utils/FetchApi';
const EvaluationSummary = ({evalsummary}:{evalsummary:Function}) => {
    const dispatch = useDispatch()
    const [listOfDynamicTableShapes, setListOfDynamicTableShapes] = useState<IDynamicTableShape[]>([]);
    const [wasDataUpdated] = useState<boolean>(false);
    useEffect(()=>{
        (async()=>{
          try {
            const getData:IDynamicTableShape[] = await fetchAPI(`/evaluationcmds/annual-competency-development/get/all`,FetchMethods.GET);
            //@ts-ignore
            if(getData.hasOwnProperty("error"))throw new Error(getData.message);
            setListOfDynamicTableShapes(getData);
        } catch (error:any) {
            dispatch(setError(error.toString()));
        }
        })()
       return () =>{
        setListOfDynamicTableShapes([]);
       }
      },[dispatch, wasDataUpdated])

      const returnItem = (index:number) =>{
        evalsummary(listOfDynamicTableShapes[index]);
      }
  return (
  <select className='form-control m-2 p-2' onChange={
    (e:React.ChangeEvent<HTMLSelectElement>)=>{
        returnItem(Number(e.target.value));
        }
    
  }>
    <option className='form-control' value={-1}>---</option>
    {listOfDynamicTableShapes.map((item:IDynamicTableShape, index:number)=>{
        return <option className='form-control' key={index} value={index}>{item.name}
        </option>
    })}
  </select>
  )
}

export default EvaluationSummary