import {
  AppraisalDocument,
  IPerformanceMetricComments,
  IPerformanceMetricCompetencies,
  IPerformanceMetricInput,
  IPerformanceMetricOutput,
} from "../../../../interface";
import Accordition from "react-bootstrap/Accordion";
import Dropdown from "react-bootstrap/Dropdown";
import { BsThreeDotsVertical } from "react-icons/bs";
import { Badge } from "react-bootstrap";
const AppriasalDocumentComponent = ({
  docs,
  deldocs,
}: {
  docs: AppraisalDocument[] | undefined;
  deldocs: Function;
}) => {
  return (
    <div className="row p-2 m-2">
      {docs &&
        docs.map((d: AppraisalDocument, i: number) => (
          <div key={i} className="col-lg-6 col-md-2 col-sm-1">
            <div className="row">
              <div className="col-lg-1 m-2">
                <Dropdown>
                  <Dropdown.Toggle size="sm" variant="info">
                    <BsThreeDotsVertical />
                  </Dropdown.Toggle>
                  <Dropdown.Menu>
                    <Dropdown.Item
                      href="#/action-2"
                      onClick={() => {
                        deldocs(docs[i]._id);
                      }}
                    >
                      Delete
                    </Dropdown.Item>
                  </Dropdown.Menu>
                </Dropdown>
              </div>
              <div className="col-lg-10 m-2">
                <Accordition key={i} className="shadow">
                  <Accordition.Header>
                    <div className="row">
                      <div className="col-lg-12">
                        <h6 className="h6">
                          {d.evaluationPeriod} - {d.country}
                        </h6>
                      </div>
                    </div>
                  </Accordition.Header>
                  <Accordition.Body>
                    <div className="row p-2 m-2 text-center">
                      <strong>
                        {d.evaluationPeriod} - by{" "}
                        <span className="text-info">{d.creator}</span>
                      </strong>
                    </div>
                    <div className="row p-2 m-2 small">
                      <Accordition>
                        <Accordition.Header>
                          <strong>Inputs</strong>
                        </Accordition.Header>
                        <Accordition.Body>
                          {d.inputs &&
                            d.inputs.map(
                              (
                                { name, type }: IPerformanceMetricInput,
                                i: number
                              ) => {
                                return (
                                  <div
                                    key={i}
                                    className="p-2 m-1 d-flex justify-content-between border-bottom"
                                  >
                                    <span>{name}</span>{" "}
                                    <span>
                                      <Badge
                                        className={
                                          type === "standard"
                                            ? "bg-info"
                                            : "bg-warning"
                                        }
                                      >
                                        {type}
                                      </Badge>
                                    </span>
                                  </div>
                                );
                              }
                            )}
                        </Accordition.Body>
                      </Accordition>
                      <hr className="hr" />
                      <Accordition className="">
                        <Accordition.Header>
                          <strong>Outputs</strong>
                        </Accordition.Header>
                        <Accordition.Body>
                          {d.outputs &&
                            d.outputs.map(
                              (
                                { name }: IPerformanceMetricOutput,
                                i: number
                              ) => {
                                return (
                                  <div
                                    key={i}
                                    className="p-2  m-1 border-bottom"
                                  >
                                    {name}
                                  </div>
                                );
                              }
                            )}
                        </Accordition.Body>
                      </Accordition>
                      <hr className="hr" />
                      <Accordition className="">
                        <Accordition.Header>
                          <strong>Competencies</strong>
                        </Accordition.Header>
                        <Accordition.Body>
                          {d.competencies &&
                            d.competencies.map(
                              (
                                { name }: IPerformanceMetricCompetencies,
                                i: number
                              ) => {
                                return (
                                  <div
                                    key={i}
                                    className="p-2  m-1 border-bottom"
                                  >
                                    {name}
                                  </div>
                                );
                              }
                            )}
                        </Accordition.Body>
                      </Accordition>
                      <hr className="hr" />
                      <Accordition className="">
                        <Accordition.Header>
                          <strong>Comments</strong>
                        </Accordition.Header>
                        <Accordition.Body>
                          {d.comments &&
                            d.comments.map(
                              (
                                { name }: IPerformanceMetricComments,
                                i: number
                              ) => {
                                return (
                                  <div
                                    key={i}
                                    className="p-2  m-1 border-bottom"
                                  >
                                    {name}
                                  </div>
                                );
                              }
                            )}
                        </Accordition.Body>
                      </Accordition>
                    </div>
                    <div className="row text-center small italic">
                      <div className="col-lg-2 col-md-2 col-sm-2">Created</div>
                      <div className="col-lg-10 col-md-10 col-sm-10">
                        {d.createdAt?.toString()}
                      </div>
                    </div>
                  </Accordition.Body>
                </Accordition>
              </div>
            </div>
          </div>
        ))}
    </div>
  );
};

export default AppriasalDocumentComponent;
