import React,{useCallback, useEffect, useState} from 'react'
import { IEvaluationDynamicDocument } from '../../interface'
import { Bubble } from 'react-chartjs-2';
import {
    Chart as ChartJS,
  LinearScale,
  PointElement,
  Tooltip,
  Legend,
  } from 'chart.js';

  ChartJS.register(
    LinearScale, PointElement, Tooltip, Legend
  );
const InputOutputChart = ({EvalDynamicDocument}:{EvalDynamicDocument:IEvaluationDynamicDocument}) => {

 const [inputNumber, setInputNumber] = useState<number>(0)
 const [outputNumber, setOutputNumber] = useState<number>(0)


 const getInputOutputNumbers = useCallback(()=>{
    const inputLetter = EvalDynamicDocument.overallInputLetter;
    const outputLetter = EvalDynamicDocument.outputStandardLetter;
    if(inputLetter === "A"){
        setInputNumber(6)
    }else if(inputLetter === "B"){
        setInputNumber(5)
    }
    else if(inputLetter === "C"){
        setInputNumber(4)
    }
    else if(inputLetter === "D"){
        setInputNumber(3)
    }
    else if(inputLetter === "E"){
        setInputNumber(2)
    }
    else if(inputLetter === "F"){
        setInputNumber(1)
    }
    if(outputLetter === "A"){
        setOutputNumber(6)
    }else if(outputLetter === "B"){
        setOutputNumber(5)
    }
    else if(outputLetter === "C"){
        setOutputNumber(4)
    }
    else if(outputLetter === "D"){
        setOutputNumber(3)
    }
    else if(outputLetter === "E"){
        setOutputNumber(2)
    }
    else if(outputLetter === "F"){
        setOutputNumber(1)
    }
 },[EvalDynamicDocument.overallInputLetter, EvalDynamicDocument.outputStandardLetter])


 useEffect(()=>{
    getInputOutputNumbers()
 },[getInputOutputNumbers])
  return (
    <div style={{width:600, maxWidth:"100%", margin:"0 auto"}}>

    <Bubble
   options={{
    scales: {
        y: {
            suggestedMin: 1,
            suggestedMax: 6,
            title:{
                text:"Output",
                display:true,
            }
        },
        x:{
            suggestedMin: 1,
            suggestedMax: 6,
            title:{
                text:"Inputs",
                display:true,
                
            }
        }
    },
   }}
    data={{
        datasets:[{
            label: "Inputs & Outputs "+EvalDynamicDocument.selectedRep.name +" "+ EvalDynamicDocument.selectedRep.surname,
            data: [{
                x: inputNumber,
                y: outputNumber,
                r: 10,
            }],
            
            backgroundColor: 'rgba(255, 99, 132, 0.5)',
        }
        ] 
            
        
    }}/>
    </div>
  )
}

export default InputOutputChart