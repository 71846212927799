import { useEffect, useState } from "react";
import { Accordion } from "react-bootstrap";
import { useDispatch } from "react-redux";
import { FetchMethods } from "../../../../interface/Enums/methods.enum";
import { setError } from "../../../../store/reducers/errorReducer";
import { fetchAPI } from "../../../../utils/FetchApi";
import { IDynamicTableShape } from "../../../../interface/DynamicTableShape.interface";
type Table = {
  cell1: string;
  cell2: string;
  cell3: string;
  cell4: string;
  cell5: string;
  cell6: string;
  cell7: string;
  cell8: string;
  cell9: string;
};

type TableData = {
  name: string;
  country: string;
  description: string;
  columns: Table[];
  _id: string;
  created_at: string;
  updated_at: string;
};

type SaveShape = {
  name: string;
  country: string;
  description: string;
  evaluationSummary: IDynamicTableShape;
  goalsByManager: IDynamicTableShape[];
  interview: IDynamicTableShape[];
  summaryTable: TableData;
  feedbackAndObesrvations: IDynamicTableShape[];
};

const EvaluationDocuments = ({bool}:{bool:Boolean}) => {
  const dispatch = useDispatch();
  const [evaluationDocuments, setEvaluationDocuments] = useState<SaveShape[]>(
    []
  );
  const [wasDataUpdated, setWasDataUpdated] = useState<boolean>(false);

  useEffect(() => {
    (async () => {
      try {
        const getData: SaveShape[] = await fetchAPI(
          `/evaluationcmds/annual-evaluation/get/all`,
          FetchMethods.GET
        );
        //@ts-ignore
        if (getData.hasOwnProperty("error")) throw new Error(getData.message);
        setEvaluationDocuments(getData);
      } catch (error: any) {
        dispatch(setError(error.toString()));
      }
    })();
    return () => {
      setEvaluationDocuments([]);
    };
  }, [dispatch, wasDataUpdated, bool]);

  //delete document
  const deleteDocument = async (index: number) => {
    //ask user if they are sure they want to delete the document
    const isSure = window.confirm(
      "Are you sure you want to delete this document?"
    );
    if (!isSure) return;

    try {
      const getData: SaveShape[] = await fetchAPI(
        `/evaluationcmds/annual-evaluation/delete/`,
        FetchMethods.DELETE,
        evaluationDocuments[index]
      );
      //@ts-ignore
      if (getData.hasOwnProperty("error")) throw new Error(getData.message);

      dispatch(setError("Document deleted successfully"));

      setWasDataUpdated(!wasDataUpdated);
    } catch (error: any) {
      dispatch(setError(error.toString()));
    }
  };
  
  return (
    <div className="row">
      {evaluationDocuments.map((item: SaveShape, index: number) => {
        return (
          <Accordion key={index} className="rounded shadow p-2 m-2">
            <Accordion.Header>
              <div className="d-flex flex-row justify-content-between">
                  <p className="h5">{item.name}</p>
                  
              </div>
            </Accordion.Header>
            <Accordion.Body>
                
              <div className="row">
                <div className="col-12">
                  <h5 className="h5">Description</h5>
                  <p>{item.description}</p>
                </div>
              </div>

              <div className="row">
                <div className="col-12">
                  <h2 className="h2">Evaluation Summary: {item.name}</h2>
                  <br />
                  <br />
                  <br />
                  <h5 className="h5">Evaluation SummaryTable</h5>
                  <table className="table table-bordered">
                    <tbody>
                      <tr>
                        {item.evaluationSummary.columns.map((column, index) => {
                          return <td key={index}>{column}</td>;
                        })}
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>

              <div className="row">
                <div className="col-12">
                  <h5 className="h5">Future Goals</h5>
                  {item.goalsByManager.length > 0 && (
                    <div className="row shadow rounded p-4 m-4">
                      {item.goalsByManager.map(
                        (item: IDynamicTableShape, index: number) => {
                          return (
                            <div className="mb-4" key={index}>
                              <strong className="p-2 m-2">{item.name} </strong>
                              <table className="table table-bordered">
                                <tbody>
                                  <tr>
                                    {item.columns.map((column, index) => {
                                      return <td key={index}>{column}</td>;
                                    })}
                                  </tr>
                                </tbody>
                              </table>
                            </div>
                          );
                        }
                      )}
                    </div>
                  )}
                </div>
              </div>

              <div className="row">
                <h5 className="h5">Summary Table</h5>
                {item.summaryTable && (
                  <div className="row">
                    <div
                      className="p-4 m-4 border shadow rounded"
                      style={{ maxWidth: "100%", width: "95%" }}
                    >
                      <p>{item.summaryTable.description}</p>
                      <table className="table table-bordered">
                        <thead>
                          <tr>
                            <th scope="col" colSpan={2}>
                              {item.summaryTable.columns[0].cell1}
                            </th>
                            <th scope="col" colSpan={2}>
                              {item.summaryTable.columns[0].cell2}
                            </th>
                            <th scope="col" colSpan={2}>
                              {item.summaryTable.columns[0].cell3}
                            </th>
                          </tr>
                          <tr>
                            <th scope="col">
                              {item.summaryTable.columns[0].cell4}
                            </th>
                            <th scope="col">
                              {item.summaryTable.columns[0].cell5}
                            </th>
                            <th scope="col">
                              {item.summaryTable.columns[0].cell6}
                            </th>
                            <th scope="col">
                              {item.summaryTable.columns[0].cell7}
                            </th>
                            <th scope="col">
                              {item.summaryTable.columns[0].cell8}
                            </th>
                            <th scope="col">
                              {item.summaryTable.columns[0].cell9}
                            </th>
                          </tr>
                        </thead>
                      </table>
                    </div>
                  </div>
                )}
              </div>

              <h2 className="h2">2. Career interview</h2>
              <div className="row">
                <div className="col-12">
                  {item.interview.length > 0 && (
                    <div className="row shadow rounded p-4 m-4">
                      {item.interview.map(
                        (item: IDynamicTableShape, index: number) => {
                          return (
                            <div key={index}>
                              <div className="border shadow rounded p-2 m-2">
                                {item.name}
                              </div>
                            </div>
                          );
                        }
                      )}
                    </div>
                  )}
                </div>
              </div>

              <h2 className="h2">3. Feedback and observations</h2>

              <div className="row">
                <div className="col-12">
                  {item.feedbackAndObesrvations.length > 0 && (
                    <div className="row shadow rounded p-4 m-4">
                      {item.feedbackAndObesrvations.map(
                        (item: IDynamicTableShape, index: number) => {
                          return (
                            <div key={index}>
                              <div className="p-2 m-2">{item.name}</div>
                            </div>
                          );
                        }
                      )}
                    </div>
                  )}
                </div>
              </div>
              <div className="row">
                <button
                    className="btn btn-danger"
                    onClick={() => deleteDocument(index)}
                  >
                    Delete
                  </button>
                </div>
            </Accordion.Body>
          </Accordion>
        );
      })}
    </div>
  );
};

export default EvaluationDocuments;
