import { fetchAPI } from "../utils/FetchApi";
import { FetchMethods } from "../interface/Enums/methods.enum";
import {IPerformanceMetric, IPerformanceMetricInput, IJobname, IDepartment, IEvaluationPeriod,UserInterface, ICountry } from "../interface/"

export const getAllUsers = async ():Promise<UserInterface[]> =>{
   const users:UserInterface[] = await fetchAPI("/users/all", FetchMethods.GET);
   return users;
}
export const getAllEvaluationPeriods = async ():Promise<IEvaluationPeriod[]> =>{
   const evaluationPeriods:IEvaluationPeriod[] = await fetchAPI("/admincmds/get-all-evaluation-periods", FetchMethods.GET);
   return evaluationPeriods;
}

export const getAllDepartments = async ():Promise<IDepartment[]> =>{
   const Departments:IDepartment[] = await fetchAPI("/admincmds/get-all-departments", FetchMethods.GET);
   return Departments;
}
export const getAllJobnames = async ():Promise<IJobname[]> =>{
   const Jobnames:IJobname[] = await fetchAPI("/admincmds/get-all-jobnames", FetchMethods.GET);
   return Jobnames;
}
export const getAllCountries = async ():Promise<ICountry[]> =>{
   const Countries:ICountry[] = await fetchAPI("/admincmds/get-all-countries", FetchMethods.GET);
   return Countries;
}


export const getAllPerformanceMetrics = async (country:string,category:string):Promise<IPerformanceMetric[]> =>{

   const PerformanceMetrics:IPerformanceMetric[] = await fetchAPI("/admincmds/get-all-performance-metric", FetchMethods.GET);

   const filteredPerformanceMetrics = PerformanceMetrics.filter((f:IPerformanceMetric) => f.country === country && f.category === category)

   return filteredPerformanceMetrics;
}
//@ts-ignore
export const createInput = async (dto:IPerformanceMetricInput):Promise<IPerformanceMetricInput> => {
      const returnCreatedUser:IPerformanceMetricInput = await fetchAPI("/admincmds/create-performance-input-metric", FetchMethods.POST,dto);
      return returnCreatedUser
}
