import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom'
import { FetchMethods } from '../../../interface/Enums/methods.enum';
import { IError, IEvaluationDynamicDocument } from '../../../interface';
import { setError } from '../../../store/reducers/errorReducer';
import { fetchAPI } from '../../../utils/FetchApi';
import EvaluationDocumentMasterComponents from './ColleageEvaluationComponents/EvaluationDocumentMasterComponents';
import ManyToInputOutput from "../../../components/Charts/ManyToInputOutput.chart"
import ManyToCompetencies from "../../../components/Charts/ManyToCompetencies.chart"
import ManyToEnergizer from "../../../components/Charts/ManyToEnergizerAssesment.chart"
import { Accordion, Dropdown } from 'react-bootstrap';
import { BsThreeDotsVertical, BsTrashFill } from 'react-icons/bs';
const UserDetail = () => {
    //Load the logged user from the global state

    const authLevel = useSelector((state:any)=>state.auth.user.authLevel);
    

    const {id} = useParams();
    const navigate = useNavigate()
    const dispatch = useDispatch()
    const [evalDocs, setEvalDocs] = useState<IEvaluationDynamicDocument[]>([])
    const [reloadState, setReloadState] = useState<boolean>(false)
    useEffect(()=>{
        (async()=>{
            try {
                const data:IEvaluationDynamicDocument[] = await fetchAPI(`/admincmds/get-evaluation-document-by-specific-user/${id}`,FetchMethods.GET );
                if(data.hasOwnProperty("error")){
                    //@ts-ignore
                throw new Error(data.message.toString());
                }
 
                if(data.length>0){
                setEvalDocs(data);
               }

            } catch (error:any) {
               dispatch(setError(error.toString()))
            }   
        })()
return ()=>{
    setEvalDocs([])
}
    },[id, dispatch, reloadState])



    const deleteAppraisalEvaluation =  async (id:string)=> {
        //confirm the deletion
        if(!window.confirm("Are you sure you want to delete this evaluation?")) return;

        try {
            const data:IError = await fetchAPI(`/admincmds/delete-evaluation-document/${id}`,FetchMethods.DELETE );
            if(data.error){
                //@ts-ignore
            throw new Error(data.message.toString());
            }
            setReloadState(!reloadState)
        } catch (error:any) {
            dispatch(setError(error.toString()))
        }

    }
  return (
    <>

        <div className="row m-4">
        <div className="col">
           
            <div className="btn btn-warning m-2" onClick={()=>{
                 navigate(-1)
            }}>
                Go back
            </div>

        </div>
    </div>
    <div className="row">
        {evalDocs.length<1&&<p>
            This person does not have any evaluation yet!
            </p>}
    </div>
    
    <div className='row'>
        {evalDocs.length>0&&evalDocs.map((e:IEvaluationDynamicDocument, i:number)=>(
           <>
           {
                authLevel==="omni-admin"&&
                <div className="col-1">
           <Dropdown>
                    <Dropdown.Toggle size="sm" variant="info">
                      <BsThreeDotsVertical />
                    </Dropdown.Toggle>
                    <Dropdown.Menu>
                      <Dropdown.Item
                      
                        onClick={() => {
                            if(e._id)deleteAppraisalEvaluation(e._id);
                        }}
                      >
                        <BsTrashFill /> Delete this evaluation
                      </Dropdown.Item>
                    </Dropdown.Menu>
                  </Dropdown>
           </div>
           }
           
           <div className="col-11">

           <EvaluationDocumentMasterComponents width={"100%"} position='relative' key={i} evaldoc={e}/>
        
           </div>
            </>
           
       ))}

{
        evalDocs.length>0&&
        <Accordion className="row p-2 m-2 text-center mx-auto border rounded" style={{maxWidth:700}}>
        <Accordion.Header>
          Summary Charts
        </Accordion.Header>
        <Accordion.Body>
        <div className="row shadow rounded border p-1 m-1">
            <h5 className="h5">Inputs & Outputs</h5>
            <ManyToInputOutput EvalDynamicDocument={evalDocs}/>
            </div>
            <div className="row shadow rounded border p-1 m-1">
            <h5 className="h5">Competencies</h5>
            <ManyToCompetencies EvalDynamicDocument={evalDocs}/>
            </div>
            <div className="row shadow rounded border p-1 m-1">
            <h5 className="h5">Energizer</h5>
            <ManyToEnergizer EvalDynamicDocument={evalDocs}/>
            </div>
            </Accordion.Body>
    </Accordion>
    }

   
    </div>


    
    </>
  )
}

export default UserDetail