import { Table } from "react-bootstrap";

interface Props {
    data: any;
}
const DataDisplayTable:React.FC<Props> = ({ data }) => {
  // Extracting the keys from the data object
  const tableHeaders = Object.keys(data);

  return (
    <Table striped bordered responsive>
      <thead>
        <tr>

          {tableHeaders.map((header, index) => (
            <th key={index}>{header}</th>
          ))}
        </tr>
        {
            //For each header in the tableHeaders array, we will map through the data object and return the value of the key
        }
        <tr>
            {tableHeaders.map((header, index) => (
            
                    <td key={index}>
                        Inputs: {parseFloat(data[header].inputs).toFixed(2)} ({data[header].finalInputLetter}) <br />
                        Outputs: {parseFloat(data[header].outputs).toFixed(2)} ({data[header].finalOutputLetter}) <br />
                        Energizers: {parseFloat(data[header].energizers).toFixed(2)} ({data[header].finalEnergizerLetter}) <br />
                        Competencies: {parseFloat(data[header].competencies).toFixed(2)} ({data[header].finalCompetencyLetter}) <br />
                        </td>
         
            ))}
        </tr>
      </thead>
      <tbody>

 
      </tbody>
    </Table>
  );
};

export default DataDisplayTable;
