
import { useCallback, useEffect, useState } from 'react'
import { IEvaluationDynamicDocument } from '../../interface'
import { ICompetencies } from '../../interface'
import { Radar } from 'react-chartjs-2';
import {
    Chart as ChartJS,
    RadialLinearScale,
    PointElement,
    LineElement,
    Filler,
    Tooltip,
    Legend,
  } from 'chart.js';

  ChartJS.register(
    RadialLinearScale,
    PointElement,
    LineElement,
    Filler,
    Tooltip,
    Legend
  );

  interface DataSetInterface {
                    data: number[],
                    borderColor: string, //'rgba(255, 99, 132, 1)'
                    backgroundColor: string,//'rgba(255, 99, 132, 0.2)',
                    borderWidth: number,
                    label:string,
  }
const CompetenciesChart = ({EvalDynamicDocument}:{EvalDynamicDocument:IEvaluationDynamicDocument[]}) => {

const [getLabels, setGetLabels] = useState<string[]>([])
const [chartCompetencyValues, setChartCompetencyValues] = useState<number[]>([])
  const [CustomDataSet, setCustomDataSet] = useState<DataSetInterface[]>([])

    const manageCompetenciesData = useCallback(()=>{
        EvalDynamicDocument.forEach((e:IEvaluationDynamicDocument)=>{

        const competencies:{[key:string]:ICompetencies} = e.competencies;
        let tempArray:number[] = [];
        let tempLables:string[] = []
        if(Object.keys(competencies).length > 0){
            for(let key in competencies){
                tempLables.push(competencies[key].name)
                if(competencies[key].evaluation === "A"){
                    tempArray.push(6)
                }else if(competencies[key].evaluation === "B"){
                    tempArray.push(5)
                }
                else if(competencies[key].evaluation === "C"){
                    tempArray.push(4)
                }
                else if(competencies[key].evaluation === "D"){
                    tempArray.push(3)
                }
                else if(competencies[key].evaluation === "E"){
                    tempArray.push(2)
                }
                else if(competencies[key].evaluation === "F"){
                    tempArray.push(1)
                }
            }
        setGetLabels(tempLables)
        setChartCompetencyValues(tempArray)
        setCustomDataSet((prev:DataSetInterface[])=>[...prev, {
            label:e.appraisalPeriod,
            data:tempArray,
            borderColor:"white",
            borderWidth:1,
            backgroundColor:`rgba(${Math.floor(Math.random()*254)}, ${Math.floor(Math.random()*254)}, ${Math.floor(Math.random()*254)}, 0.4)`
        }])
        }
    })
    },[EvalDynamicDocument]);
    useEffect(()=>{
        manageCompetenciesData()
        return () => {
            setCustomDataSet([])
        }
        
    },[manageCompetenciesData])

  return (
   <div style={{width:"100%", maxWidth:"100%", margin:"0 auto"}}>
   {
    getLabels.length>0 &&<Radar
    data={
        {
            labels:getLabels,
            datasets:CustomDataSet
        }
    }
   options={{
    scales: {
        r: {
            angleLines: {
                display: false
            },
            suggestedMin: 1,
            suggestedMax: Math.max(...chartCompetencyValues)
        }
    }
   }}
/>
   }
   
   </div>
        
  )
}

export default CompetenciesChart