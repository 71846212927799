import { useState } from "react"
import { Accordion } from "react-bootstrap";
import { BsSafe2Fill } from "react-icons/bs";
import { IDynamicTableShape } from "../../../../../interface/DynamicTableShape.interface";

const InterviewComponent = ({interview, setter, specificindex}:{interview:IDynamicTableShape, setter:Function, specificindex:number}) => {
    const [answer, setAnswer] = useState<string>("");
    const [makeItGreen, setMakeItGreen] = useState(false)
  return (
    <Accordion className="border m-1" style={makeItGreen?{backgroundColor:"#89d189"}:{}}>
        <Accordion.Header style={makeItGreen?{backgroundColor:"#89d189"}:{}}>
            {interview.name}
        </Accordion.Header>
        <Accordion.Body>
           {interview.description}
            <textarea className="form-control" rows={3} value={answer} onChange={({target})=>{setAnswer(target.value)}}></textarea>
            <div
                className="btn btn-success m-2"
                onClick={() => {
                 setter(specificindex, answer)
                 if(answer.length>0)
                 {setMakeItGreen(true)}
                }}
              >
                <BsSafe2Fill /> Save
              </div>
        </Accordion.Body>
    </Accordion>
  )
}

export default InterviewComponent