import React from 'react'
import { Accordion } from 'react-bootstrap'

type EvalSummry = 
{name:string, country:string, description:string,notes:string, columns:string[], answer:[][]}
const EvaluationSummary = ({document}:{document:EvalSummry}) => {
  return (
   <Accordion className='border rounded m-1'>
    <Accordion.Header>
          {document.name}
    </Accordion.Header>
    <Accordion.Body>
        <p><strong>Description: </strong>{document.description}</p>
        <p><strong>Notes: </strong>{document.notes}</p>
<table className='table table-bordered rounded shadow small p-3'>
<tbody>
    <tr>
    {document.columns && document.columns.map((c:string,i:number)=>{
            return(
               <th key={i}>{c}</th>
            )
        })}
    </tr>
    {

        document.answer && document.answer.map((row:string[], index:number)=>{
            return <tr key={index} style={{backgroundColor:"#f0efef"}}>
              {row.map((f:string,k:number)=>{
                return <td key={k}>{f}</td>
              })}
            </tr>
        })
    }
</tbody>
</table>
        
    </Accordion.Body>
   </Accordion>
  )
}

export default EvaluationSummary