import { useDispatch } from "react-redux";
import { cleanError } from "../store/reducers/errorReducer"
const Error = ({ errorMessage }: { errorMessage: [] }) => {
    const dispatch = useDispatch();
    return (
        <div style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            position: "fixed",
            zIndex: 99999,
            bottom: 0,
            width: "100vw",
            height: "100vh",
            backgroundColor: "rgba(0,0,0,0.8)"
        }}
            onClick={() => {
                dispatch(cleanError());
            }}
        >
            <div className="padding" style={{
                backgroundColor: "white",
                padding:40
            }}>{errorMessage}</div>


        </div>
    );
};

export default Error;
