
import { useCallback, useEffect, useState } from 'react'
import { IEvaluationDynamicDocument} from '../../interface'
import {  IInputOutput } from '../../interface'
import { Radar } from 'react-chartjs-2';

import {
    Chart as ChartJS,
    RadialLinearScale,
    PointElement,
    LineElement,
    Filler,
    Tooltip,
    Legend,
  } from 'chart.js';

  ChartJS.register(
    RadialLinearScale,
    PointElement,
    LineElement,
    Filler,
    Tooltip,
    Legend
  );
const EnergizerAssesmentChart = ({EvalDynamicDocument}:{EvalDynamicDocument:IEvaluationDynamicDocument}) => {

const [getLabels, setGetLabels] = useState<string[]>([])
const [chartCompetencyValues, setChartCompetencyValues] = useState<number[]>([])
  
    const manageCompetenciesData = useCallback(()=>{
        const rating:any = EvalDynamicDocument.appraisalDocument.ratingDistribution;
        const inputs:{[key:string]:IInputOutput} = EvalDynamicDocument.inputsEnergizer;
        let tempArray:number[] = [];
        let tempLables:string[] = []
        if(Object.keys(inputs).length > 0){
            for(let key in inputs){
                tempLables.push(inputs[key].name)
               for(let aKey in rating){
                const fullfilment =  inputs[key].fulfillment;
                let {from, to, letter}: {from:number, to:number, letter:string} = rating[aKey]
                if(fullfilment<=from && fullfilment>= to){
                    if(letter === "A"){
                        tempArray.push(6)
                    }else if(letter === "B"){
                        tempArray.push(5)
                    }
                    else if(letter === "C"){
                        tempArray.push(4)
                    }
                    else if(letter === "D"){
                        tempArray.push(3)
                    }
                    else if(letter === "E"){
                        tempArray.push(2)
                    }
                    else if(letter === "F"){
                        tempArray.push(1)
                    }
                }
               }
            }
        setGetLabels(tempLables)
        setChartCompetencyValues(tempArray)
        }
    },[EvalDynamicDocument]);
    useEffect(()=>{
        manageCompetenciesData()
    },[manageCompetenciesData])

  return (
   <div style={{width:600, maxWidth:"100%", margin:"0 auto"}}>
   {
    getLabels.length>0 &&<Radar
    data={
        {
            labels:getLabels,
            datasets:[
                {
                    label: "Energizer - "+EvalDynamicDocument.appraisalDocument.inputName,
                    data: chartCompetencyValues,
                    borderColor: 'rgba(255, 99, 132, 1)',
                    backgroundColor: 'rgba(255, 99, 132, 0.2)',
                    borderWidth: 1,
                    
                }
            ]
        }
    }
   options={{
    scales: {
        r: {
            angleLines: {
                display: false
            },
            suggestedMin: 1,
            suggestedMax: 6
        }
    }
   }}
/>
   }
   
   </div>
        
  )
}

export default EnergizerAssesmentChart