
import { useCallback, useEffect, useState } from 'react'
import { IEvaluationDynamicDocument } from '../../interface'
import { ICompetencies } from '../../interface'
import { Radar } from 'react-chartjs-2';
import {
    Chart as ChartJS,
    RadialLinearScale,
    PointElement,
    LineElement,
    Filler,
    Tooltip,
    Legend,
  } from 'chart.js';

  ChartJS.register(
    RadialLinearScale,
    PointElement,
    LineElement,
    Filler,
    Tooltip,
    Legend
  );
const CompetenciesChart = ({EvalDynamicDocument}:{EvalDynamicDocument:IEvaluationDynamicDocument}) => {

const [getLabels, setGetLabels] = useState<string[]>([])
const [chartCompetencyValues, setChartCompetencyValues] = useState<number[]>([])
  
    const manageCompetenciesData = useCallback(()=>{
        const competencies:{[key:string]:ICompetencies} = EvalDynamicDocument.competencies;
        let tempArray:number[] = [];
        let tempLables:string[] = []
        if(Object.keys(competencies).length > 0){
            for(let key in competencies){
                tempLables.push(competencies[key].name)
                if(competencies[key].evaluation === "A"){
                    tempArray.push(6)
                }else if(competencies[key].evaluation === "B"){
                    tempArray.push(5)
                }
                else if(competencies[key].evaluation === "C"){
                    tempArray.push(4)
                }
                else if(competencies[key].evaluation === "D"){
                    tempArray.push(3)
                }
                else if(competencies[key].evaluation === "E"){
                    tempArray.push(2)
                }
                else if(competencies[key].evaluation === "F"){
                    tempArray.push(1)
                }
            }
        setGetLabels(tempLables)
        setChartCompetencyValues(tempArray)
        }
    },[EvalDynamicDocument.competencies]);
    useEffect(()=>{
        manageCompetenciesData()
    },[manageCompetenciesData])

  return (
   <div style={{width:600, maxWidth:"100%", margin:"0 auto"}}>
   {
    getLabels.length>0 &&<Radar
    data={
        {
            labels:getLabels,
            datasets:[
                {
                    label: EvalDynamicDocument.appraisalDocument.competenciesName,
                    data: chartCompetencyValues,
                    borderColor: 'rgba(255, 99, 132, 1)',
                    backgroundColor: 'rgba(255, 99, 132, 0.2)',
                    borderWidth: 1,
                    
                }
            ]
        }
    }
   options={{
    scales: {
        r: {
            angleLines: {
                display: false
            },
            suggestedMin: 1,
            suggestedMax: Math.max(...chartCompetencyValues)
        }
    }
   }}
/>
   }
   
   </div>
        
  )
}

export default CompetenciesChart