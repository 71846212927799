import { useState, useEffect } from "react";
import AdminContainer from "../../../admin-components/AdminContainer";
import { IEvaluationPeriod } from "../../../interface/IEvaluationPeriods";
import { useDispatch, useSelector } from "react-redux";
import { setError } from "../../../store/reducers/errorReducer";
import { UserInterface } from "../../../interface/UserInterface";
import { fetchAPI } from "../../../utils/FetchApi";
import { FetchMethods } from "../../../interface/Enums/methods.enum";
import Dropdown from "react-bootstrap/Dropdown";
import { BsThreeDotsVertical } from "react-icons/bs";
import Badge from "react-bootstrap/Badge";
import CountryFilter from "../../../components/CountryFilter";
import { ICountry } from "../../../interface/ICountry.interface";



const ManageEvaluationPeriods = () => {
  const dispatch = useDispatch();
  const data: UserInterface = useSelector((data: any) => {
    return data.auth.user;
  });
  const makeEmpty = {
    name: "",
    country: "",
    creator: data.name + " " + data.surname,
    expiration: new Date(),
    createdAt: new Date(),
  }
  const [EvaluationPeriods, setEvaluationPeriods] = useState<
    IEvaluationPeriod[]
  >([]);
  const [showAddEvaluationPeriod, setshowAddEvaluationPeriod] =
    useState<boolean>(false);
  const [EvaluationPeriod, setEvaluationPeriod] = useState<IEvaluationPeriod>({
    name: "",
    country: "",
    creator: data.name + " " + data.surname,
    expiration: new Date(),
    createdAt: new Date(),
  });
  const [countries, setCountries] = useState<ICountry[]>([]);
  const [makeChange, setMakeChange] = useState<boolean>(false);

  const [selectedCountry, setSelectedCountry] = useState<string>("---");
  const [isReadOnly, setIsReadOnly] = useState<boolean>(true);

  const createEvaluationPeriod = async () => {
    const { name, country, creator, expiration } = EvaluationPeriod;
    try {
      const data: IEvaluationPeriod = await fetchAPI(
        "/admincmds/create-evaluation-period",
        FetchMethods.POST,
        {
          name,
          country,
          creator,
          expiration: new Date(expiration),
        }
      );
      if (data.hasOwnProperty("error")) {
        throw new Error(data.message?.toString());
      }
      setEvaluationPeriod(makeEmpty)
      setMakeChange(!makeChange);
      setEvaluationPeriod({...EvaluationPeriod, name:generateRecommendedName()})
    } catch (error: any) {
      dispatch(setError(error.toString()));
    }
  };

  useEffect(() => {
    (async () => {
      try {
        const countries: ICountry[] = await fetchAPI("/admincmds/get-all-countries", FetchMethods.GET)
    countries.sort((a, b) => (a.name > b.name) ? 1 : -1)
    setCountries(countries);
        const data: IEvaluationPeriod[] | any = await fetchAPI(
          `/admincmds/get-all-evaluation-periods/${selectedCountry}`,
          FetchMethods.GET
        );
        if (data.hasOwnProperty("error")) {
          throw new Error(data.message?.toString());
        }
        setshowAddEvaluationPeriod(false);
        setEvaluationPeriods(data);
      } catch (error: any) {
        dispatch(setError(error.toString()));
      }
    })();
    return () => {
      setshowAddEvaluationPeriod(false);
    };
  }, [makeChange, selectedCountry, dispatch]);

  const deleteEvaluationPeriods = async (index: number) => {

    //confirm delete

    if(!window.confirm("Are you sure you want to delete this evaluation period?")) return;

    try {
      const EvalToBeDeleted: IEvaluationPeriod = await fetchAPI(
        `/admincmds/delete-evaluation-period/${EvaluationPeriods[index]._id}`,
        FetchMethods.GET
      );
      if (data.hasOwnProperty("error")) {
        throw new Error(EvalToBeDeleted.message?.toString());
      }
      setMakeChange(!makeChange);
    } catch (error: any) {
      dispatch(setError(error.toString()));
    }
  };


  useEffect(()=>{
       setEvaluationPeriod(e=>({
      ...e,
      creator:data.name + " "+ data.surname,
      name:generateRecommendedName()
    }))
  }, [data])
  const pickCountry = (country: string):void => {
    setSelectedCountry(country)
  };
  const generateRecommendedName = ():string =>{
  const date:Date = new Date();
  const mnth:number = date.getMonth()+1;

  return `H${mnth<7?1:2}/${date.getFullYear()}`
  }

  
  return (
    <AdminContainer>
      <div className="row">
        <h1 className="h1">Evaluation period management</h1>
      </div>
      <div className="row justify-content-between align-items-center">
        <div className="col-lg-8 m-2">
        <CountryFilter returnedCountry={pickCountry} />
        </div>
        <div className="col-lg-2 m-2 p-1">
        <button
              className="btn btn-danger"
              type="button"
              onClick={() => {
                setshowAddEvaluationPeriod(!showAddEvaluationPeriod)
              }}
            >
              Create new Evaluation Period
            </button>
        </div>
      </div>
      <hr className="hr" />
      <div className="row justify-content-md-center">
        {EvaluationPeriods.map((c: IEvaluationPeriod, i: number) => (
          <div className="card col-lg-3 col-md-4 cal-sm-2 m-2" key={i}>
            <div className="row">
              <div className="col-sm-2"> <Dropdown>
                <Dropdown.Toggle size="sm" variant="info">
                  <BsThreeDotsVertical />
                </Dropdown.Toggle>
                <Dropdown.Menu>
                  <Dropdown.Item
                    href="#/action-2"
                    onClick={() => {
                      deleteEvaluationPeriods(i);
                    }}
                  >
                    Delete
                  </Dropdown.Item>
                </Dropdown.Menu>
              </Dropdown></div>
              <div className="col-sm-10">

              <div className="card-body">
              <h5 className="card-title align-center">{c.name}</h5>
              <Badge bg="info">By: {c.creator}</Badge>
              <br />
              <Badge bg="secondary">Country scope: {c.country}</Badge>
              <br />
              <Badge bg="warning">
                Expires: {new Date(c.expiration).toLocaleDateString()}
              </Badge>
              <br />
            </div>
              </div>
             
            </div>
           
          </div>
        ))}
      </div>
      <form
        onSubmit={(e) => {
          e.preventDefault();
          createEvaluationPeriod();
        }}
      >
        <div className="row" style={{ marginTop: 100 }}>
          <div className="form-group">
          
            {showAddEvaluationPeriod && (
              <div className="card" style={{ padding: 30 }}>
                <h3>Evaluation Period Name</h3>
                <div className="row">
                  <div className="col-11"> <input
                  readOnly={isReadOnly}
                  className="form-control"
                  type="text"
                  value={EvaluationPeriod.name}
                  onChange={({ target }) => {
                    setEvaluationPeriod({
                      ...EvaluationPeriod,
                      name: target.value,
                    });
                  }}
                />
                <small style={{color:"grey"}}>Recommended name</small>
                </div>
                  <div className="col-1">
                    <div onClick={()=>{setIsReadOnly(!isReadOnly)}} className={`btn ${isReadOnly?"btn-warning":"btn-danger"}`}>{isReadOnly?"Edit":"Unedit"}</div>
                  </div>
                </div>
               
                <h3>Country</h3>
                <select
                  className="form-control"
                  onChange={({ target }) => {
                    setEvaluationPeriod({
                      ...EvaluationPeriod,
                      country: target.value,
                    });
                  }}
                >
                  <option className="form-control" value="---">
                    ---
                  </option>
                  {countries.map((c: ICountry, i: number) => (
                    <option key={i} value={c.name}>
                      {c.name}
                    </option>
                  ))}
                </select>
                <h3>Expiration date</h3>
                <input
                  className="form-control"
                  value={EvaluationPeriod.expiration
                    .toISOString()
                    .substring(0, 10)}
                  type="date"
                  onChange={(e: any) => {
                    setEvaluationPeriod({
                      ...EvaluationPeriod,
                      expiration: new Date(e.target.value),
                    });
                  }}
                  min={new Date().toISOString().substring(0, 10)}
                />
                <br />
                <input
                  className="form-control"
                  type="text"
                  readOnly
                  value={EvaluationPeriod.creator}
                  style={{ backgroundColor: "#d6d6d6" }}
                />
                <br />
                <button type="submit" className="btn btn-danger">
                  Create Evaluation Period
                </button>
              </div>
            )}
          </div>
        </div>
      </form>
    </AdminContainer>
  );
};

export default ManageEvaluationPeriods;
