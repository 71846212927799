import { Route, Routes } from "react-router-dom";
import { useSelector } from "react-redux";
/**
 * Admin components
 */

import ManageUsers from "./routes/AdminRoutes/ManageUsers";
import ManageCountries from "./routes/AdminRoutes/GlobalSetupRoutes/ManageCountries";
import ManageEvaluationPeriods from "./routes/AdminRoutes/GlobalSetupRoutes/ManageEvaluationPeriods";
import ManageJobname from "./routes/AdminRoutes/GlobalSetupRoutes/ManageJobnames";
import ManageDepartment from "./routes/AdminRoutes/GlobalSetupRoutes/ManageDepartments";
import ManagePerformanceInputMetrics from "./routes/AdminRoutes/AppraisalManagement/ManagePerformanceInputMetrics";
import ManagePerformanceOutputMetrics from "./routes/AdminRoutes/AppraisalManagement/ManagePerformanceOutputMetrics";
import ManagePerformanceCompetenciesMetric from "./routes/AdminRoutes/AppraisalManagement/ManagePerformanceCompetenciesMetric";
import ManagePerformanceCommentsMetric from "./routes/AdminRoutes/AppraisalManagement/ManagePerformanceCommentsMetric";

import EvaluationComposer from "./routes/AdminRoutes/AppraisalManagement/CreateAppraisal";
import EvaluateColleage from "./routes/AdminRoutes/AppraisalManagement/EvaluateColleage";

/**
 * New Annual Evaluation Summary
 */

import AnnualEvaluationSummary from "./routes/AdminRoutes/AnnualManagement/AnnualEvaluationSummary";
import AnnualEvaluationGoals from "./routes/AdminRoutes/AnnualManagement/AnnualEvalatuionGoals"
import AnnualEvaluationGenericTable from "./routes/AdminRoutes/AnnualManagement/AnnualEvaluationGenericTable";
import CreateAnnualEvaluationForColleague from "./routes/AdminRoutes/AnnualManagement/CreateAnnualEvaluationComponents/CreateAnnualEvaluationForColleague";
/**
 * Global Components
 */
import Login from "./routes/Login";
import Dashboard from "./routes/AfterLogin";
//Global Error
import ErrorComponent from "./components/Error";

import { GlobalWatcher } from "./utils/setAuthToken"
import 'bootstrap/dist/css/bootstrap.min.css';
import "./main.css"
import GlobalMenu from "./components/GlobalMenu"
import GlobalFooter from "./components/GlobalFooter";
import UserDetail from "./routes/AdminRoutes/AppraisalManagement/UserDetail";
import AnnualInterviewGoals from "./routes/AdminRoutes/AnnualManagement/AnnualInterviewCareerExpectations";
import AnnualObservationAndFeedback from "./routes/AdminRoutes/AnnualManagement/AnnualObservationAndFeedback";
import CreateAnnualEvaluation from "./routes/AdminRoutes/AnnualManagement/CreateAnnualEvaluation";
import UserDetailForAnnualEvaluation from "./routes/AdminRoutes/AnnualManagement/UserDetailForAnnualEvaluation";
import AppraisalColleagueEvaluation from "./routes/AdminRoutes/AppraisalManagement/AppraisalColleagueEvaluation";
import Updates from "./routes/Updates";
import TeamStatistics from "./routes/AdminRoutes/TeamStatistics";
import TeamAssesment from "./routes/AdminRoutes/TeamAssesment";
const App = () => {
  const data = useSelector((data: {
    error: {
      alerts: [],
      show: boolean
    }
  }) => {
    return data.error;
  })
  GlobalWatcher();


  return (
    <>
    {data.show && <ErrorComponent errorMessage={data.alerts} />}
    <div className="bg">
      <div className="container" >
        <GlobalMenu />
        <hr />
        
        <Routes>
          <Route path="/" element={<Login />} />
          <Route path="/login" element={<Login />} />
          <Route path="/dashboard" element={<Dashboard />} />
          <Route path="/admin-manage-users" element={<ManageUsers />} />
          <Route path="/admin-manage-countries" element={<ManageCountries />} />
          <Route path="/admin-manage-evaluation-periods" element={<ManageEvaluationPeriods />} />
          <Route path="/admin-manage-jobname" element={<ManageJobname />} />
          <Route path="/admin-manage-department" element={<ManageDepartment />} />
          <Route path="/admin-manage-performance-input-metrics" element={<ManagePerformanceInputMetrics />} />
          <Route path="/admin-manage-performance-output-metrics" element={<ManagePerformanceOutputMetrics />} />
          <Route path="/admin-manage-performance-competencies-metrics" element={<ManagePerformanceCompetenciesMetric />} />
          <Route path="/admin-manage-performance-comments-metrics" element={<ManagePerformanceCommentsMetric />} />
          <Route path="/admin-create-new-appraisal" element={<EvaluationComposer />} />
          <Route path="/admin-colleague-evaluation" element={<EvaluateColleage />} />
          <Route path="/admin-colleague-evaluation/:id" element={<AppraisalColleagueEvaluation />} />

          <Route path="/admin-annual-evaluation-summary" element={<AnnualEvaluationSummary />} />
          <Route path="/admin-annual-evaluation-goals" element={<AnnualEvaluationGoals />} />
          <Route path="/admin-annual-evaluation-generic-table" element={<AnnualEvaluationGenericTable />} />
          <Route path="/admin-annual-interview-career-expectations" element={<AnnualInterviewGoals />} />
          <Route path="/admin-annual-feedback-observations" element={<AnnualObservationAndFeedback />} />
          <Route path="/admin-create-new-annual-evaluation" element={<CreateAnnualEvaluation />} />
          <Route path="/admin-create-new-annual-summary/:id" element={<CreateAnnualEvaluationForColleague />} />
          <Route path="/user/detail/:id" element={<UserDetail />} />
          <Route path="/user/anual-evaluation-detail/:id" element={<UserDetailForAnnualEvaluation />} />

          <Route path="/admin/udpates" element={<Updates />} />

          <Route path="/admin/team-statistics" element={<TeamStatistics name="Team Statistics" />} />
          <Route path="/admin/team-assessment" element={<TeamAssesment />} />
          <Route path="/admin/team-assessment/:id" element={<TeamAssesment />} />

          
        </Routes>
      </div>
      <GlobalFooter />
    </div>
    </>
   
  )
}

export default App
