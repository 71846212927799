import React from 'react'
import { Accordion } from 'react-bootstrap';

type Table = {
    cell1: string;
    cell2: string;
    cell3: string;
    cell4: string;
    cell5: string;
    cell6: string;
    cell7: string;
    cell8: string;
    cell9: string;
  };
  
  type TableData = {
    name: string;
    country: string;
    description: string;
    columns: Table[];
    _id: string;
    created_at: string;
    updated_at: string;
    answer:Table;
  }

const SummaryTable = ({table}:{table:TableData}) => {
  return (
    <Accordion className="border rounded m-1">
        <Accordion.Header>{table.name}</Accordion.Header>
        <Accordion.Body>
          {(table.columns.length> 0 && table.answer)
          &&
          <table className="table table-bordered">
            <tbody>
                <tr>
                    <th colSpan={2}>{table.columns[0].cell1}</th>
                    <th colSpan={2}>{table.columns[0].cell2}</th>
                    <th colSpan={2}>{table.columns[0].cell3}</th>
                </tr>
                <tr>
                    <td>{table.columns[0].cell4}</td>
                    <td>{table.columns[0].cell5}</td>
                    <td>{table.columns[0].cell6}</td>
                    <td>{table.columns[0].cell7}</td>
                    <td>{table.columns[0].cell8}</td>
                    <td>{table.columns[0].cell9}</td>
                </tr>
                <tr className='text-center'>
                    <td>{table.answer.cell4}</td>
                    <td>{table.answer.cell5}</td>
                    <td>{table.answer.cell6}</td>
                    <td>{table.answer.cell7}</td>
                    <td>{table.answer.cell8}</td>
                    <td>{table.answer.cell9}</td>
                </tr>
            </tbody>
           </table>
          }
        
        </Accordion.Body>
    </Accordion>
    
  )
}

export default SummaryTable