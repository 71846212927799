import { useState, useEffect } from "react";
import { Accordion } from "react-bootstrap";
import { BsFillTrashFill } from "react-icons/bs";
import { useDispatch } from "react-redux";
import AdminContainer from "../../../admin-components/AdminContainer";
import CountryFilter from "../../../components/CountryFilter";
import { FetchMethods } from "../../../interface/Enums/methods.enum";
import { setError } from "../../../store/reducers/errorReducer";
import { fetchAPI } from "../../../utils/FetchApi";


type Table = {
  cell1: string;
  cell2: string;
  cell3: string;
  cell4: string;
  cell5: string;
  cell6: string;
  cell7: string;
  cell8: string;
  cell9: string;
};

type TableData = {
  name: string;
  country: string;
  description: string;
  columns: Table[];
  _id: string;
  created_at: string;
  updated_at: string;

}

const AnnualEvaluationGenericTable = () => {
  //controls a country
  const dispatch = useDispatch();
  const [showSummaryTable, setShowSummaryTable] = useState<boolean>(false);
  const [selectedCountry, setSelectedCountry] = useState<string>("---");
  const [uniqueNameForTable, setUniqueNameForTable] = useState<string>("");
  const [tableDescription, setTableDescription] = useState("");
  const [tableRowsAndColumns, setTableRowsAndColumns] = useState<Table>({
    cell1: "Exceeds expectations",
    cell2: "In line with expectations",
    cell3: "Need for improvement",
    cell4: "Exemplary performance",
    cell5: "Usually high performance",
    cell6: "Usually good performance",
    cell7: "Sufficient enough performance",
    cell8: "Not always sufficient performance",
    cell9: "Insufficient performance",
  });

  const [tables, setTables] = useState<TableData[]>([]);
const [change, setChange] = useState<boolean>(false)
  useEffect(() => {
    const loadTableData = async () => {
      try {
        const loadIt: TableData[] = await fetchAPI(
          `/evaluationcmds/annual-overall-summary/get/all`,
          FetchMethods.GET
        );
  
        if (loadIt.hasOwnProperty("error"))
          //@ts-ignore
          throw new Error(loadIt.message);
  
        setTables(loadIt);
      } catch (error: any) {
        dispatch(setError(error.toString()));
      }
    };
    loadTableData();
  }, [dispatch, change]);

  //load the data
  
//delete the data

  const deleteData = async (index: number) => {
    //confirm the delete
    if (!window.confirm("Are you sure you want to delete this data?")) return;
    try {
      const deleteData: TableData[] = await fetchAPI(
        `/evaluationcmds/annual-overall-summary/delete`,
        FetchMethods.DELETE,
        tables[index]
      );
      //@ts-ignore
      if (deleteData.hasOwnProperty("error"))
      //@ts-ignore
        throw new Error(deleteData.message);
      setChange(!change)
      dispatch(setError("The data was deleted successfully!"));
    } catch (error: any) {
      dispatch(setError(error.toString()));
    }
  };

  const pickCountry = (country: string) => {
    setSelectedCountry(country);
  };

  //load the data 

  const saveTableRowsAndColumns = async () => {
    //cell1 - cell9 are not empty
    try {
      if (uniqueNameForTable.length < 1)
        throw new Error("The name of the table is mandatory!");
      if (tableDescription.length < 1)
        throw new Error("The description of the table is mandatory!");
      if (tableRowsAndColumns.cell1.length < 1)
        throw new Error("Cell 1 is mandatory!");
      if (tableRowsAndColumns.cell2.length < 1)
        throw new Error("Cell 2 is mandatory!");
      if (tableRowsAndColumns.cell3.length < 1)
        throw new Error("Cell 3 is mandatory!");
      if (tableRowsAndColumns.cell4.length < 1)
        throw new Error("Cell 4 is mandatory!");
      if (tableRowsAndColumns.cell5.length < 1)
        throw new Error("Cell 5 is mandatory!");
      if (tableRowsAndColumns.cell6.length < 1)
        throw new Error("Cell 6 is mandatory!");
      if (tableRowsAndColumns.cell7.length < 1)
        throw new Error("Cell 7 is mandatory!");
      if (tableRowsAndColumns.cell8.length < 1)
        throw new Error("Cell 8 is mandatory!");
      if (tableRowsAndColumns.cell9.length < 1)
        throw new Error("Cell 9 is mandatory!");

      //save the data

      const saveIt: Table[] = await fetchAPI(
        "/evaluationcmds/annual-overall-summary/create",
        FetchMethods.POST,
        {
          name: uniqueNameForTable,
          country: selectedCountry,
          description: tableDescription,
          columns: [tableRowsAndColumns],
        }
      );

      //@ts-ignore
      if (saveIt.hasOwnProperty("error")) throw new Error(saveIt.message);

      //reset the state
      setUniqueNameForTable("");
      setTableDescription("");
      setTableRowsAndColumns({
        cell1: "Exceeds expectations",
        cell2: "In line with expectations",
        cell3: "Need for improvement",
        cell4: "Exemplary performance",
        cell5: "Usually high performance",
        cell6: "Usually good performance",
        cell7: "Sufficient enough performance",
        cell8: "Not always sufficient performance",
        cell9: "Insufficient performance",
      });

      //hide the table
      setShowSummaryTable(false);
      setChange(!change)
      //show a message
      dispatch(setError("The table was created successfully!"));
    } catch (err: any) {
      dispatch(setError(err.toString()));
    }
  };

  return (
    <AdminContainer>
      <div className="row">
        <h1 className="h1">The Summary Table</h1>
      </div>

      <div className="row justify-content-between align-items-center">
        <div className="col-lg-8 m-2">
          <CountryFilter returnedCountry={pickCountry} />
        </div>
        <div className="col-lg-2 m-2 p-1">
          <p>Create new summary table</p>
          <button
            className="btn btn-danger"
            type="button"
            onClick={() => {
              setShowSummaryTable(!showSummaryTable);
            }}
          >
            Create the table
          </button>
        </div>
      </div>

      <div className="row">
        {
          tables.map((table, index) => {
            return (
         
                    <Accordion key={index} className="border m-2 p-1">
                      <Accordion.Header>
                      {table.name}
                      </Accordion.Header>
                      <Accordion.Body>
                        <p className="card-text">{table.description}</p>
                       
                      
                        <table className="table table-bordered">
                          <thead>
                            <tr>
                              <th scope="col" colSpan={2}>{table.columns[0].cell1}</th>
                              <th scope="col" colSpan={2}>{table.columns[0].cell2}</th>
                              <th scope="col" colSpan={2}>{table.columns[0].cell3}</th>
                            </tr>
                          <tr>
                            <th scope="col">{table.columns[0].cell4}</th>
                            <th scope="col">{table.columns[0].cell5}</th>
                            <th scope="col">{table.columns[0].cell6}</th>
                            <th scope="col">{table.columns[0].cell7}</th>
                            <th scope="col">{table.columns[0].cell8}</th>
                            <th scope="col">{table.columns[0].cell9}</th>
                          </tr>
                          </thead>
                          </table>
                          <div className="btn btn-danger m-2" onClick={()=>{
                          deleteData(index)
                        }}><BsFillTrashFill /></div>
                      </Accordion.Body>
                    </Accordion>
              
            );
          })
        }
      </div>
     
     
      {showSummaryTable && (
        <>

           
             <br />
             <br />
             <br />
          <div className="form-row">
    <div className="form-group">
     <h4 className="h4">Unique table name</h4>
      <input
                type="text"
                className="form-control"
                value={uniqueNameForTable}
                onChange={({ target }) => {
                  setUniqueNameForTable(target.value);
                }}
              />
    </div>
    <br />
    <div className="form-group">
    <h4 className="h4">Table description</h4>
      <input
                type="text"
                className="form-control"
                value={tableDescription}
                onChange={({ target }) => {
                  setTableDescription(target.value);
                }}
              />
    </div>
  </div>

<br />
          <div className="row">
            <table className="table table-bordered text-center">
              <tbody>
                <tr>
                  <th colSpan={2}>
                    <input
                      className="form-control text-center"
                      type="text"
                      value={tableRowsAndColumns.cell1}
                      onChange={(e) => {
                        setTableRowsAndColumns({
                          ...tableRowsAndColumns,
                          cell1: e.target.value,
                        });
                      }}
                    />
                  </th>
                  <th colSpan={2}>
                    <input
                      className="form-control text-center"
                      type="text"
                      value={tableRowsAndColumns.cell2}
                      onChange={(e) => {
                        setTableRowsAndColumns({
                          ...tableRowsAndColumns,
                          cell2: e.target.value,
                        });
                      }}
                    />
                  </th>
                  <th colSpan={2}>
                    <input
                      className="form-control text-center"
                      type="text"
                      value={tableRowsAndColumns.cell3}
                      onChange={(e) => {
                        setTableRowsAndColumns({
                          ...tableRowsAndColumns,
                          cell3: e.target.value,
                        });
                      }}
                    />
                  </th>
                </tr>
                <tr>
                  <td>
                    <input
                      style={{ fontSize: 12 }}
                      className="form-control text-center small"
                      type="text"
                      value={tableRowsAndColumns.cell4}
                      onChange={(e) => {
                        setTableRowsAndColumns({
                          ...tableRowsAndColumns,
                          cell4: e.target.value,
                        });
                      }}
                    />
                  </td>
                  <td>
                    <input
                      style={{ fontSize: 12 }}
                      className="form-control text-center small"
                      type="text"
                      value={tableRowsAndColumns.cell5}
                      onChange={(e) => {
                        setTableRowsAndColumns({
                          ...tableRowsAndColumns,
                          cell5: e.target.value,
                        });
                      }}
                    />
                  </td>
                  <td>
                    <input
                      style={{ fontSize: 12 }}
                      className="form-control text-center"
                      type="text"
                      value={tableRowsAndColumns.cell6}
                      onChange={(e) => {
                        setTableRowsAndColumns({
                          ...tableRowsAndColumns,
                          cell6: e.target.value,
                        });
                      }}
                    />
                  </td>
                  <td>
                    <input
                      style={{ fontSize: 12 }}
                      className="form-control text-center"
                      type="text"
                      value={tableRowsAndColumns.cell7}
                      onChange={(e) => {
                        setTableRowsAndColumns({
                          ...tableRowsAndColumns,
                          cell7: e.target.value,
                        });
                      }}
                    />
                  </td>
                  <td>
                    <input
                      style={{ fontSize: 12 }}
                      className="form-control text-center"
                      type="text"
                      value={tableRowsAndColumns.cell8}
                      onChange={(e) => {
                        setTableRowsAndColumns({
                          ...tableRowsAndColumns,
                          cell8: e.target.value,
                        });
                      }}
                    />
                  </td>
                  <td>
                    <input
                      style={{ fontSize: 12 }}
                      className="form-control text-center"
                      type="text"
                      value={tableRowsAndColumns.cell9}
                      onChange={(e) => {
                        setTableRowsAndColumns({
                          ...tableRowsAndColumns,
                          cell9: e.target.value,
                        });
                      }}
                    />
                  </td>
                </tr>
              </tbody>
            </table>
            <div onClick={saveTableRowsAndColumns} className="btn btn-primary">
              Save
            </div>
          </div>
        </>
      )}
    </AdminContainer>
  );
};

export default AnnualEvaluationGenericTable;
