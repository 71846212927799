import {useEffect, useState} from 'react'
import { useDispatch } from 'react-redux';
import { FetchMethods } from '../../../../interface/Enums/methods.enum';
import { setError } from '../../../../store/reducers/errorReducer';
import { fetchAPI } from '../../../../utils/FetchApi';

type Table = {
    cell1: string;
    cell2: string;
    cell3: string;
    cell4: string;
    cell5: string;
    cell6: string;
    cell7: string;
    cell8: string;
    cell9: string;
  };
  
  type TableData = {
    name: string;
    country: string;
    description: string;
    columns: Table[];
    _id: string;
    created_at: string;
    updated_at: string;
  
  }
const SummaryTable = ({evalsummarytable}:{evalsummarytable:Function}) => {
    const dispatch = useDispatch()
    const [listOfDynamicTableShapes, setListOfDynamicTableShapes] = useState<TableData[]>([]);
    const [wasDataUpdated] = useState<boolean>(false);

    useEffect(()=>{
        (async()=>{
          try {
            const getData:TableData[] = await fetchAPI(`/evaluationcmds/annual-overall-summary/get/all`,FetchMethods.GET);
            //@ts-ignore
            if(getData.hasOwnProperty("error"))throw new Error(getData.message);
            setListOfDynamicTableShapes(getData);
        } catch (error:any) {
            dispatch(setError(error.toString()));
        }
        })()
       return () =>{
        setListOfDynamicTableShapes([]);
       }
      },[dispatch, wasDataUpdated])

      const returnItem = (index:number) =>{
        evalsummarytable(listOfDynamicTableShapes[index]);
      }
  return (
  <select className='form-control m-2 p-2' onChange={
    (e:React.ChangeEvent<HTMLSelectElement>)=>{
        returnItem(Number(e.target.value));
        }
    
  }>
    <option className='form-control' value={-1}>---</option>
    {listOfDynamicTableShapes.map((item:TableData, index:number)=>{
        return <option className='form-control' key={index} value={index}>{item.name}
        </option>
    })}
  </select>
  )
}

export default SummaryTable