import React, { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom'
import { FetchMethods } from '../../../interface/Enums/methods.enum';
import { setError } from '../../../store/reducers/errorReducer';
import { fetchAPI } from '../../../utils/FetchApi';
import AnnualEvaluationMasterDisplayComponent from './CreateAnnualEvaluationComponents/ComponentsForEvaluation/AnnualEvaluationMasterDisplayComponent';
const UserDetailForAnnualEvaluation = () => {
    const {id} = useParams();
    const navigate = useNavigate()
    const dispatch = useDispatch()
    const [evalDocs, setEvalDocs] = useState<any[]>([])
    useEffect(()=>{
        (async()=>{
            try {
                const data:any[] = await fetchAPI(`/evaluationcmds/annual-evaluations/user/${id}`,FetchMethods.GET );
                if(data.hasOwnProperty("error")){
                    //@ts-ignore
                throw new Error(data.message.toString());
                }
 
                if(data.length>0){
                setEvalDocs(data);
               }

            } catch (error:any) {
               dispatch(setError(error.toString()))
            }   
        })()
return ()=>{
    setEvalDocs([])
}
    },[id, dispatch])
  return (
    <>

        <div className="row m-4">
        <div className="col">
            <div className="btn btn-warning m-2" onClick={()=>{
                 navigate(-1)
            }}>
                Go back
            </div>

        </div>
    </div>
    <div className="row">
        {evalDocs.length<1&&<p>
            This person does not have any evaluation yet!
            </p>}
    </div>
    
    <div className='row'>
        {evalDocs.length>0&&evalDocs.map((e:any, i:number)=>(
           <>
           <AnnualEvaluationMasterDisplayComponent width={"100%"} position='relative' key={i} evaldoc={e}/>
          
           </>
            
        ))}
    </div>
    
    </>
  )
}

export default UserDetailForAnnualEvaluation