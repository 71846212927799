import {useState} from 'react'
import { IAnnualEvaluationDocument } from '../../../../../interface';
import { BsTrashFill, BsFillPlusCircleFill, BsSafe2Fill } from 'react-icons/bs';
const GoalsComponent = ({selectedAnnualDocument, setter}:{selectedAnnualDocument:IAnnualEvaluationDocument, setter:Function}) => {
  const [evaluationSummaryTable, setEvaluationSummaryTable] = useState<any[]>(
    []
   );
 const [makeItGreen, setmakeItGreen] = useState<boolean>(false)
 
  return (
    <>
     <table className="table table-bordered position-relative" style={makeItGreen?{backgroundColor:"#89d189"}:{backgroundColor:"transparent"}}>
                {
                  //@ts-ignore
                }{" "}
                <tbody>
                  <tr>
                    {selectedAnnualDocument.evaluationSummary.columns.map(
                      (column, index) => {
                        return (
                          <td key={index}>
                            <strong>{column}</strong>
                          </td>
                        );
                      }
                    )}
                  </tr>
                  {evaluationSummaryTable.map((t: [], i) => {
                    return (
                      <>
                        <tr className="position-relative" key={i}>
                          {t.map((c, x) => (
                            <td key={x}>
                              <input
                              key={x}
                                type="text"
                                className="form-control"
                                placeholder="empty value"
                                value={evaluationSummaryTable[i][x]}
                                onChange={({target})=>{
                                  const theValue = target.value;
                                  //method to manipulate this sh1t
                                  let clonedArray = [...evaluationSummaryTable]
                                  clonedArray[i][x] = theValue;
                                  setEvaluationSummaryTable(clonedArray);
                                }}
                              />
                            </td>
                          ))}
                          <div
                            onClick={() => {
                              const filtered = evaluationSummaryTable.filter(
                                (r, x) => x !== i
                              );
                              setEvaluationSummaryTable(filtered);
                            }}
                            className="btn small position-absolute"
                            style={{
                              top: 0,
                              right: 0,
                              marginRight: -30,
                              fontSize: 16,
                              marginTop: 5,
                              color:"red"
                            }}
                          >
                            <BsTrashFill />
                          </div>
                        </tr>
                      </>
                    );
                  })}
                </tbody>
              </table>

              <div
                className="btn btn-primary m-2"
                onClick={() => {
                  const arrayOfArray = new Array(
                    selectedAnnualDocument.evaluationSummary.columns.length
                  ).fill("");
                  const anotherRow = [...evaluationSummaryTable, arrayOfArray];
                  setEvaluationSummaryTable(anotherRow);
                }}
              >
                <BsFillPlusCircleFill /> Add row
              </div>
              <div
                className="btn btn-success m-2"
                onClick={() => {
                  if(evaluationSummaryTable.length>0){
                    setmakeItGreen(true)
                    setter(evaluationSummaryTable)
                  }
                }}
              >
                <BsSafe2Fill /> Save
              </div>
             
              <p className="mt-4">
                {selectedAnnualDocument.evaluationSummary.notes}
              </p></>
  )
}

export default GoalsComponent