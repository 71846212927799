import  { useEffect, useState } from 'react'
import { Accordion } from 'react-bootstrap';
import {  BsFillTrashFill } from 'react-icons/bs'
import { useDispatch } from 'react-redux';
import AdminContainer from '../../../admin-components/AdminContainer'
import CountryFilter from '../../../components/CountryFilter';
import { FetchMethods } from '../../../interface/Enums/methods.enum';
import { setError } from '../../../store/reducers/errorReducer';
import { fetchAPI } from '../../../utils/FetchApi';

type InterviewGoal = {
  name: string;
  description: string;
  country: string;
  createdAt: Date;
  updatedAt: Date;
}

const emptyObject: InterviewGoal = {
  name: "",
  description: "",
  country: "",
  createdAt: new Date(),
  updatedAt: new Date(),
};

const AnnualObservationAndFeedback = () => {
  const dispatch = useDispatch(); 
  const [showInterview, setShowInterview] = useState<boolean>(false);
 const [interview, setInterview] = useState<InterviewGoal>(emptyObject);
 const [updateChange, setUpdateChange] = useState<boolean>(false);

  //pick country
  const [selectedCountry, setSelectedCountry] = useState<string>("---");
  const pickCountry = (country: string) => {
    setSelectedCountry(country);
  };

const createNewInterview = async () => {
  try {

    //name and description can not be empty
    if (interview.name.length < 1 || interview.description.length < 1)
      throw new Error("Name and description are mandatory!");

    const newInterview: InterviewGoal = await fetchAPI(
      `/evaluationcmds/proposals-feedback/create`,
      FetchMethods.POST,
      {...interview, country: selectedCountry}
    );
    //@ts-ignore
    if (newInterview.hasOwnProperty("error")) throw new Error(newInterview.message);

    dispatch(setError("New feedback proposal was created!"));

    //clean state
    setInterview(emptyObject);
    setUpdateChange(!updateChange)
    setShowInterview(false);
  } catch (error: any) {
    dispatch(setError(error.toString()));
  }
}

//load interview goals
const [interviewGoals, setInterviewGoals] = useState<InterviewGoal[]>([]);

useEffect(() => {
  const loadInterviewGoals = async () => {
    try {
      const interviewGoals: InterviewGoal[] = await fetchAPI(
        `/evaluationcmds/proposals-feedback/get/all`,
        FetchMethods.GET
      );
      //@ts-ignore
      if (interviewGoals.hasOwnProperty("error")) throw new Error(interviewGoals.message);
  
      setInterviewGoals(interviewGoals);
    } catch (error: any) {
      dispatch(setError(error.toString()));
    }
  };
  loadInterviewGoals();
},[dispatch, updateChange]);



//delete interview goal
const deleteInterviewGoal = async (index: number) => {
  try {
    const deletedInterviewGoal: InterviewGoal = await fetchAPI(
      `/evaluationcmds/proposals-feedback/delete`,
      FetchMethods.DELETE,
      interviewGoals[index]
    );

    if (deletedInterviewGoal.hasOwnProperty("error"))
          //@ts-ignore
      throw new Error(deletedInterviewGoal.message);

    dispatch(setError("Interview goal deleted!"));
setUpdateChange(!updateChange)
  } catch (error: any) {
    dispatch(setError(error.toString()));
  }

};

  return (
   <AdminContainer>
        <div className="row">
         <h1 className="h1">Manage observation and feedback</h1>
      </div>
      <div className="row justify-content-between align-items-center">
        <div className="col-lg-8 m-2">
        <CountryFilter returnedCountry={pickCountry} />
        </div>
        <div className="col-lg-2 m-2 p-1">
        <button
              className="btn btn-danger"
              type="button"
              onClick={() => {
                setShowInterview(!showInterview);
              }}
            >
              Create new feedback & expectation
            </button>
        </div>
      </div>
<div className="card p-4 m-2">
  <h4 className="h4">List of Expectations</h4>
      {
        interviewGoals.length > 0 && interviewGoals.map((interviewGoal, index) => {
          if (interviewGoal.country === selectedCountry) {
            return (
              <Accordion key={index} className="m-2">
                <Accordion.Item eventKey={index.toString()}>
                  <Accordion.Header>
                    {interviewGoal.name}
                  </Accordion.Header>
                  <Accordion.Body>
                    <p>{interviewGoal.description}</p>
                    <button className='btn btn-danger' onClick={() => deleteInterviewGoal(index)}><BsFillTrashFill /></button>
                  </Accordion.Body>
                </Accordion.Item>
              </Accordion>
            )
          }
          return <></>
        })
      }
</div>
      {
        showInterview && 
        <form className='form-control' onSubmit={
          (e) => {
            e.preventDefault();
            createNewInterview();
          }
        }>
           <label htmlFor='text'>Name</label>
          <input className='form-control' type="text" value={interview.name} onChange={
            (e) => {
              setInterview({...interview, name: e.target.value})
            }
          }/>
          <label htmlFor='text'>Description</label>
          <textarea className='form-control' value={interview.description} onChange={
            (e) => {
              setInterview({...interview, description: e.target.value})
            }
          }/>


          <button className='btn btn-danger m-2' type='submit'>Save</button>


        </form>

      }

   </AdminContainer>
  )
}

export default AnnualObservationAndFeedback