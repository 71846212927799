import AdminContainer from "../../../admin-components/AdminContainer";
import { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import { setError } from "../../../store/reducers/errorReducer";
import { fetchAPI } from "../../../utils/FetchApi";
import { FetchMethods } from "../../../interface/Enums/methods.enum";
import Dropdown from "react-bootstrap/Dropdown";
import { BsThreeDotsVertical } from "react-icons/bs";
interface CountryInterface {
  name: string;
  createdAt: Date;
  updatedAt: Date;
  _id: string;
}
const ManageCountries = () => {
  const dispatch = useDispatch();
  const [country, setCountry] = useState<string>("");
  const [countries, setCountries] = useState<CountryInterface[]>([]);
  const [triggerEffect, setTriggerEffect] = useState<boolean>(false);
  const [showAddCountery, setShowAddCountery] = useState<boolean>(false);

  useEffect(() => {
    (async () => {
      try {
        const countries: CountryInterface[] = await fetchAPI(
          "/admincmds/get-all-countries",
          FetchMethods.GET
        );
        countries.sort((a, b) => (a.name > b.name ? 1 : -1));
        setCountries(countries);
      } catch (error: any) {
        dispatch(setError(error.message.toString()));
      }
    })();
  }, [triggerEffect, dispatch]);

  const createCountry = async () => {
    try {
      await fetchAPI("/admincmds/create-country", FetchMethods.POST, {
        name: country,
      });
      setCountry("");
      setTriggerEffect(!triggerEffect);
      setShowAddCountery(false)
    } catch (error: any) {
      dispatch(setError(error.message.toString()));
    }
  };

  const deleteCountry = async (index: number) => {

    //confirm window for deleting the country
    if (!window.confirm("Are you sure you want to delete this country?")) {
      return;
    }

    const { _id } = countries[index];
    try {
      await fetchAPI(`/admincmds/delete-country/${_id}`, FetchMethods.GET);
      setTriggerEffect(!triggerEffect);
    } catch (error: any) {
      dispatch(setError(error.message.toString()));
    }
  };
  return (
    <AdminContainer>
     

      <div className="row justify-content-between align-items-center">
        <div className="col-lg-8 m-2">
       <h1 className="h1">Countries Management</h1> 
        </div>
        <div className="col-lg-2 m-2 p-1">
        <button
              className="btn btn-danger"
              type="button"
              onClick={() => {
                setShowAddCountery(!showAddCountery)
              }}
            >
              Create new Country
            </button>
        </div>
      </div>
      <hr className="hr" />
      <div className="row justify-content-md-center">
        {countries.map((c: CountryInterface, i: number) => (
          <div
            className="shadow rounded col-lg-3 col-md-4 cal-sm-2 m-2 p-2"
            key={i}
          >
            <div className="row">
              <div className="col-sm-2">
                <Dropdown>
                  <Dropdown.Toggle size="sm" variant="info">
                    <BsThreeDotsVertical />
                  </Dropdown.Toggle>
                  <Dropdown.Menu>
                    <Dropdown.Item
                      href="#/action-2"
                      onClick={() => {
                        deleteCountry(i);
                      }}
                    >
                      Delete
                    </Dropdown.Item>
                  </Dropdown.Menu>
                </Dropdown>
              </div>
              <div className="col-sm-10">
                <div className="card-body">
                  <h3 className="card-title h3">{c.name}</h3>
                  <p style={{ fontSize: 12 }} className="card-text">
                    Created: {new Date(c.createdAt).toLocaleDateString()}
                  </p>
                </div>
              </div>
            </div>
          </div>
        ))}
      </div>
      <form
        onSubmit={(e) => {
          e.preventDefault();
          createCountry();
        }}
      >
        <div className="row" style={{ marginTop: 100 }}>
          <div className="form-group">
            {showAddCountery && (
              <div className="card" style={{ padding: 30 }}>
                <h3>Unique name of the country</h3>
                <input
                  onChange={({ target }) => {
                    setCountry(target.value);
                  }}
                  value={country}
                  type="text"
                  className="form-control"
                  placeholder="Hungary, Czech Republic, Canada..."
                />
                <small id="emailHelp" className="form-text text-muted">
                  Please, make sure the country does not exist
                </small>
                <br />
                <button type="submit" className="btn btn-danger">
                  Create
                </button>
              </div>
            )}
          </div>
        </div>
      </form>
    </AdminContainer>
  );
};

export default ManageCountries;
