import { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import { FetchMethods } from "../interface/Enums/methods.enum";
import { IDepartment } from "../interface";
import { setError } from "../store/reducers/errorReducer";
import { fetchAPI } from "../utils/FetchApi";


const DepartmentSelectionByCountry = ({
  selectedDepartment,
  country,
}: {
  selectedDepartment: Function;
  country: string;
}) => {
  const dispatch = useDispatch();
  //Get the list of my users
  const [listOfDeparments, setListOfDeparments] = useState<IDepartment[]>([]);

  useEffect(() => {

      if(country.length>0){
        (async () => {
          try {
            const getDeparments: IDepartment[] = await fetchAPI(
              `/admincmds/get-all-departments/${country}`,
              FetchMethods.GET
            );
    
            if (getDeparments.hasOwnProperty("error"))
              throw new Error("Departments could not be loaded");
    
            setListOfDeparments(getDeparments);
          } catch (error: any) {
            if (error) dispatch(setError(error.message));
          }
        })();
      }
    
    
   
  }, [dispatch, country]);

  return (
    <select
      onChange={({ target }) => {
        const pseudoIndex = parseInt(target.value);
        selectedDepartment(listOfDeparments[pseudoIndex]);
      }}
      className="form-control form-select"
    >
      <option className="form-control" value="---">
        ---
      </option>

      {listOfDeparments.map((u: IDepartment, i) => (
        <option className="form-control" key={i} value={i}>
          {u.name}
        </option>
      ))}
    </select>
  );
};

export default DepartmentSelectionByCountry;
