import React, { useEffect, useState } from 'react';
import { IDepartment} from '../interface';
import { fetchAPI } from '../utils/FetchApi';
import { FetchMethods } from '../interface/Enums/methods.enum';
import { UserInterface, IEvaluationDynamicDocument, IError, IRatingDistribution } from '../interface';
import { useDispatch } from 'react-redux';
import { setError } from '../store/reducers/errorReducer';

interface AggregatedDataInterface {
    [key: string]: {
        inputs: string[],
        outputs: string[],
        energizers: string[],
        competencies: string[]
        ratingDistribution: IRatingDistribution
    }
}

interface Props {
  master:string,
  department?:IDepartment,
  //a property allData that is a function which returns getInputOutputEnergizerCompetencyLetters from the component
  dataRetriever:(data:AggregatedDataInterface)=>void
}

interface ITeamStatistics extends UserInterface  {
  evaluationDocuments:IEvaluationDynamicDocument[]
}




const ComponentName: React.FC<Props> = ({ master, department, dataRetriever}) => {
const [teamStatistics, setTeamStatistics] = useState<ITeamStatistics[]>([])
const [uniqueAppraisalPeriods, setUniqueAppraisalPeriods] = useState<string[]>([]);



const dispatch = useDispatch();
    useEffect(()=>{
        (async()=>{
if(department?._id !== "all" && master){
    console.log(master, department)
    try {
    
        const data:ITeamStatistics[] & IError = await fetchAPI("/admincmds/team/statistics", FetchMethods.POST, {master, department})
      if(data.statusCode){
       
        setTeamStatistics([]);
         //@ts-ignore
        throw new Error(data.message.toString())
        }
        console.log(data)
        setTeamStatistics(data)
    } catch (error:any) {
        dispatch(setError(error.message))
    }
    return;
}else{
    try {

        console.log("Call ALL")
        const data:ITeamStatistics[] & IError = await fetchAPI(`/admincmds/team/statistics/${master}`, FetchMethods.GET)
      if(data.statusCode){
       
        setTeamStatistics([]);
         //@ts-ignore
        throw new Error(data.message.toString())
        }
        setTeamStatistics(data)
    } catch (error:any) {
        dispatch(setError(error.message))
    }
    return;
}
            
        })()
    }, [master, department, dispatch])

// a function that gets unique values from teamStatistics.evaluationDocuments.appraisalPeriod
const getUniqueAppraisalPeriods = (teamStatistics:ITeamStatistics[])=>{
    const uniqueAppraisalPeriods:string[] = []
    teamStatistics.forEach((user:ITeamStatistics)=>{
        user.evaluationDocuments.forEach((evaluation:IEvaluationDynamicDocument)=>{
            if(!uniqueAppraisalPeriods.includes(evaluation.appraisalPeriod)){
                uniqueAppraisalPeriods.push(evaluation.appraisalPeriod)
            }
        })
    })
    return sortColumnsByYearAndHalf(uniqueAppraisalPeriods);
}
function sortColumnsByYearAndHalf(columns: string[]): string[] {
    // Create a custom sorting function
    const customSort = (a: string, b: string): number => {
      // Extract the year and half from the column names
      const yearA = parseInt(a.split("/")[1]);
      const yearB = parseInt(b.split("/")[1]);
      const halfA = a.includes("H1") ? 1 : 2;
      const halfB = b.includes("H1") ? 1 : 2;
  
      // Sort by year first
      if (yearA !== yearB) {
        return yearA - yearB;
      }
      // Sort by half (H1 before H2)
      else if (halfA !== halfB) {
        return halfA - halfB;
      }
      // If year and half are the same, compare the full names
      else {
        return a.localeCompare(b);
      }
    };
  
    // Sort the columns using the custom sorting function
    return columns.sort(customSort);
  }
useEffect(()=>{
    const uniqueAppraisalPeriods = getUniqueAppraisalPeriods(teamStatistics)
    setUniqueAppraisalPeriods(uniqueAppraisalPeriods)
     // eslint-disable-next-line react-hooks/exhaustive-deps
}, [teamStatistics])

//A function that for each unique appraisal period, gets the input, output, energizer and competency letters
const getInputOutputEnergizerCompetencyLetters = (teamStatistics:ITeamStatistics[], uniqueAppraisalPeriods:string[])=>{

    const allPeriodsWithLetters:any = {}

    uniqueAppraisalPeriods.forEach((appraisalPeriod:string)=>{
        teamStatistics.forEach((user:ITeamStatistics)=>{
            user.evaluationDocuments.forEach((evaluation:IEvaluationDynamicDocument)=>{
                if(evaluation.appraisalPeriod === appraisalPeriod){
                    if(!allPeriodsWithLetters[appraisalPeriod]){
                        allPeriodsWithLetters[appraisalPeriod] = {
                            inputs:[],
                            outputs:[],
                            energizers:[],
                            competencies:[]
                        }
                    }
                    allPeriodsWithLetters[appraisalPeriod].inputs.push(evaluation.overallInputLetter)
                    allPeriodsWithLetters[appraisalPeriod].outputs.push(evaluation.outputStandardLetter)
                    allPeriodsWithLetters[appraisalPeriod].energizers.push(evaluation.energizerInputLetter)
                    allPeriodsWithLetters[appraisalPeriod].competencies.push(evaluation.competenciesLetter)
                    allPeriodsWithLetters[appraisalPeriod].ratingDistribution = evaluation.appraisalDocument.ratingDistribution;
                }
            })
        })
    })
    return {allPeriodsWithLetters}
   
}

useEffect(()=>{
    const {allPeriodsWithLetters} = getInputOutputEnergizerCompetencyLetters(teamStatistics, uniqueAppraisalPeriods)
    dataRetriever(allPeriodsWithLetters)
    // eslint-disable-next-line react-hooks/exhaustive-deps
}, [teamStatistics, uniqueAppraisalPeriods])



  return (
    <div style={{fontSize:12}}>
        <hr className="hr" />
      <table className='table border p-2 m-1 rounded vertical-align'>
            <tbody>
                <tr>
                    <td className='align-middle'>&nbsp;</td>
                    <td className='align-middle'>&nbsp;</td>
      
                 
                    {
                        uniqueAppraisalPeriods.map((appraisalPeriod:string, index:number)=>{
                            return (
                                <th key={index} className='text-center align-middle'>{appraisalPeriod}</th>
                            )
                        })
                    }
                </tr>

                {
                    teamStatistics.length> 0 && teamStatistics.map((user:ITeamStatistics, index:number)=>{
                        return (
                            <tr key={index}>
                                <td className='align-middle'>{user.name} {user.surname}</td>
                                <td >
                                    <table style={{margin:"0 auto"}}>
                                        <tbody>
                                       
                                            <tr><td>Inputs</td></tr>
                                            <tr><td>Energizer</td></tr>
                                            <tr><td>Outputs</td></tr>
                                            <tr><td>Competencies</td></tr>
                                        </tbody>
                                    </table>
                                </td>
                                {
                                    uniqueAppraisalPeriods.map((appraisalPeriod:string, index:number)=>{
                                        return (
                                            <td key={index} className='text-center'>
                                                <table style={{margin:"0 auto"}}>
                                                    <tbody>
                                                        
                                                        {
                                                            user.evaluationDocuments && user.evaluationDocuments.map
                                                             // eslint-disable-next-line no-unused-vars
                                                            ((evaluation:IEvaluationDynamicDocument,
                                                                // eslint-disable-next-line no-unused-vars
                                                                index:number)=>{
                                                                if(evaluation.appraisalPeriod === appraisalPeriod){
                                                                    return (
                                                                        <>
                                                                            <tr><td>{evaluation.overallInputLetter}</td></tr>
                                                                            <tr><td>{evaluation.energizerInputLetter}</td></tr>
                                                                            <tr><td>{evaluation.outputStandardLetter}</td></tr>
                                                                            <tr><td>{evaluation.competenciesLetter}</td></tr>
                                                                        </>
                                                                    )
                                                                }
                                                               return null;
                                                            })
                                                        }
                                                    </tbody>
                                                </table>
                                            </td>
                                        )
                                    })
                                }
                               
                                
                            </tr>
                        )
                    })
                }
            </tbody>
      </table>
    </div>
  );
};

export default ComponentName;