import { IEvaluationDynamicDocument } from "../../../../interface";
import { Roles } from "../../../../interface/Enums/Roles.enum";
export const emptyEvaluationDynamicDocument: IEvaluationDynamicDocument = {
  selectedRep: {
    updatedAt: new Date(),
    createdAt: new Date(),
    lastLoggedIn: new Date(),
    isUserApproved: false,
    authLevel: Roles.MedicalRepresentative,
    country: "",
    surname: "",
    name: "",
    password: "",
    email: "",
    refresh_token: "",
    master: "",
    _id: "",
  },
  selectedDepartment: {
    name: "",
    country: "",
    description: "",
    creator: "",
    createdAt: new Date(),
    updatedAt: new Date(),
    _id: "",
  },
  selectedJobname: {
    name: "",
    country: "",
    description: "",
    creator: "",
    createdAt: new Date(),
    updatedAt: new Date(),
    _id: "",
  },
  startDate: new Date(),
  currentPositionHeld: new Date(),
  appraisalDate: new Date(),
  dateConfirmation: new Date(),
  appraisalDocument: {
    country: "",
    inputs: [],
    inputName: "",
    inputDescription: "",
    outputs: [],
    outputName: "",
    outputDescription: "",
    comments: [],
    commentsName: "",
    commentsDescription: "",
    competencies: [],
    competenciesName: "",
    competenciesDescription: "",
    evaluationPeriod: "",
    creator: "",
    _id: "",
    ratingDistribution: {
      a: {
        from: 0,
        to: 0,
        letter: "A",
      },
      b: {
        from: 0,
        to: 0,
        letter: "B",
      },
      c: {
        from: 0,
        to: 0,
        letter: "C",
      },
      d: {
        from: 0,
        to: 0,
        letter: "D",
      },
      e: {
        from: 0,
        to: 0,
        letter: "E",
      },
      f: {
        from: 0,
        to: 0,
        letter: "F",
      },
    },
    createdAt: new Date(),
    updatedAt: new Date(),
  },
  raitingDistribution: {
    a: {
      from: 0,
      to: 0,
      letter: "A",
    },
    b: {
      from: 0,
      to: 0,
      letter: "B",
    },
    c: {
      from: 0,
      to: 0,
      letter: "C",
    },
    d: {
      from: 0,
      to: 0,
      letter: "D",
    },
    e: {
      from: 0,
      to: 0,
      letter: "E",
    },
    f: {
      from: 0,
      to: 0,
      letter: "F",
    },
  },
  inputsStandard: {},
  inputsEnergizer: {},
  outputsStandard: {},
  competencies: {},
  competenciesLetter: "",
  competenciesFinaPercentageNumber: 0,
  comments: {},
  evaluationSummeries: {},
  managerConsentButton: false,
  employeeConsentButton: false,

  //Countables for INPUT section
  energizerImportanceSum: 0,
  inputImportanceSum: 0,
  inputEnergizerSum: 0,
  inputStandardSum: 0,
  energizerInputFinalValue: 0,
  overallInputFinalvalue: 0,
  energizerInputLetter: "",
  overallInputLetter: "",

  //Countables for OUTPUT section
  outputStandardImportanceSum: 0,
  outputStandardSum: 0,
  outputStandardFinalValue: 0,
  outputStandardLetter: "",

  //Fast search for quick queries
  repNameQuery:"",
  appraisalPeriod:"",
  country:"",
};
