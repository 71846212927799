import {useEffect, useState} from 'react'
import { BsFillPlusCircleFill } from 'react-icons/bs';
import { useDispatch } from 'react-redux';
import { FetchMethods } from '../../../../interface/Enums/methods.enum';
import { IDynamicTableShape } from '../../../../interface/DynamicTableShape.interface';
import { setError } from '../../../../store/reducers/errorReducer';
import { fetchAPI } from '../../../../utils/FetchApi';
const CareerInterview = ({careerinterviews}:{careerinterviews:Function}) => {
    const dispatch = useDispatch()
    const [listOfDynamicTableShapes, setListOfDynamicTableShapes] = useState<IDynamicTableShape[]>([]);
    const [wasDataUpdated] = useState<boolean>(false);
    useEffect(()=>{
        (async()=>{
          try {
            const getData:IDynamicTableShape[] = await fetchAPI(`/evaluationcmds/interview/get/all`,FetchMethods.GET);
            //@ts-ignore
            if(getData.hasOwnProperty("error"))throw new Error(getData.message);
            setListOfDynamicTableShapes(getData);
        } catch (error:any) {
            dispatch(setError(error.toString()));
        }
        })()
       return () =>{
        setListOfDynamicTableShapes([]);
       }
      },[dispatch, wasDataUpdated])

      //select one or more items - items will be unique

      const selectItem = (index:number) =>{
        const item = listOfDynamicTableShapes[index];
        const filteredArray = listOfDynamicTableShapes.filter((item:IDynamicTableShape)=>item.name !== listOfDynamicTableShapes[index].name);
        setListOfDynamicTableShapes(filteredArray);
        careerinterviews(item);
      }

  return (
 <>

     { listOfDynamicTableShapes.length > 0 &&
   
    <div className='rounded p-2 shadow m-2 border'>
    {listOfDynamicTableShapes.map((item:IDynamicTableShape, index:number)=>{
        return <div className='form-check p-2 m-2' key={index}>
          <div className="row">
          <div className="col-8"> {item.name} </div>
          <div className="col-4"><BsFillPlusCircleFill color='green' fontSize={20} style={{cursor:"pointer"}} onClick={()=>{
            selectItem(index);
          }}/></div>
          </div>
         
       </div>

     
    })}
    </div>}
    </>
  )
}

export default CareerInterview