import { IEvaluationDynamicDocument } from "../../../../interface";
import { Accordion } from "react-bootstrap";
import RatingDistribution from "./RatingDistribution";
import InputsOutputs from "./Inputs";
import Competencies from "./Competencies";
import Comments from "./Comments";
import CompetenciesChart from "../../../../components/Charts/Competencies.chart";
import InputOutputChart from "../../../../components/Charts/InputOutput.chart";
import EnergizerAssesmentChart from "../../../../components/Charts/EnergizerAssesment.chart";
const EvaluationDocumentMasterComponents = ({
  evaldoc,
  position = "absolute",
  width,
  bgcolor,
}: {
  evaldoc: IEvaluationDynamicDocument,
  position?:"absolute"|"relative"|"fixed",
  width?:number|string,
  bgcolor?:string,
}) => {
  return (
    <div
      className="container small rounded p-2"
      style={{
        position: position,
        bottom: 0,
        right: 0,
        width: width||"auto",
        zIndex: 1,
        overflow:"scroll",
        maxHeight:"80vh",
        flexWrap:"wrap",
        backgroundColor:bgcolor||"transparent",
      }}
    >
      <Accordion className="
      border p-2 small">
        <Accordion.Header style={{ fontSize: 12 }}>
         {evaldoc.appraisalPeriod} | {evaldoc.repNameQuery}
        </Accordion.Header>
        <Accordion.Body>
        <div className="row text-center">
                    <div className="col">Inputs</div>
                    <div className="col">{evaldoc.overallInputLetter}</div>
                </div>
        <div className="row text-center">
                    <div className="col">Energizer</div>
                    <div className="col">{evaldoc.energizerInputLetter}</div>
                </div>
                <div className="row text-center">
                    <div className="col">Outputs</div>
                    <div className="col">{evaldoc.outputStandardLetter}</div>
                </div>
                <div className="row text-center">
                    <div className="col">Competencies</div>
                    <div className="col">{evaldoc.competenciesLetter}</div>
                </div>
          <Accordion className="p-2 small border m-1">
            <Accordion.Header>
              <strong className="small">Basic info: {evaldoc.appraisalPeriod} | {evaldoc.repNameQuery}</strong>
              
            </Accordion.Header>
            <Accordion.Body>
              <div className="row">
                <div className="col">
                  <strong>Department</strong>
                </div>
                <div className="col">{evaldoc.selectedRep.department?.name}</div>
              </div>
              <div className="row">
                <div className="col">
                  <strong>Position</strong>
                </div>
                <div className="col">{evaldoc.selectedRep.jobName?.name}</div>
              </div>
              <div className="row">
                <div className="col">
                  <strong>Start date of employment</strong>
                </div>
                <div className="col">
                  {evaldoc.selectedRep.startDateOfEmployment && new Date(evaldoc.selectedRep.startDateOfEmployment).toLocaleDateString()}
                </div>
              </div>
              <div className="row">
                <div className="col">
                  <strong>Current position held since</strong>
                </div>
                <div className="col">
                {evaldoc.selectedRep.currentPositionHeldSince && new Date (evaldoc.selectedRep.currentPositionHeldSince).toLocaleDateString()}
                </div>
              </div>
              <div className="row">
                <div className="col">
                  <strong>Date of appraisal</strong>
                </div>
                <div className="col">
                  {new Date(evaldoc.dateConfirmation).toLocaleDateString()}
                </div>
              </div>
            </Accordion.Body>
          </Accordion>
          <Accordion className="p-2 small border m-1">
            <Accordion.Header><strong className="small">Rating distribution</strong></Accordion.Header>
            <Accordion.Body>
                <RatingDistribution data={evaldoc.appraisalDocument.ratingDistribution}/>
            </Accordion.Body>
          </Accordion>
          <Accordion className="p-2 small border m-1">
            <Accordion.Header><strong className="small">Inputs</strong></Accordion.Header>
            <Accordion.Body>
                <InputsOutputs inputsoutputs={evaldoc.inputsEnergizer}/>
                <InputsOutputs inputsoutputs={evaldoc.inputsStandard}/>
                <div className="row px-2" style={{fontWeight:"bold"}}>
                   
                    <div className="col">Energizer</div>
                    <div className="col"></div>
                    <div className="col">{evaldoc.energizerInputFinalValue.toFixed(2)}%</div>
                    <div className="col">{evaldoc.energizerInputLetter}</div>
                </div>
                <div className="row px-2" style={{fontWeight:"bold"}}>
                    <div className="col">Overall inputs</div>
                    <div className="col"></div>
                <div className="col">{evaldoc.overallInputFinalvalue.toFixed(2)}%</div>
                    <div className="col">{evaldoc.overallInputLetter}</div>
                </div>
            </Accordion.Body>
          </Accordion>
          <Accordion className="p-2 small border m-1">
            <Accordion.Header><strong className="small">Outputs</strong></Accordion.Header>
            <Accordion.Body>
                <InputsOutputs inputsoutputs={evaldoc.outputsStandard}/>
                <div className="row px-2" style={{fontWeight:"bold"}}>
                    <div className="col">Overall outputs</div>
                    <div className="col"></div>
                     <div className="col">{evaldoc.outputStandardFinalValue.toFixed(2)}%</div>
                    <div className="col">{evaldoc.outputStandardLetter}</div>
                </div>
            </Accordion.Body>
          </Accordion>
          <Accordion className="p-2 small border m-1">
            <Accordion.Header><strong className="small">Competencies</strong></Accordion.Header>
            <Accordion.Body>
                <Competencies competencies={evaldoc.competencies}/>
            <div className="row">
                <div className="col">{evaldoc.competenciesFinaPercentageNumber.toFixed(2)}%</div>
                <div className="col">{evaldoc.competenciesLetter} ({
                       (evaldoc.competenciesLetter === "A" && 6) ||
                        (evaldoc.competenciesLetter === "B" && 5 )||
                        (evaldoc.competenciesLetter === "C" && 4) ||
                        (evaldoc.competenciesLetter === "D" && 3) ||
                        (evaldoc.competenciesLetter === "E" && 2) ||
                        (evaldoc.competenciesLetter === "F" && 1) ||
                        (evaldoc.competenciesLetter === "X" && 0)
                      })</div>
            </div>
            </Accordion.Body>
          </Accordion>
          <Accordion className="p-2 small border m-1">
            <Accordion.Header><strong className="small">Comments</strong></Accordion.Header>
            <Accordion.Body>
                <Comments comments={evaldoc.comments}/>
            </Accordion.Body>
          </Accordion>
          <Accordion className="p-2 small border m-1">
            <Accordion.Header><strong className="small">Charts</strong></Accordion.Header>
            <Accordion.Body>

            <div className="div" style={{height:"50vh", overflow:"scroll"}}>
           
                <CompetenciesChart EvalDynamicDocument={evaldoc}/>
                <EnergizerAssesmentChart EvalDynamicDocument={evaldoc}/>
                <InputOutputChart  EvalDynamicDocument={evaldoc}/>
    </div>
             
            </Accordion.Body>
          </Accordion>
        </Accordion.Body>
      </Accordion>
    </div>
  );
};

export default EvaluationDocumentMasterComponents;
