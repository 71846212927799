import React from 'react'
import { Accordion } from 'react-bootstrap';

type GoalT = {
    country:string;
    columns:string[];
    name:string;
    notes?:string;
    answer:[][]
}
const TheGoals = ({thegoal}:{thegoal:GoalT}) => {
  return (
   <Accordion className="m-1 border rounded">
        <Accordion.Header>
            {thegoal.name}
        </Accordion.Header>
        <Accordion.Body>
            <p>{thegoal.notes}</p>

            <table className='table table-bordered rounded shadow small p-3'>
<tbody>
    <tr>
    {thegoal.columns && thegoal.columns.map((c:string,i:number)=>{
            return(
               <th key={i}>{c}</th>
            )
        })}
    </tr>
    {

        thegoal.answer&&thegoal.answer.map((row:string[], index:number)=>{
            return <tr key={index} style={{backgroundColor:"#f0efef"}}>
              {row.map((f:string,k:number)=>{
                return <td key={k}>{f}</td>
              })}
            </tr>
        })
    }
</tbody>
</table>
        </Accordion.Body>
   </Accordion>
  )
}

export default TheGoals