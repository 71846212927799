import { useSelector, useDispatch } from "react-redux";
import Navbar from "react-bootstrap/NavBar";
import Container from "react-bootstrap/Container";
import Nav from "react-bootstrap/Nav";
import NavDropdown from "react-bootstrap/NavDropdown";
import { useState, useEffect } from "react";
import { Roles } from "../interface/Enums/Roles.enum";
import { UserInterface } from "../interface/UserInterface";

import { authUserFailed, setUser } from "../store/reducers/auth";
import { useNavigate } from "react-router-dom";

import {
  BsGlobe2,
  BsStopwatch,
  BsBookmarkStar,
  BsFillDiagram2Fill,
  BsFillFileEarmarkPersonFill,
  BsFillPlusCircleFill,
  BsArrowBarRight,
  BsArrowBarLeft,
  BsFillPuzzleFill,
  BsChatDots,
  BsFillFlagFill,
  BsTable,
  BsFillPlusSquareFill,
  BsFillHouseDoorFill,
  BsShieldFillCheck,
  BsBarChartFill,
  BsBarChart,
} from "react-icons/bs";
import Tutorial from "./Tutorial";
import GDPRBullshit from "./GDPRBullshit";
import { fetchAPI } from "../utils/FetchApi";
import { FetchMethods } from "../interface/Enums/methods.enum";
import { setError } from "../store/reducers/errorReducer";
const GlobalMenu = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const data: UserInterface = useSelector((data: any) => {
    return data.auth.user;
  });
  const isUserAuthenticated = useSelector((data: any) => {
    return data.auth.isAuthenticated;
  });


  const [authLevel, setAuthLevel] = useState<Roles | string>(
    Roles.MedicalRepresentative
  );
const [listenForChange, setListenForChange] = useState<boolean>(false)
 const [showGDPRModal, setShowGDPRModal] = useState<boolean>(false);
  useEffect(() => {
    if (!isUserAuthenticated) navigate("/login");
    setAuthLevel(data.authLevel);

    //Check if user consented to GDPR
    if(!data.gdprConsent){
      //throw the GDPR modal
      setShowGDPRModal(true);
    }else{

      setShowGDPRModal(false)
    }
  }, [data, isUserAuthenticated, navigate, dispatch, listenForChange]);

  const [switchTutorial, setSwitchTutorial] = useState<boolean>(false);

  const turnOff = () => {
    setSwitchTutorial(false);
  };

  //update the user's consent
  const updateUserAnything = async (
    partial: Partial<UserInterface>
  ) => {
    try {

   let dataForUpdate = Object.assign({...data}, partial);
      const response: any = await fetchAPI(
        `/users/update/${data._id}`,
        FetchMethods.PATCH,
        dataForUpdate
      );
      dispatch(setUser(response));
      setListenForChange(!listenForChange);
     if(response.hasOwnProperty("error")){
      //@ts-ignore
      throw new Error(response.message.toString())
     }
      
    } catch (error: any) {
    //  console.log(error)
      dispatch(setError(error.toString()));
    }
  };
  return (
    <>
     {
  (showGDPRModal && isUserAuthenticated) && 
  <div className="container" style={{width:"100vw",maxWidth:"100%", height:"100vh", position:"fixed", zIndex:909090, backgroundColor:"white", display:"flex", flexDirection:"column", maxHeight:"100%", overflow:"scroll", top:0, left:0}}>
    <div className="container">
      <br />
      <br />
      <br />
    <h3 className="h3">GDPR privacy notice</h3>
    <br />
    <GDPRBullshit />
    <br />
    <div className="consent-btn btn btn-primary"
    onClick={()=>{
      updateUserAnything({
        gdprConsent:true,
        gdprConsentDate: new Date()
      });
    }}
    >I consent</div>
    </div>
  </div>
 }
    {
      (switchTutorial && isUserAuthenticated) && <Tutorial toggleButton={turnOff} className="shadow rounded p-2" style={{position:"fixed", bottom:0, right:0,overflow:"scroll",
      maxHeight:"80vh",
      flexWrap:"wrap",
      backgroundColor:"#ffffffd1",
      width:"40%",
      zIndex:400,
      }} />
    }
    <Navbar bg="light" expand="lg">
      <Container>
        
        <Navbar.Brand
          style={{ cursor: "pointer" }}
          onClick={() => {
            navigate("/dashboard");
          }}
        >
         <BsFillHouseDoorFill size={50} color="grey" className="border rounded p-1"/>
        </Navbar.Brand>
        <Navbar.Toggle aria-controls="basic-navbar-nav" />
        <Navbar.Collapse id="basic-navbar-nav">
          <Nav variant="tabs" className="me-auto">
            {authLevel === Roles.OmniAdmin && (
              <>
                <NavDropdown
                  title="Administrator"
                  id="basic-nav-dropdown"
                >

<NavDropdown
                    title={`Global setup`}
                    drop="end"
                  >
       
                    <NavDropdown.Item
                      onClick={() => {
                        navigate("/admin-manage-countries");
                      }}
                    >
                      {" "}
                      <BsGlobe2 color="orange" /> Manage Countries
                    </NavDropdown.Item>

                 

                    <NavDropdown.Item
                      onClick={() => {
                        navigate("/admin-manage-jobname");
                      }}
                    >
                      {" "}
                      <BsBookmarkStar color="orange" /> Manage Job Names
                    </NavDropdown.Item>

                    <NavDropdown.Item
                      onClick={() => {
                        navigate("/admin-manage-department");
                      }}
                    >
                      {" "}
                      <BsFillDiagram2Fill color="orange" /> Manage Departments
                    </NavDropdown.Item>

                    
                    
                  </NavDropdown>
                  <NavDropdown.Item
                    onClick={() => {
                      navigate("/admin-manage-users");
                    }}
                  >
                    <span>
                      <BsFillFileEarmarkPersonFill color="green" /> Manage Users
                    </span>
                  </NavDropdown.Item>
                  <NavDropdown.Item
                    onClick={() => {
                      navigate("/admin/team-statistics");
                    }}
                  >
                    <span>
                      <BsBarChartFill color="green" /> Team Statistics
                    </span>
                  </NavDropdown.Item>
                  <NavDropdown.Item
                    onClick={() => {
                      navigate("/admin/team-assessment");
                    }}
                  >
                    <span>
                      <BsBarChart color="green" /> Team Assessment
                 
                    </span>
                  </NavDropdown.Item>
                  <NavDropdown
                    title={"Appraisal Management"}
                    drop="end"
                  >

<NavDropdown.Item
                      onClick={() => {
                        navigate("/admin-manage-evaluation-periods");
                      }}
                    >
                      <BsStopwatch color="orange" /> Manage Evaluation Periods
                    </NavDropdown.Item>
                      <NavDropdown.Item
                        onClick={() => {
                          navigate("/admin-manage-performance-input-metrics");
                        }}
                      >
                        {" "}
                        <BsArrowBarRight color="purple" /> Manage Inputs
                      </NavDropdown.Item>

                      <NavDropdown.Item
                        onClick={() => {
                          navigate("/admin-manage-performance-output-metrics");
                        }}
                      >
                        <BsArrowBarLeft color="purple" /> Manage Outputs
                      </NavDropdown.Item>

                      <NavDropdown.Item
                        onClick={() => {
                          navigate(
                            "/admin-manage-performance-competencies-metrics"
                          );
                        }}
                      >
                        <BsFillPuzzleFill color="purple" /> Manage Competencies
                      </NavDropdown.Item>

                      <NavDropdown.Item
                        onClick={() => {
                          navigate(
                            "/admin-manage-performance-comments-metrics"
                          );
                        }}
                      >
                        <BsChatDots color="purple" /> Manage Comments
                      </NavDropdown.Item>

                  
                    

                    <NavDropdown.Item
                      onClick={() => {
                        navigate("/admin-create-new-appraisal");
                      }}
                    >
                      <BsFillPlusCircleFill color="green" /> Create Appraisal
                    </NavDropdown.Item>
                  </NavDropdown>

                  <NavDropdown
                    title={"Annual Management"}
                    drop="end"
                  >
                    <NavDropdown.Item
                      onClick={() => {
                        navigate("/admin-annual-evaluation-summary");
                      }}
                    >
                      {" "}
                      <BsGlobe2 color="orange" /> Evaluation Summary
                    </NavDropdown.Item>

                    <NavDropdown.Item
                      onClick={() => {
                        navigate("/admin-annual-evaluation-goals");
                      }}
                    >
                      {" "}
                      <BsFillFlagFill color="orange" /> Future goals
                    </NavDropdown.Item>

                    <NavDropdown.Item
                      onClick={() => {
                        navigate("/admin-annual-evaluation-generic-table");
                      }}
                    >
                      {" "}
                      <BsTable color="orange" /> Summary table
                    </NavDropdown.Item>

                    <NavDropdown.Item
                      onClick={() => {
                        navigate("/admin-annual-interview-career-expectations");
                      }}
                    >
                      <BsStopwatch color="orange" /> Interview - Carreer
                      Expectations
                    </NavDropdown.Item>

                    <NavDropdown.Item
                      onClick={() => {
                        navigate("/admin-annual-feedback-observations");
                      }}
                    >
                      {" "}
                      <BsBookmarkStar color="orange" /> Observation & Feedback
                    </NavDropdown.Item>
                    <NavDropdown.Item
                      onClick={() => {
                        navigate("/admin-create-new-annual-evaluation");
                      }}
                    >
                      <BsFillPlusSquareFill color="green" /> Create Annual
                      Evaluation
                    </NavDropdown.Item>
                  </NavDropdown>

                  <NavDropdown.Item
                  className="bg-warning"
                      onClick={() => {
                        navigate("/admin/udpates");
                      }}
                    >
                      <BsShieldFillCheck color="red" /> <small className="small">updates</small>
                    </NavDropdown.Item>
                </NavDropdown>
              </>
            )}

        

      
          </Nav>
          <Nav>
            <NavDropdown
              title={
                isUserAuthenticated
                  ? data.name+"'s Profile"
                  : "Profile"
              }
              className="justify-content-end ms-auto ml-auto"
            >
              <table
                className="table table-striped table-bordered"
                style={{ fontSize: 10 }}
              >
                <tbody>
                  {isUserAuthenticated && (
                    <>
                    <tr>
                        <th>Tutorial</th>
                        <td><div className={switchTutorial?"btn btn-warning small":"btn btn-success small"} onClick={()=>{setSwitchTutorial(!switchTutorial)}}>{switchTutorial?"Turn off":"Turn on"}</div></td>
                    </tr>
                      <tr>
                        <th>Name</th>
                        <td>
                          {data.name} {data.surname}
                        </td>
                      </tr>
                      <tr>
                        <th>Role</th>
                        <td>{data.authLevel}</td>
                      </tr>
                      <tr>
                        <th>Last logged in</th>
                        <td>
                          {new Date(data.lastLoggedIn).toLocaleDateString()}{" "}
                          {new Date(data.lastLoggedIn).toLocaleTimeString()}
                        </td>
                      </tr>
                      <tr>
                        <th>Country</th>
                        <td>{data.country}</td>
                      </tr>
                      <tr>
                        <th>e-mail</th>
                        <td>{data.email}</td>
                      </tr>
                    </>
                  )}

                    {
                      data.gdprConsent?<>Consented</>:<>No consent</>
                    }
                  {isUserAuthenticated && (
                    <tr>
                      <td></td>
                      <td>
                        <div
                          onClick={() => {
                            dispatch(authUserFailed());
                          }}
                          className="btn btn-danger"
                        >
                         Sign-out
                        </div>
                      </td>
                    </tr>
                  )}
                </tbody>
              </table>
            </NavDropdown>
          </Nav>
        </Navbar.Collapse>
      </Container>
    </Navbar>

    </>
  );
};

export default GlobalMenu;
