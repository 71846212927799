import React from 'react'
import {IUser} from "../../../../../../interface/IUser.interface"
import { Accordion } from "react-bootstrap";
const UserDetail = ({user}:{user:IUser}) => {
  return (
    <>
    {
        user&&
        <Accordion className='border rounded m-1'>
            <Accordion.Header>
                {user.name} {user.surname}
            </Accordion.Header>
            <Accordion.Body>
                <ul>
                    <li>{user.country}</li>
                    <li>{user.department?.name}</li>
                    <li>{user.email}</li>
                    <li>{user.isUserApproved?"Approved":"Disapproved"}</li>
                    <li>{user.jobName?.name}</li>
                </ul>
            </Accordion.Body>
        </Accordion>
    }
    </>
  )
}

export default UserDetail