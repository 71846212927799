import { useState, useEffect } from "react"
import { useDispatch } from "react-redux"
import { FetchMethods } from "../../../../interface/Enums/methods.enum"
import { IEvaluationDynamicDocument, IJobname } from "../../../../interface"


import { setError } from "../../../../store/reducers/errorReducer"
import { fetchAPI } from "../../../../utils/FetchApi"

const JobnameSelection = ({selectedJobname, evaldoc}:{selectedJobname:Function, evaldoc:IEvaluationDynamicDocument}) => {
  const dispatch = useDispatch()
  //Get the list of my users
  const [listOfDeparments, setListOfDeparments] = useState<IJobname[]>([])

useEffect(()=>{
    (async()=>{
      try {
        const getDeparments:IJobname[] =  await fetchAPI("/evaluationcmds/get-my-jobs", FetchMethods.GET);
        if(getDeparments.hasOwnProperty("error")) throw new Error("Departments could not be loaded")

        setListOfDeparments(getDeparments)

      } catch (error:any) {
          if (error)dispatch(setError(error.message))
      }
      
     
    })()
},[dispatch]);
  
  return (
    <select onChange={({target})=>{
      const pseudoIndex = parseInt(target.value)
      selectedJobname(listOfDeparments[pseudoIndex])
    }} className="form-control">
      {selectedJobname?<option className="form-control" value={-1}>{evaldoc.selectedJobname.name}</option>:<option className="form-control" value="---">---</option>}
      
      {
       listOfDeparments.map((u:IJobname,i)=>(
          <option className="form-control" key={i} value={i}>{u.name}</option>
        ))
      }
    </select>
  )
}

export default JobnameSelection