import React from 'react'

const GDPRBullshit = () => {
  return (
    <div className='border p-5 rounded shadow' style={{overflow:"scroll", maxHeight:"30vh", maxWidth:"1000px"}}>
        <p>
        Privacy Notice for Users of Gedeon Richter Plc. (H-1103 Budapest, Gyömrői út 19-21., Hungary, Cg. 01-10-040944) & Gedeon Richter Marketing ČR.s.r.o
        </p>
        
        <p>
        This Privacy Notice explains how [company name] ("we," "us," or "our") collects, uses, and shares personal data in connection with our services, in accordance with the General Data Protection Regulation ("GDPR").
        </p>

       <h3>
       What personal data do we collect?

       </h3>
<p>
We collect the following personal data from our users:
</p>

<ul>
    <li>Name</li>
    <li>Surname</li>
    <li>Start date of employment</li>
    <li>Position in the company</li>
    <li>Name of the team in the company</li>
    <li>Date of employment start</li>
    <li>Territory name of the user</li>
    <li>Email</li>
    <li>Last login to the system</li>

</ul>

<h3 className="h3">How do we use personal data?</h3>
<p>
We use personal data for the following purposes:
</p>

<ul>
    <li>To provide our services to users</li>
    <li>To manage user accounts and provide user support</li>
    <li>To communicate with users</li>
    <li>To comply with our legal obligations</li>
    <li>To improve our services and for internal business purposes</li>

</ul>

    <h3>How do we share personal data?</h3>

<p>
We may share personal data with the following third parties:
</p>

<ul>
    <li>Service providers who help us provide our services, such as cloud hosting providers</li>
    <li>
    Legal and regulatory authorities, when required by law or in connection with legal proceedings
    </li>
    <li>
    Other third parties, with your consent or at your direction
    </li>
</ul>

<h3>
How do we protect personal data?
</h3>

<p>
We take appropriate technical and organizational measures to protect personal data against accidental or unlawful destruction, loss, alteration, unauthorized disclosure or access, and against all other unlawful forms of processing.
</p>


<h3 className="h3">
What are your rights?
</h3>
<p>
You have the following rights regarding your personal data:
</p>

<ul>
    <li>
    The right to access your personal data
    </li>
    <li>
    The right to rectify your personal data
    </li>
    <li>
    The right to erase your personal data
    </li>
    <li>
    The right to restrict the processing of your personal data
    </li>
    <li>
    The right to data portability
    </li>
    <li>
    The right to object to the processing of your personal data
    </li>
    <li>
    The right to withdraw your consent at any time (where applicable)
    </li>

</ul>
<p>
To exercise your rights, please contact us using the contact details provided below.
</p>


<h3 className="h3">Contact us</h3>

<p>
If you have any questions or concerns about our privacy practices, please contact us at info@richtergedeon.cz
</p>
        </div>
  )
}

export default GDPRBullshit