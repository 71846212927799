import { useState } from "react";
import { FetchMethods } from "../interface/Enums/methods.enum";
import { Roles } from "../interface/Enums/Roles.enum";
import { ICountry, IDepartment, IJobname, UserInterface } from "../interface";
import { fetchAPI } from "../utils/FetchApi";
import { IUser } from "../interface/IUser.interface";
import DepartmentSelectionByCountry from "./DepartmentsByCountry";
import JobsSelectionByCountry from "./JobsByCountry";
import { useDispatch } from "react-redux";
import { setError } from "../store/reducers/errorReducer";
const emptyUser: IUser = {
  isUserApproved: false,
  authLevel: Roles.MedicalRepresentative,
  country: "",
  surname: "",
  name: "",
  email: "",
  master: "",
  _id: "",
  password: "",
  confirmedPassword:"",
  refresh_token: "",
  jobName: {
    name: "",
    country: "",
    description: "",
    creator: "",
  },
  department: { name: "", country: "", description: "", creator: "" },
};

const RegisterUser = ({
  countrylist,
  usersAsManagers,
  finishit
}: {
  countrylist: ICountry[];
  usersAsManagers: UserInterface[];
  finishit:Function
}) => {
  const dispatch = useDispatch();
  const [user, setUser] = useState<IUser>(emptyUser);

  const createUser = async (e: any) => {
    e.preventDefault();
    const {name, surname, email,  password, confirmedPassword}:IUser = user;

    if(name.length<1){
      dispatch(setError("Name can not be empty!"))
    }

    else if(surname.length<1){
      dispatch(setError("Surname can not be empty!"))
    }

    else if(email.length<1){
      dispatch(setError("Email can not be empty!"))
    }

    else if(password.length<1){
      dispatch(setError("Password can not be empty!"))
    }
    else if(confirmedPassword&&confirmedPassword.length<1){
      dispatch(setError("Confirmed password can not be empty!"))
    }

    else if(password !== confirmedPassword){
      dispatch(setError("Password is not as same as confirmed password!"))
    }
    
    else if(!password.match(/((?=.*\d)|(?=.*\W+))(?![.\n])(?=.*[A-Z])(?=.*[a-z]).*$/)){
      dispatch(setError("Password is too weak - it has to contain at least one Capital Letter and one number."))
    }
    else{
      try {
        const data:Response & {statusCode:number} = await fetchAPI("/admincmds/register-user-by-admin", FetchMethods.POST, user);
        if(data.statusCode > 299){
          throw new Error(data.toString())
        }

     if(data.hasOwnProperty("error")){
      //@ts-ignore
      throw new Error(data.message.toString())
     }

     finishit()
  setUser(emptyUser)
      } catch (error:any) {
       dispatch(setError(error.toString()))
      }
    }

    
  };

  const depSelection = (dep: IDepartment) => {
    setUser(() => ({ ...user, department: dep }));
  };
  const jobSelection = (job: IJobname) => {
    setUser(() => ({ ...user, jobName: job }));
  };

  return (
    <form
      onSubmit={createUser}
      className="d-sm-flex flex-column p-3 shadow rounded m-5"
      autoComplete="off"
      style={{
        margin: "0 auto",
        background: "#f9f9f9",
        padding: 10,
        borderRadius: 12,
      }}
    >
      <div className="row">
        <h3 className="h3 text-center">Create new employee</h3>
      </div>
      <div className="row m-2 p-2">
        <div className="col-6">
          {/* NAME */}
          <label className="form-field-select__label label-headline">
            Employee's First name
          </label>
          <div className="input-group mb-3">
            <input
              value={user.name}
              onChange={(e: React.ChangeEvent<HTMLInputElement>): void => {
                setUser({ ...user, name: e.target.value });
              }}
              type="text"
              className="form-control"
              aria-label="Recipient's username"
              aria-describedby="basic-addon2"
            />
          </div>
        </div>
        <div className="col-6">
          {/* SURNAME */}
          <label className="form-field-select__label label-headline">
            Employee's Last name
          </label>
          <div className="input-group mb-3">
            <input
              value={user.surname}
              onChange={(e: React.ChangeEvent<HTMLInputElement>): void => {
                setUser({ ...user, surname: e.target.value });
              }}
              type="text"
              className="form-control"
              aria-label="Recipient's username"
              aria-describedby="basic-addon2"
            />
          </div>
        </div>
      </div>
<div className="row m-2 p-2">
{/* EMAIL */}
<label className="form-field-select__label label-headline">
        Employee's Email
      </label>
      <div className="input-group mb-3">
        <input
          value={user.email}
          onChange={(e: React.ChangeEvent<HTMLInputElement>): void => {
            setUser({ ...user, email: e.target.value });
          }}
          type="email"
          className="form-control"
          aria-label="Recipient's username"
          aria-describedby="basic-addon2"
        />
        <div className="input-group-append">
          <span className="input-group-text" id="basic-addon2">
            Email
          </span>
        </div>
      </div>
</div>
      

      <div className="row m-2 p-2">
        <div className="col-4">
          {/* COUNTRY SELECTION */}
          <label className="form-field-select__label label-headline">
            Employee's Country
          </label>
          <div className="countrySelection">
            <select
              className="form-select"
              aria-label="Default select example"
              onChange={({ target }): void => {
                setUser({ ...user, country: target.value });
              }}
            >
              <option defaultValue={"---"} value="---">
                ---
              </option>
              {countrylist.map((c: ICountry, i: number) => (
                <option key={i} value={c.name}>
                  {c.name}
                </option>
              ))}
            </select>
          </div>
        </div>
        <div className="col-4">
          {/** DEPARTMENT SELECTION */}
          <label className="form-field-select__label label-headline">
            Employee's Department {user.country}
          </label>
          <DepartmentSelectionByCountry
            country={user.country}
            selectedDepartment={depSelection}
          />
        </div>
        <div className="col-4">
          {/** JOBS SELECTION */}
          <label className="form-field-select__label label-headline">
            Employee's Jobnames {user.country}
          </label>
          <JobsSelectionByCountry
            country={user.country}
            selectedJobName={jobSelection}
          />
        </div>
      </div>
<div className="row m-2 p-2">
     {/* Manager SELECTION */}
     <label className="form-field-select__label label-headline">
        Employee's Manager
      </label>
      <div className="countrySelection">
        <select
          className="form-select"
          aria-label="Default select example"
          onChange={({ target }): void => {
            setUser(() => ({ ...user, master: target.value }));
          }}
        >
          <option defaultValue={"---"} value="---">
            ---
          </option>
          {usersAsManagers.map((c: UserInterface, i: number) => (
            <option key={i} value={c._id}>
              {c.name} {c.surname}
            </option>
          ))}
        </select>
      </div>
</div>
     
<div className="row m-2 p-2">
    <div className="col-6">
 {/* PASSWORD */}
 <label className="form-field-select__label label-headline">
        Choose password for {user.name} {user.surname}
      </label>
      <div className="input-group mb-3">
        <input
          value={user.password}
          onChange={(e: React.ChangeEvent<HTMLInputElement>): void => {
            setUser({ ...user, password: e.target.value });
          }}
          type="password"
          className="form-control"
         autoComplete="false"
          aria-describedby="basic-addon2"
        />
        <div className="input-group-append">
          <span className="input-group-text" id="basic-addon2">
            Password
          </span>
        </div>
      </div>
    </div>
    <div className="col-6">
{/* PASSWORD REPETITION */}
<label className="form-field-select__label label-headline">
        Confirm selected password
      </label>
      <div className="input-group mb-3">
        <input
          value={user.confirmedPassword}
          onChange={(e: React.ChangeEvent<HTMLInputElement>): void => {
            setUser({...user,confirmedPassword:e.target.value})
          }}
          type="password"
          className="form-control"
          aria-label="Recipient's username"
          aria-describedby="basic-addon2"
        />
        <div className="input-group-append">
          <span className="input-group-text" id="basic-addon2">
            Confirmed password
          </span>
        </div>
      </div>
    </div>
</div>
     

      

      {/* FORM CONTROL */}
      <div className="register-form-control">
        <input className="btn btn-dark" type="submit" value={"Register"} />
      </div>
    </form>
  );
};

export default RegisterUser;
