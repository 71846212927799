
import { FetchMethods } from "../interface/Enums/methods.enum";
import { GLOBAL_URL } from "../GLOBAL_URL"

/**
 * 
 * @param url API URLS coming from Nest. List of APIS can be found in MD document
 * @param method one of POST, GET, PATCH and DELETE
 * @param data OPTIONAL param - Interface of any object we want
 */
export const fetchAPI = async <Type>(url:string, method:FetchMethods, data?:object):Promise<Type> =>{
    //Look for a token
    let dataResponse:any = undefined;
    try {
        const token: string | null = localStorage.getItem("token"); 
        const response:Response = await fetch(GLOBAL_URL+url,{
            method,
            headers: {
                "Content-type": "application/json",
                Authorization: `Bearer ${token}`,
            },
            body:JSON.stringify(data) 
        })

        if(response.status === 401){
            //go to login page
            window.location.href = "/login"
        }
         
        //Get the object based on the interface or get the Error
        dataResponse = await response.json();
    } catch (error) {
        console.log(error)
    }
   

  
      /**
     * Logic for the refresh token
     */
     return dataResponse

}