
import { useEffect, useState } from 'react'
import { IPerformanceMetricComments } from '../../../../interface'

const Comments = ({comments}:{comments:{[key:string]:IPerformanceMetricComments}}) => {

    const [comps, setComps] = useState<IPerformanceMetricComments[]>([])
    useEffect(()=>{
      const tempArr:IPerformanceMetricComments[] = [];
      for(let key in comments){
        tempArr.push(comments[key])
      }
      setComps(tempArr)
     
    },[comments])
    return (
      <div style={{backgroundColor:"white"}}>
      {comps.length>0 &&
      comps.map((io:IPerformanceMetricComments)=>(
        <div key={io.name} className='border-bottom p-1 m-1'>
          <div className="row">
            <div className="col">{io.name}</div>
           <div className="col">{io.evaluation}</div>
          </div>
      </div>
      ))
      }
      </div>
    )
}

export default Comments